import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WorkPointModule } from '../work-point/work-point.module';
import { WorkPointsComponent } from './work-points.component';

@NgModule({
  declarations: [WorkPointsComponent],
  imports: [CommonModule, WorkPointModule, FlexLayoutModule],
  exports: [WorkPointsComponent],
})
export class WorkPointsModule {}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthStore } from 'eyes-core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TRIANGLE_ADMIN_GROUP } from '../../app.references';

@Injectable({
  providedIn: 'root',
})
export class AdminAccountGuard implements CanActivate {
  constructor(private authStore: AuthStore) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authStore.data$.pipe(
      filter((_) => !!_),
      map(({ userInfo }) => (userInfo.groups || []).includes(TRIANGLE_ADMIN_GROUP)),
    );
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { Observable, Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { Coerce } from 'triangle-core';
import { ContactUsKeys, GetInTouchForm } from 'triangle-support/models';

@Injectable()
export class GetInTouchService {
  private requestingSubject = new Subject<boolean>();
  private successSubject = new Subject<boolean>();
  private errorSubject = new Subject<string>();

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private translate: TranslateService,
  ) {}

  sendRequest(form: GetInTouchForm): void {
    this.requestingSubject.next(true);
    const sanitizedPayload = this.sanitizePayload(this.makePayload(form));
    this.http
      .post(this.makeEndpoint(), sanitizedPayload)
      .pipe(
        first(),
        finalize(() => this.requestingSubject.next(false)),
      )
      .subscribe({
        next: this.onSuccess.bind(this),
        error: this.onFailure.bind(this),
      });
  }

  getRequestingStream(): Observable<boolean> {
    return this.requestingSubject.asObservable();
  }

  getIsSuccessfulStream(): Observable<boolean> {
    return this.successSubject.asObservable();
  }

  getErrorStream(): Observable<string> {
    return this.errorSubject.asObservable();
  }

  prepForAnotherSend(): void {
    this.successSubject.next(false);
  }

  private onSuccess(response: ContactUsResponse): void {
    this.successSubject.next(true);
  }

  private onFailure(error: HttpErrorResponse): void {
    const actualErrorCode = error?.error?.error;
    const translated = this.translate.instant(
      `Support.errors.${actualErrorCode}`,
    );
    this.errorSubject.next(translated);
  }

  private makeEndpoint(): string {
    const { baseUrl, endpoints } = this.config.api;
    return generateEndpoint(baseUrl, endpoints.support.contactUs);
  }
  private makePayload(form: GetInTouchForm): ContactUsRequest {
    return { ...form, marketing: !!Coerce.object(form.agreements).marketing };
  }

  private sanitizePayload(request: ContactUsRequest): ContactUsRequest {
    return ContactUsKeys.reduce((a, key: string) => {
      a[key] = (request[key] ?? '') as any;
      return a;
    }, {} as ContactUsRequest);
  }
}

interface ContactUsRequest extends GetInTouchForm {}

interface ContactUsResponse {}

enum NonFleetPackages {
  Free = 'free',
  Essential = 'essential',
  EssentialPlus = 'essential_plus',
  Standard = 'standard',
  Premium = 'premium',
  Enterprise = 'enterprise',
  NA = '',
}

enum FleetPackages {
  FreeFleet = 'free_fleet',
  EssentialFleet = 'essential_fleet',
  EssentialPlusFleet = 'essential_plus_fleet',
  StandardFleet = 'standard_fleet',
  PremiumFleet = 'premium_fleet',
}

type ProductPackage = NonFleetPackages | FleetPackages;
const ProductPackage: typeof NonFleetPackages & typeof FleetPackages = {
  ...NonFleetPackages,
  ...FleetPackages,
};

const LimitedPackages = [
  ProductPackage.Free,
  ProductPackage.Essential,
  ProductPackage.EssentialPlus,
  ProductPackage.FreeFleet,
  ProductPackage.EssentialFleet,
  ProductPackage.EssentialPlusFleet,
];

export { ProductPackage, LimitedPackages };

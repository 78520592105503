import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { WorkPointsModule } from '../work-points';
import { HowItWorksComponent } from './how-it-works.component';

@NgModule({
  declarations: [HowItWorksComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslateModule,
    WorkPointsModule,
  ],
  exports: [HowItWorksComponent],
})
export class HowItWorksModule {}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dateformat from 'dateformat';

@UntilDestroy()
@Component({
  selector: 'triangle-calculation-date-picker',
  templateUrl: './calculation-date-picker.component.html',
  styleUrls: ['./calculation-date-picker.component.scss'],
})
export class CalculationDatePickerComponent implements OnInit {
  @Output() picked = new EventEmitter();

  calculationDate = new FormControl({ value: '', disabled: true }, Validators.required);
  minDate: Date;
  maxDate: Date;

  constructor() {}

  ngOnInit(): void {
    this.minDate = this.getMinimumDateForPicker();
    this.maxDate = this.getMaximumDateForPicker();

    this.calculationDate.valueChanges.pipe(untilDestroyed(this)).subscribe(this.emitFormattedDate.bind(this));
  }

  filterOutWeekends(date: Date): boolean {
    const day = (date || new Date()).getDay();
    const isSaturday = day === 6;
    const isSunday = day === 0;
    return !isSaturday && !isSunday;
  }

  private emitFormattedDate(dateString: string): void {
    const LOCALIZED_ISO_STRING_FORMAT = `yyyy-mm-dd'T'00:00:00+00:00`;
    const formattedDate = dateformat(dateString, LOCALIZED_ISO_STRING_FORMAT);
    this.picked.emit(formattedDate);
  }

  private getMinimumDateForPicker(): Date {
    // today
    return this.dateAfterX(1);
  }

  private getMaximumDateForPicker(): Date {
    // week after
    return this.dateAfterX(8);
  }

  private dateAfterX(numberOfDays: number): Date {
    const current = new Date();
    current.setDate(current.getDate() + numberOfDays);
    return current;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'triangle-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksComponent {
  constructor(private readonly router: Router) {}
  onLearnMore(): void {
    this.router.navigate(this.getTutorialUrlFragments());
  }

  private getTutorialUrlFragments(): string[] {
    return ['support', 'tutorial'];
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { DocumentRetrieverService, DocumentType } from 'triangle-core';

@Component({
  selector: 'triangle-landing-reports',
  templateUrl: './landing-reports.component.html',
  styleUrls: ['./landing-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingReportsComponent {
  readonly downloading$ = this.retriever.loading$;

  constructor(private readonly retriever: DocumentRetrieverService) {}

  onDownloadProdRefClick(): void {
    this.retriever
      .getDocument(DocumentType.EMA_STUDY)
      .pipe(take(1))
      .subscribe();
  }

  onOtherSolutionsClick(): void {
    window.open(this.getOtherSolutionsFileUrl(), '_blank');
  }

  private getOtherSolutionsFileUrl(): string {
    return `https://tractebel-engie.be/en/solutions/urban-infrastructure-environment`;
  }
}

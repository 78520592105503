import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  pulseAnimation,
  tadaAnimation,
} from 'angular-animations';
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  take,
} from 'rxjs/operators';
import { DrawerItem } from './layout.models';
@Component({
  selector: 'triangle-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    tadaAnimation(),
    fadeInOnEnterAnimation({ anchor: 'in', duration: 100 }),
    fadeOutOnLeaveAnimation({
      anchor: 'out',
      duration: 100,
      animateChildren: 'none',
    }),
    pulseAnimation({ anchor: 'attention', direction: '=>' }),
  ],
})
export class LayoutComponent implements OnInit {
  @Input() currentLanguage = 'en';
  @Input() languages: string[] = ['en', 'fr', 'nl'];
  private itemsSubject = new BehaviorSubject<DrawerItem[]>([]);
  @Input() set drawerItems(items: DrawerItem[]) {
    if (!!items) {
      this.itemsSubject.next(items);
    }
  }
  private loggedInUser = new BehaviorSubject<string>(undefined);
  @Input() set user(u: string) {
    this.loggedInUser.next(u);
  }

  @Output() languageSelect = new EventEmitter<string>();
  @Output() drawerSelect = new EventEmitter<string>();
  @Output() report = new EventEmitter();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() signup = new EventEmitter();
  private something = new BehaviorSubject<boolean>(false);
  @Output() viewProfile = new EventEmitter();

  private drawerOpened = new BehaviorSubject<boolean>(false);
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('video') videoAnchor: ElementRef;

  private hoverSubject = new BehaviorSubject<any>(undefined);
  /** Data streams */
  isDrawerOpen$ = this.drawerOpened.asObservable();
  loggedInUser$ = this.loggedInUser.asObservable();
  something$ = this.something.asObservable();
  commonItems$ = this.itemsSubject.pipe(
    map((items) => items.filter(({ adminOnly }) => !adminOnly))
  );
  adminItems$ = this.itemsSubject.pipe(
    map((items) => items.filter(({ adminOnly }) => adminOnly)),
    map((items) => (!!items.length ? items : undefined))
  );
  hovering$ = this.hoverSubject.asObservable();
  isHomePage$: Observable<boolean> = this.router.events.pipe(
    filter((event: RouterEvent) => event instanceof NavigationStart),
    map(({ url }) => url === '/' || url.includes('home')),
    distinctUntilChanged(),
    shareReplay(1)
  );
  isHomeAndTopPage$: Observable<boolean> = combineLatest([
    this.isHomePage$,
    this.scrollEventListener,
  ]).pipe(
    map((obs) => obs.every((o) => !!o)),
    distinctUntilChanged(),
    take(10),
  );

  constructor(private router: Router) {}
  ngOnInit(): void {
    this.isDrawerOpen$
      .pipe()
      .subscribe((isIt) => (isIt ? this.drawer.open() : this.drawer.close()));
    this.viewProfile.subscribe(() =>
      this.something.next(!this.something.value)
    );
  }

  onDrawerOpen = () => this.drawerOpened.next(true);
  onDrawerClose = () => this.drawerOpened.next(false);
  onSelectLanguage = (code: string) => this.languageSelect.emit(code);
  onDrawerSelect = (item: DrawerItem) => {
    this.drawerOpened.next(false);
    this.drawerSelect.emit(item.value);
  };
  onLogin = () => this.login.emit();
  onLogout = () => this.logout.emit();
  onSignup = () => this.signup.emit();
  onViewProfile = () => this.viewProfile.emit();
  onMouseEnter = (something: any) => this.hoverSubject.next(something);
  onMouseLeave = () => this.hoverSubject.next(undefined);

  onReportClick(): void {
    this.drawerOpened.next(false);
    this.report.emit();
  }

  private get scrollEventListener(): Observable<boolean> {
    return fromEvent(window, 'scroll').pipe(
      map(
        (event: ProgressEvent<Document>) =>
          event.target.scrollingElement.scrollTop
      ),
      startWith(0),
      map((scroll) => scroll <= 800),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  scrollDownToVideo(): void {
    this.videoAnchor.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { LandingMediaComponent } from './landing-media.component';

@NgModule({
  declarations: [LandingMediaComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule],
  exports: [LandingMediaComponent],
})
export class LandingMediaModule {}

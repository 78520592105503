import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SuccessCardModule } from 'triangle-shared/components/success-card';
import { TriangleCoreModule } from '../core';
import { SharedModule } from '../shared';
import { AccessCategoriesFormComponent, UsageReasonsComponent, UserInfoFormComponent } from './components';
import { SignUpPageComponent } from './containers';

@NgModule({
  imports: [CommonModule, TriangleCoreModule, SharedModule, SuccessCardModule, NgxCaptchaModule],
  declarations: [SignUpPageComponent, AccessCategoriesFormComponent, UserInfoFormComponent, UsageReasonsComponent],
  exports: [SignUpPageComponent, AccessCategoriesFormComponent],
})
export class SignUpModule {}

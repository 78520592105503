import { Injectable } from '@angular/core';
import { doNothing } from 'eyes-shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const SPINNER_ANIMATIONS = {
  triangleBalls: 'ball-triangle-path',
  timer: 'timer',
  ballNewton: 'ball-newton-cradle',
};

@Injectable()
export class TriangleLoaderService {
  readonly DEFAULT_SPINNER_CONFIG = {
    bdColor: 'rgba(255, 255, 255, 0.75)',
    color: '#ffbf71',
    fullScreen: false,
  } as Spinner;

  constructor(private spinner: NgxSpinnerService) {}

  show = (name = undefined, type = SPINNER_ANIMATIONS.triangleBalls) => {
    this.spinner.show(name, { ...this.DEFAULT_SPINNER_CONFIG, type });
  };

  idle = (name = undefined, type = SPINNER_ANIMATIONS.ballNewton) =>
    this.spinner.show(name, { ...this.DEFAULT_SPINNER_CONFIG, type, color: 'white' });

  hide = (name = undefined) => this.spinner.hide(name);
}

import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { mergeMapTo } from 'rxjs/operators';
import { MOBILE_WIDTH_PX } from '../../app.references';
import { ViewportQueryService } from './viewport-query.service';

@Injectable({
  providedIn: 'root',
})
export class TriangleSnackbar {
  readonly verticalPosition$ = new BehaviorSubject<MatSnackBarVerticalPosition>('bottom');
  readonly config: MatSnackBarConfig = {
    duration: 4000,
    horizontalPosition: 'right',
  };

  constructor(
    private _snackbar: MatSnackBar,
    private _viewport: ViewportQueryService,
    private router: Router,
    private _ngZone: NgZone,
    private _translate: TranslateService,
  ) {
    this._viewport
      .viewportAt$(MOBILE_WIDTH_PX)
      .subscribe((onMobile) => this.verticalPosition$.next(onMobile ? 'bottom' : 'top'));
  }

  simpleInfo = (message: string) => this._snackbar.open(message, undefined, this.config);

  simpleSuccess = (message: string, action?: string, custom?: MatSnackBarConfig) =>
    this._snackbar.open(`✔️ ${message}`, action, {
      ...this.config,
      ...custom,
      panelClass: 'triangle-snack-ok',
      verticalPosition: this.verticalPosition$.value,
    });

  simpleError = (message?: string, action?: string) => {
    const sensibleMsg = message || this._translate.instant('General.messages.genericError');
    this._snackbar.open(`❌ ${sensibleMsg}`, action, {
      ...this.config,
      panelClass: 'triangle-snack-nok',
      verticalPosition: this.verticalPosition$.value,
      duration: 10000,
    });
  };

  navigatingSuccess = ({ message, action, route }) =>
    this.simpleSuccess(message, action)
      .onAction()
      // .pipe(first())
      .subscribe(() => this._ngZone.run(() => this.router.navigate(route)));

  autoNavigateSuccess = ({ message, route }) => {
    const cancelled$ = new BehaviorSubject<boolean>(false);
    const snacky = this.simpleSuccess(message, this._translate.instant('General.buttons.cancel'), {
      duration: 2500,
    });
    // attach to the onAction() clicked event
    snacky
      .onAction()
      // .pipe(first())
      .subscribe(() => {
        cancelled$.next(true);
        snacky.dismiss();
      });
    // attach to afterDismissed() to check if cancel was clicked;
    snacky
      .afterDismissed()
      .pipe(mergeMapTo(cancelled$))
      .subscribe((cancelled) => {
        if (!cancelled) {
          this._ngZone.run(() => this.router.navigate(route));
        }
      });
  };
}

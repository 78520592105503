import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ConfigService, NotificationsService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import produce from 'immer';
import { first } from 'rxjs/operators';

import { StudyCreationStatus } from '../../app.references';
import { Project } from './project.action';
import { ProjectModel } from './project.models';
import { Injectable } from '@angular/core';

export interface ProjectStateModel {
  dataMap: { [key: string]: ProjectModel };
  fetchStatus: 'fetched' | 'fetching' | 'failed';
  creating: boolean;
  creationStatus?: StudyCreationStatus;
}

@State<ProjectStateModel>({
  name: 'project',
  defaults: { dataMap: {}, fetchStatus: undefined, creating: false },
})
@Injectable()
export class ProjectState {
  readonly api: { base; endpoints };
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private snackBar: NotificationsService,
    private actionSubject: Project.ActionSubject,
    private translate: TranslateService,
  ) {
    this.api = { base: this.config.api.baseUrl, endpoints: this.config.api.endpoints };
  }

  @Action(Project.GetList)
  getList({ patchState, dispatch, getState }: StateContext<ProjectStateModel>) {
    if (getState().fetchStatus) {
      return;
    }
    const { base, endpoints } = this.api;
    patchState({
      fetchStatus: 'fetching',
    });
    this.http.get(generateEndpoint(base, endpoints.project.list)).subscribe(
      ({ projects }: any) => dispatch(new Project.GetListSuccess({ projects })),
      ({ error }) => dispatch(new Project.GetListFailure({ error })),
    );
  }

  @Action(Project.GetListSuccess)
  getListSuccess({ patchState }: StateContext<ProjectStateModel>, { payload: { projects } }: Project.GetListSuccess) {
    const mappedProjs = projects.map((project) => ProjectModel.mapIncoming(project));
    patchState({
      fetchStatus: 'fetched',
      dataMap: mappedProjs.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    });
  }

  @Action(Project.GetListFailure)
  getListFailure({ patchState }: StateContext<ProjectStateModel>, { payload: { error } }: Project.GetListFailure) {
    patchState({ fetchStatus: 'failed' });
    this.snackBar.showError(this.translate.instant(`Project.errors.${error.error}`));
  }

  @Action(Project.Get)
  get({ dispatch, setState }: StateContext<ProjectStateModel>, { payload: { id } }: Project.Get) {
    const { base, endpoints } = this.api;
    setState(
      produce((draft: ProjectStateModel) => {
        draft.dataMap[id] = { id, loading: true };
      }),
    );
    this.http.get(generateEndpoint(base, endpoints.project.single, id)).subscribe(
      (data: any) => dispatch(new Project.GetSuccess({ data })),
      (error) => dispatch(new Project.GetFailure({ id, error })),
    );
  }

  @Action(Project.GetSuccess)
  getSuccess({ setState }: StateContext<ProjectStateModel>, { payload: { data } }: Project.GetSuccess) {
    setState(
      produce((draft: ProjectStateModel) => {
        const mapped = ProjectModel.mapIncoming(data);
        draft.dataMap[mapped.id] = mapped;
      }),
    );
  }

  @Action(Project.GetFailure)
  getFailure(ctx: StateContext<ProjectStateModel>, { payload: { id, error } }: Project.GetFailure) {
    ctx.setState(
      produce((draft: ProjectStateModel) => {
        draft.dataMap[id].loading = false;
        draft.dataMap[id].error = error;
      }),
    );
    this.snackBar.showError(this.translate.instant(`Project.errors.${error.error}`));
  }

  @Action(Project.Create)
  create({ patchState, dispatch }: StateContext<ProjectStateModel>, { payload: { name } }: Project.Create) {
    const { base, endpoints } = this.api;
    patchState({ creating: true, creationStatus: StudyCreationStatus.CreatingProject });
    this.http
      .post(generateEndpoint(base, endpoints.project.list), { name })
      .pipe(first())
      .subscribe(
        (data: any) => dispatch(new Project.CreateSuccess({ data })),
        ({ error }) => dispatch(new Project.CreateFailure({ error })),
      );
  }

  @Action(Project.CreateSuccess)
  createSuccess(ctx: StateContext<ProjectStateModel>, { payload: { data } }: Project.CreateSuccess) {
    ctx.setState(
      produce((draft: ProjectStateModel) => {
        const mapped = ProjectModel.mapIncoming(data);
        draft.creating = false;
        draft.creationStatus = undefined;
        draft.dataMap[mapped.id] = mapped;
      }),
    );
    this.actionSubject.dispatched$.next('createSucess');
  }

  @Action(Project.CreateFailure)
  createFailure({ patchState }: StateContext<ProjectStateModel>, { payload: { error } }: Project.CreateFailure) {
    patchState({ creating: false, creationStatus: undefined });
    this.snackBar.showError(this.translate.instant(`Project.errors.${error.error}`));
    this.actionSubject.dispatched$.next('createFailure');
  }
}

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GuardsCheckStart, ResolveStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthStore, ConfigService, HostedUIAuthService, PageStore } from 'eyes-core';
import { BaseComponent, LayoutComponent } from 'eyes-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { SupportFeature, SupportFeatures } from 'triangle-support/models';
import { FEATURES } from './app-routing.module';
import {
  APP_NAME,
  APP_VERSION,
  Language,
  MOBILE_WIDTH_PX,
  SUPPORTED_COUNTRIES,
  TRIANGLE_ADMIN_GROUP,
} from './app.references';
import { LocalCacheService, TriangleSnackbar, ViewportQueryService } from './core/services';
import { DrawerItem } from './layout';

@Component({
  selector: 'triangle-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  @ViewChild(LayoutComponent) layout: LayoutComponent;

  appName = APP_NAME.toUpperCase();

  /** Custom streams */
  navbarLinks$ = this.pageStore.sidenavLinks$.pipe(this.takeUntilShare());
  mobileMedia$ = this.viewportQuery.viewportAt$(MOBILE_WIDTH_PX);
  userInfo$ = this.authStore.data$.pipe(
    filter((_) => !!_),
    map(({ userInfo }) => userInfo),
  );
  userName$ = this.userInfo$.pipe(map((info) => (!!info ? `${info.firstName} ${info.lastName}` : 'profile')));
  isUserAdmin$ = this.userInfo$.pipe(map(({ groups }) => groups.includes(TRIANGLE_ADMIN_GROUP)));
  triangleFeatures$ = this.isUserAdmin$.pipe(
    map((isAdmin) => {
      return (
        Object.entries(FEATURES)
          // filter out features not supposed to show on drawer
          .filter(([, { showInDrawer }]) => showInDrawer)
          // filter out admin-exclusive features if not admin
          .filter(([, { adminOnly }]) => !adminOnly || isAdmin)
          .map(
            ([k, { icon, adminOnly, disabled }]) =>
              ({ disabled, icon, adminOnly, literalKey: `Layout.drawer.${k}`, value: k } as DrawerItem),
          )
      );
    }),
  );

  toolbarHeight: number;
  version = APP_VERSION;
  uploadOpened$ = new BehaviorSubject<boolean>(false);
  supportedCountries = SUPPORTED_COUNTRIES;
  modalConfig = {
    closeOnNavigation: true,
    disableClose: true,
  };
  currentLang$ = Language.currentLang$;
  routeResolving$: Observable<boolean>;

  constructor(
    private pageStore: PageStore,
    public authStore: AuthStore,
    private viewportQuery: ViewportQueryService,
    private hostedAuthService: HostedUIAuthService,
    private _snack: TriangleSnackbar,
    private _translate: TranslateService,
    private cache: LocalCacheService,
    private config: ConfigService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    // Initialize i18n translation for dem languages!
    this.initializeLanguage(Language.APP_LANGUAGES);
    this._translate.onLangChange.subscribe((langChange: LangChangeEvent) => this.cache.preferLanguage(langChange.lang));

    this.routeResolving$ = this.getRouteResolvingStream();
  }

  _synchronizeLang = (lang: string) =>
    this._translate
      .use(lang)
      .pipe(first())
      .subscribe(() => this.currentLang$.next(lang));

  initializeLanguage(supportedLangs = []) {
    registerLocaleData(localeFr);
    registerLocaleData(localeNl);
    // Load the stored lang (if any) or browser default lang
    const defaultLang = Language.getDefaultLanguage(
      this.cache.preferredLang$.value || this._translate.getBrowserLang(),
    );
    this._translate.addLangs(supportedLangs);
    this._synchronizeLang(defaultLang);
  }

  onLangSwitch = (selectedLang: string) => this._synchronizeLang(selectedLang);
  onLogout = () =>
    this.hostedAuthService
      .logout()
      .pipe(this.takeUntilShare())
      .subscribe(() => this._snack.simpleSuccess(this._translate.instant('General.messages.logout')));

  onLogin = () => this.hostedAuthService.launch();

  onDrawerSelect = (featureKey: string) => {
    const allegedPath = FEATURES[featureKey].path;
    if (!!allegedPath) {
      this.router.navigate([allegedPath]);
    }
    // special handling for 'features without paths'
  };

  onSignup = () => this.router.navigate([FEATURES.signup.path]);

  onReportIssue = () => this.router.navigate([FEATURES.support.path, SupportFeatures[SupportFeature.REPORT].route]);

  private getRouteResolvingStream(): Observable<boolean> {
    const loadingRouteStates = [ResolveStart, GuardsCheckStart];
    return this.router.events.pipe(map((event) => loadingRouteStates.some((state) => event instanceof state)));
  }
}

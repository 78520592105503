import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export namespace Project {
  const API_NAME = '[Project API] ';
  export type ListenableAction = 'createSucess' | 'createFailure';

  @Injectable({ providedIn: 'root' })
  export class ActionSubject {
    dispatched$ = new Subject<ListenableAction>();
  }

  export class GetList {
    static readonly type = API_NAME + 'Get List';
    constructor() {}
  }

  export class GetListSuccess {
    static readonly type = API_NAME + 'Get List Success';
    constructor(public payload: { projects: any[] }) {}
  }

  export class GetListFailure {
    static readonly type = API_NAME + 'Get List Failure';
    constructor(public payload: { error }) {}
  }

  export class Get {
    static readonly type = API_NAME + 'Get';
    constructor(public payload: { id: string }) {}
  }

  export class GetSuccess {
    static readonly type = API_NAME + 'Get Success';
    constructor(public payload: { data: any }) {}
  }

  export class GetFailure {
    static readonly type = API_NAME + 'Get Failure';
    constructor(public payload: { id: string; error }) {}
  }

  export class Create {
    static readonly type = API_NAME + 'Create';
    constructor(public payload: { name: string }) {}
  }

  export class CreateSuccess {
    static readonly type = API_NAME + 'Create Success';
    constructor(public payload: { data: any }) {}
  }

  export class CreateFailure {
    static readonly type = API_NAME + 'Create Failure';
    constructor(public payload: { error }) {}
  }
}

<div fxLayout="column" fxLayoutGap="8px" *ngIf="criteria$ | async as criteria">
  <div fxLayout="column" data-test="reasons">
    <span class="summary-label reasons-label">
      {{ 'CaseStudy.labels.selectedReasons' | translate }}
    </span>
    <ul class="summary-value" cla>
      <li *ngFor="let reason of reasons$ | async" data-test="reason">
        {{ 'CaseStudy.upload.' + reason | translate }}
      </li>
    </ul>
  </div>

  <div fxLayout="row" fxLayoutGap="64px">
    <div fxLayout="column" fxLayoutGap="4px" data-test="sites">
      <span class="summary-label">
        {{ 'CaseStudy.labels.numberOfSites' | translate }}
      </span>
      <span class="summary-value">
        {{ criteria.sites }}
      </span>
    </div>
    <div fxLayout="column" fxLayoutGap="4px" data-test="employees">
      <span class="summary-label">
        {{ 'CaseStudy.labels.numberOfEmployees' | translate }}
      </span>
      <span class="summary-value">
        {{ criteria.employees }}
      </span>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutGap="8px"
    class="analyze-fleet"
    fxLayoutAlign="center start"
  >
    <span class="summary-label">
      {{ 'AccessCategories.labels.idealPackage' | translate | titlecase }}:
    </span>
    <span class="summary-value" data-test="idealPackage">
      {{ 'AccessCategories.packages.' + package | translate | uppercase }}
    </span>
  </div>
</div>

/* tslint:disable: max-file-line-count*/

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  fadeInLeftOnEnterAnimation,
  fadeInOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeInUpOnEnterAnimation,
  flipInXOnEnterAnimation,
} from 'angular-animations';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LimitedPackages, ProductPackage } from '../../../core';
import { EmptyResults, Results } from './results-viewer.model';
import {
  AccessCategory,
  ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO,
  ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO,
} from 'triangle-signup/store';
import { StudyStatus, CaseStudyModel } from 'triangle-study/stores';
import { ScenarioTypes } from 'triangle-study/models';

const STRING_LIMIT = 20;
const hideColumnsScMc = [
  'co2_gain',
  'money_gain',
  'time_gain',
  'prio_co2',
  'prio_money',
  'prio_time',
  'priority',
  'name',
  'fast',
  'slow',
  'total',
  'parkingSpaces',
];
const hideColumnsSM = ['fast', 'slow', 'total', 'name', 'parkingSpaces'];
const hideColumnMobility = ['currentVehicleCategory', 'fuel'];
const hideColumnsEse = [
  'co2_gain',
  'money_gain',
  'time_gain',
  'prio_co2',
  'prio_money',
  'prio_time',
  'priority',
  'originId',
  'transportMode',
  'duration',
  'distance',
  'vehicleCategory',
  'currentVehicleCategory',
  'fuel',
  'destinationName',
];
@Component({
  selector: 'triangle-results-viewer',
  templateUrl: './results-viewer.component.html',
  styleUrls: ['./results-viewer.component.scss'],
  animations: [
    flipInXOnEnterAnimation({ anchor: 'rowIn', duration: 1000 }),
    fadeInOnEnterAnimation({ anchor: 'tableIn', duration: 500 }),
    fadeInUpOnEnterAnimation({ anchor: 'bottomIn', duration: 1000 }),
    fadeInLeftOnEnterAnimation({ anchor: 'backIn' }),
    fadeInRightOnEnterAnimation({ anchor: 'exportIn' }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsViewerComponent implements OnInit, OnChanges {
  readonly COLUMN_MAP = {
    originId: 'originId',
    destinationName: 'destinationName',
    transportMode: 'modeLabel',
    currentVehicleCategory: 'vehicleCategory',
    fuel: 'fuelType',
    duration: 'duration',
    distance: 'distance',
    co2_gain: 'co2Gain',
    money_gain: 'moneyGain',
    time_gain: 'timeGain',
    prio_co2: 'prioCo2',
    prio_money: 'prioMoney',
    prio_time: 'prioTime',
    priority: 'priority',
    name: 'destinationName',
    fast: 'fastCharging',
    slow: 'slowCharging',
    total: 'totalCharging',
    parkingSpaces: 'parkingSpaces',
  };

  columnDefs: string[];
  readonly columnColors = {
    '#e7f7ef': ['co2_gain', 'money_gain', 'time_gain'],
    '#fef4e8': ['prio_co2', 'prio_money', 'prio_time'],
    '#feead1': ['priority'],
  };
  /** Input, Output, and Misc. Directives */
  @Input() backUrl: string;
  @Input() title: string;
  @Input() loading: boolean;
  @Input() exporting: boolean;
  @Input() creating = false;
  @Input() isFleet: boolean;

  private results$ = new BehaviorSubject<Results>(EmptyResults);
  @Input() set results(r: Results) {
    if (!!r) {
      this.results$.next(r);
    }
  }
  private applicablePkg$ = new BehaviorSubject<ProductPackage>(ProductPackage.NA);
  @Input() set package(pkg: ProductPackage) {
    if (!!pkg) {
      this.applicablePkg$.next(pkg);
    }
  }
  @Output() export = new EventEmitter();
  @Output() create = new EventEmitter<string>();
  private packageSubject = new BehaviorSubject<AccessCategory>(AccessCategory.Free);
  private scenarioIsMobility$ = new BehaviorSubject<boolean>(false);

  /** Multiple Paginator Solution */
  readonly pageOptions = [10, 20];
  @ViewChild('s1m1Paginator') s1m1Paginator: MatPaginator;
  @ViewChild('s1m2Paginator') s1m2Paginator: MatPaginator;
  @ViewChild('s2m1Paginator') s2m1Paginator: MatPaginator;
  @ViewChild('scmcPaginator') scmcPaginator: MatPaginator;
  @ViewChild('chargingSpacesTabs', { static: false }) chargingSpacesTabs;

  private activeTabIdx = new BehaviorSubject<number>(0);
  private statusSubject = new BehaviorSubject<StudyStatus>(StudyStatus.PENDING);
  public scenario: typeof ScenarioTypes = ScenarioTypes;

  activeTabIdx$ = this.activeTabIdx.asObservable();

  limitedPackage$ = this.applicablePkg$.pipe(map((pkg) => LimitedPackages.includes(pkg)));
  s1m1$ = this.results$.pipe(map(({ s1m1 }) => s1m1 || []));
  s1m1NonEmpty$ = this.s1m1$.pipe(map((s1m1) => s1m1.length > 0));
  s1m1Datasource$ = this.s1m1$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      datasource.paginator = this.s1m1Paginator;
      return datasource;
    }),
  );
  s1m2$ = this.results$.pipe(map(({ s1m2 }) => s1m2 || []));
  s1m2NonEmpty$ = this.s1m2$.pipe(map((s1m2) => s1m2.length > 0));
  s1m2Datasource$ = this.s1m2$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      datasource.paginator = this.s1m2Paginator;
      return datasource;
    }),
  );
  s2m1$ = this.results$.pipe(map(({ s2m1 }) => s2m1 || []));
  s2m1NonEmpty$ = this.s2m1$.pipe(map((s2m1) => s2m1.length > 0));
  s2m1Datasource$ = this.s2m1$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      datasource.paginator = this.s2m1Paginator;
      return datasource;
    }),
  );
  scmc$ = this.results$.pipe(map(({ scmc }) => scmc || []));
  scmcNonEmpty$ = this.scmc$.pipe(map((scmc) => scmc.length > 0));
  scmcDataSource$ = this.scmc$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      datasource.paginator = this.scmcPaginator;
      return datasource;
    }),
  );

  hasChargingSpaces$ = this.results$.pipe(
    map(({ scs }) => {
      if (!!scs) {
        const { s1m1_charging_stations, s1m2_charging_stations, s2m1_charging_stations, scmc_charging_stations } = scs;
        return [s1m1_charging_stations, s1m2_charging_stations, s2m1_charging_stations, scmc_charging_stations].some(
          (result_set) => !!result_set.length,
        );
      }

      return false;
    }),
  );

  s1m1SCS$ = this.results$.pipe(
    map(({ scs }) => {
      return scs.s1m1_charging_stations || [];
    }),
  );
  s1m1SCSNonEmpty$ = this.s1m1SCS$.pipe(map((scs) => scs.length > 0));
  s1m1SCSDataSource$ = this.s1m1SCS$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      return datasource;
    }),
  );

  s1m2SCS$ = this.results$.pipe(
    map(({ scs }) => {
      return scs.s1m2_charging_stations || [];
    }),
  );
  s1m2SCSNonEmpty$ = this.s1m2SCS$.pipe(map((scs) => scs.length > 0));
  s1m2SCSDataSource$ = this.s1m2SCS$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      return datasource;
    }),
  );

  s2m1SCS$ = this.results$.pipe(
    map(({ scs }) => {
      return scs.s2m1_charging_stations || [];
    }),
  );
  s2m1SCSNonEmpty$ = this.s2m1SCS$.pipe(map((scs) => scs.length > 0));
  s2m1SCSDataSource$ = this.s2m1SCS$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      return datasource;
    }),
  );

  scmcSCS$ = this.results$.pipe(
    map(({ scs }) => {
      return scs.scmc_charging_stations || [];
    }),
  );
  scmcSCSNonEmpty$ = this.scmcSCS$.pipe(map((scs) => scs.length > 0));
  scmcSCSDataSource$ = this.scmcSCS$.pipe(
    map((data) => {
      const datasource = new MatTableDataSource(data);
      return datasource;
    }),
  );

  private nameSubject = new BehaviorSubject<string>('');

  @Input() set study(study: CaseStudyModel) {
    this.statusSubject.next(study.status);
    this.packageSubject.next(study.productPackage);
    this.nameSubject.next(study.name);
  }

  @Input() set scenarioType(type: ScenarioTypes) {
    if (type === ScenarioTypes.Mobility || type === undefined) {
      this.scenarioIsMobility$.next(true);
    }
  }

  canCreateFleet$ = this.canCreateFleetScenarioConditionStream();
  canCreateStandardFleet$ = this.isPackageEligibleForStandardFleetScenarioStream();
  canCreateAdvancedFleet$ = this.isPackageEligibleForAdvancedFleetScenarioStream();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.columnDefs = this.generateColumns();
  }

  onExportClick = () => this.export.emit();
  ellipsisIfApplicable = (expression: string) =>
    expression.substring(0, STRING_LIMIT).concat(expression.length > STRING_LIMIT ? '...' : '');

  roundIfApplicable = (expression: any) =>
    isNaN(expression) ? this.ellipsisIfApplicable(expression || '') : Math.round(expression * 100) / 100;

  appropriateBGColor = (columnDef: string) =>
    Object.keys(this.columnColors).find((colorCode) => (this.columnColors[colorCode] || []).includes(columnDef));

  getTableHeaderKey = (translationKey: string) => `CaseStudy.labels.${this.COLUMN_MAP[translationKey]}`;

  getTransportationModeKey = (transportationMode: string) => `General.transportModes.${transportationMode}`;

  onTabChange = (event: MatTabChangeEvent) => {
    if (!!!event) {
      return;
    }
    this.activeTabIdx.next(event.index);
  };

  filterSMColumns(arr: Array<string>): Array<string> {
    if (!!arr) {
      return arr.filter((column) => hideColumnsSM.indexOf(column) < 0);
    }
  }

  filterScMcColumns(arr: Array<string>): Array<string> {
    if (!!arr) {
      return arr.filter((column) => hideColumnsScMc.indexOf(column) < 0);
    }
  }

  filterESEColumns(arr: Array<string>) {
    if (!!arr) {
      return arr.filter((column) => hideColumnsEse.indexOf(column) < 0);
    }
  }

  generateColumns() {
    return Object.keys(this.COLUMN_MAP)
      .filter((header) => header !== 'error')
      .filter((data) => {
        if (this.isFleet) {
          return data;
        }
        return !hideColumnMobility.includes(data);
      });
  }

  private canCreateFleetScenarioConditionStream(): Observable<boolean | any> {
    return combineLatest([this.isPackageFleetStream(), this.isStudyValidatedStream(), this.scenarioIsMobility$]).pipe(
      map((conditions) => {
        return conditions.every((condition) => condition);
      }),
    );
  }

  private isPackageEligibleForAdvancedFleetScenarioStream(): Observable<boolean> {
    return this.packageSubject.pipe(map((pkg) => ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO.includes(pkg)));
  }

  private isPackageEligibleForStandardFleetScenarioStream(): Observable<boolean> {
    return this.packageSubject.pipe(map((pkg) => ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO.includes(pkg)));
  }

  private isStudyValidatedStream(): Observable<boolean> {
    return this.statusSubject.pipe(map((status) => status === StudyStatus.VALIDATED));
  }

  private isPackageFleetStream(): Observable<boolean | any> {
    return combineLatest([
      this.isPackageEligibleForStandardFleetScenarioStream(),
      this.isPackageEligibleForAdvancedFleetScenarioStream(),
    ]).pipe(map((conditions) => conditions.some((condition) => condition)));
  }

  onCreate(): void {
    this.create.emit();
  }

  onParentTabChange(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.chargingSpacesTabs.realignInkBar();
    }
  }
}

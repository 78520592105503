import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from 'eyes-core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectionGuard implements CanActivate {
  constructor(private authGuard: AuthGuard, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const authy = this.authGuard.canActivate(next, state) as Observable<boolean>;
    return authy.pipe(tap((isAuthorized) => (isAuthorized ? {} : this.router.navigate(['/home']))));
  }
}

<!-- main and paginator -->
<div class="full-height" fxLayout="column" fxLayoutAlign="space-between none">
  <!-- inputs and table -->
  <div fxLayout="column" fxLayoutGap="16px" fxFlex="90%">
    <!-- inputs and add button -->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
      <!-- inputs -->
      <div
        class="export-exclusion-inputs"
        [class.exclusion-inputs-error]="!(isCombinationUnique$ | async)"
        fxLayout="row"
        fxLayoutGap="16px"
        [formGroup]="singleCriteriaForm"
      >
        <mat-form-field>
          <mat-label>{{ 'CaseStudy.labels.siteLabel' | translate }}</mat-label>
          <mat-select formControlName="site">
            <mat-option *ngFor="let site of sites" [value]="site.value">
              {{ site.name }}
            </mat-option>
          </mat-select>
          <mat-hint
            [@slideDown]
            class="error-hint"
            *ngIf="!(isCombinationUnique$ | async)"
            [innerHTML]="'CaseStudy.errors.duplicateCombo' | translate"
          >
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'CaseStudy.labels.depLabel' | translate }}</mat-label>
          <mat-select formControlName="department">
            <mat-option *ngFor="let department of departments" [value]="department.value">
              {{ department.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'CaseStudy.labels.modeLabel' | translate }}</mat-label>
          <mat-select formControlName="mode">
            <mat-option *ngFor="let mode of modes" [value]="mode.value">
              {{ getTransportModeTransKey(mode.name) | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-button color="accent" (click)="onAddExclusion()" [disabled]="addBtnDisable$ | async">
        <mat-icon>add</mat-icon>
        {{ 'CaseStudy.actions.add' | translate }}
      </button>
    </div>
    <div class="triangle-table-container export-exclusion-table" fxLayout="column" fxLayoutAlign="start stretch">
      <table mat-table #table [dataSource]="dataSource$ | async">
        <ng-container matColumnDef="site">
          <th mat-header-cell *matHeaderCellDef>
            {{ getHeaderLabelTransKey(COLUMN_DEF_MAPPING['site']) | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ mapSite(row['site']) }}</td>
        </ng-container>
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>
            {{ getHeaderLabelTransKey(COLUMN_DEF_MAPPING['department']) | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ mapDepartment(row['department']) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="mode">
          <th mat-header-cell *matHeaderCellDef>
            {{ getHeaderLabelTransKey(COLUMN_DEF_MAPPING['mode']) | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ getTransportModeTransKey(mapMode(row['mode'])) | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="clear">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-button color="warn" [disabled]="clearBtnDisable$ | async" (click)="onClearFilters()">
              {{ 'CaseStudy.actions.clearFilters' | translate }}
            </button>
          </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="onDelete(row)">
              <mat-icon>delete_sweep</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="COLUMN_DEFS; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: COLUMN_DEFS"></tr>
      </table>
    </div>
  </div>
  <mat-paginator fxFlex [pageSizeOptions]="[2]" showFirstLastButtons></mat-paginator>
</div>

<!-- Auto completation -->
<mat-autocomplete autoActiveFirstOption #siteAuto="matAutocomplete">
  <mat-option *ngFor="let site of sites" [value]="site.value">
    {{ site.name }}
  </mat-option>
</mat-autocomplete>
<mat-autocomplete autoActiveFirstOption #departmentAuto="matAutocomplete">
  <mat-option *ngFor="let department of departments" [value]="department.value">
    {{ department.name }}
  </mat-option>
</mat-autocomplete>
<mat-autocomplete autoActiveFirstOption #modeAuto="matAutocomplete">
  <mat-option *ngFor="let mode of modes" [value]="mode.value">
    {{ getTransportModeTransKey(mode.name) | translate }}
  </mat-option>
</mat-autocomplete>

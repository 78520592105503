import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WorkPoint, WORK_POINT_NUMBER } from './work-point.tokens';

@Component({
  selector: 'triangle-work-point',
  templateUrl: './work-point.component.html',
  styleUrls: ['./work-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkPointComponent {
  private readonly pointCache = new BehaviorSubject<WorkPoint>(null);
  @Input() set point(point: WorkPoint) {
    this.pointCache.next(point);
  }
  readonly point$ = this.selectTruthyPoint();
  readonly number$ = this.selectWorkPointNumber();
  @Input() rtl = false;

  private selectWorkPointNumber(): Observable<number> {
    return this.selectTruthyPoint().pipe(
      map((point) => WORK_POINT_NUMBER[point]),
    );
  }

  private selectTruthyPoint(): Observable<WorkPoint> {
    return this.pointCache.pipe(filter((point) => !!point));
  }
}

<div fxLayout="column" fxLayoutAlign="space-between center">
  <mat-form-field class="price-container">
    <mat-label>{{ 'Upload.labels.price' | translate }}</mat-label>
    <triangle-proforma-preview matSuffix (preview)="onPreview()" *ngIf="debouncedValid$ | async; else tollIcon">
    </triangle-proforma-preview>
    <!-- else block -->
    <ng-template #tollIcon>
      <mat-icon matSuffix>toll</mat-icon>
    </ng-template>
    <mat-spinner matPrefix color="primary" diameter="20" *ngIf="fetchingPrice$ | async"></mat-spinner>
    <input class="price-figure" matInput [value]="price$ | async" disabled />
    <mat-hint>
      <div fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="5px">
        <span *ngIf="debouncedValid$ | async" [style.letter-spacing.px]="1.5">
          <b>{{ 'Upload.hints.vat_price' | translate }}</b>
        </span>
        <span>{{ 'Upload.hints.price' | translate }}</span>
      </div>
    </mat-hint>
  </mat-form-field>
</div>

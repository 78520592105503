export interface ProjectModel {
  error?: string;
  loading: boolean;
  id: string;
  name?: string;
  description?: string;
}

export namespace ProjectModel {
  export const mapIncoming = ({ projectUuid, name }: any): ProjectModel => ({
    id: projectUuid,
    name: name || '',
    loading: false,
  });
}

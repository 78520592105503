import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VidPlayerComponent } from 'triangle-shared/components/vid-player';

@Component({
  selector: 'triangle-landing-media',
  templateUrl: './landing-media.component.html',
  styleUrls: ['./landing-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingMediaComponent {
  constructor(private readonly dialog: MatDialog) {}

  onWatchVideo(): void {
    this.dialog.open(VidPlayerComponent);
  }
}

/* tslint:disable: max-file-line-count*/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { saveAs } from 'file-saver';
import { first, map, mergeMap } from 'rxjs/operators';
import { CalculationResultsModel } from '../../+case-study/stores';
import { Language } from '../../app.references';
import { blobToString$ } from '../../core';
import { TriangleSnackbar } from '../../core/services';
import { Scenario } from './scenario.actions';
import { ScenarioModel } from './scenario.models';

export interface ScenarioStateModel {
  creating: boolean;
  fetching: boolean;
  gettingResults: boolean;
  fetchedFor: string[];
  dataMap: { [key: string]: ScenarioModel };
  results: any;
  downloadingResults: boolean;
}

@State<ScenarioStateModel>({
  name: 'scenario',
  defaults: {
    creating: false,
    fetching: false,
    fetchedFor: [],
    dataMap: {},
    results: {},
    gettingResults: false,
    downloadingResults: false,
  },
})
@Injectable()
export class ScenarioState {
  constructor(
    private translate: TranslateService,
    private snackBar: TriangleSnackbar,
    private _http: HttpClient,
    private _config: ConfigService,
  ) {
    Language.currentLang$.subscribe((language) => (this.currentLanguage = language));
  }

  readonly defaultLanguage = 'en';
  currentLanguage = Language.getDefaultLanguage(this.defaultLanguage);

  @Action(Scenario.Create)
  create(
    { patchState, dispatch }: StateContext<ScenarioStateModel>,
    { payload: { projectId, caseId, data } }: Scenario.Create,
  ) {
    patchState({ creating: true });
    const { baseUrl, endpoints } = this._config.api;
    this._http
      .post(generateEndpoint(baseUrl, endpoints.study.exclusionScenarios, projectId, caseId), { ...data })
      .subscribe(
        () => {
          dispatch(new Scenario.CreateSuccess({ data: 'success' }));
        },
        ({ error }) => {
          dispatch(new Scenario.CreateFailure({ error }));
        },
      );
  }

  @Action(Scenario.CreateSuccess)
  createSuccess({ patchState }: StateContext<ScenarioStateModel>, { payload: { data } }: Scenario.CreateSuccess) {
    this.snackBar.simpleSuccess(this.translate.instant('Scenario.messages.createSuccess'));
    patchState({ creating: false, fetchedFor: [] });
  }

  @Action(Scenario.CreateFailure)
  createFailure({ patchState }: StateContext<ScenarioStateModel>, { payload: { error } }: Scenario.CreateFailure) {
    this.snackBar.simpleError(this.translate.instant(`Scenario.errors.${error.error}`));
    patchState({ creating: false });
  }

  @Action(Scenario.Get)
  get(ctx: StateContext<ScenarioStateModel>, { payload: { projectId, caseId, scenarioId } }: Scenario.Get) {
    const { baseUrl, endpoints } = this._config.api;
    ctx.patchState({ fetching: true, results: {} });
    this._http.get(generateEndpoint(baseUrl, endpoints.scenario.single, projectId, caseId, scenarioId)).subscribe(
      (data) => {
        ctx.dispatch(new Scenario.GetSuccess({ data }));
      },
      ({ error }) => {
        ctx.dispatch(new Scenario.GetFailure(error));
      },
    );
  }

  @Action(Scenario.GetSuccess)
  getSuccess(ctx: StateContext<ScenarioStateModel>, { payload: { data } }: Scenario.GetSuccess) {
    const newDataMap = { ...ctx.getState().dataMap };
    const newModel = ScenarioModel.mapIncoming(data);
    newDataMap[data.scenarioUuid] = newModel;
    ctx.patchState({ fetching: false, dataMap: { ...newDataMap } });
  }

  @Action(Scenario.GetFailure)
  getFailure(ctx: StateContext<ScenarioStateModel>, { payload: { error } }: Scenario.GetFailure) {
    this.snackBar.simpleError(this.translate.instant(`Scenario.errors.${error.error}`));
    ctx.patchState({ fetching: false });
  }

  @Action(Scenario.GetList)
  getList(
    { patchState, dispatch, getState }: StateContext<ScenarioStateModel>,
    { payload: { projectId, caseId } }: Scenario.GetList,
  ) {
    if (getState().fetchedFor.includes(caseId)) {
      return;
    }
    const { baseUrl, endpoints } = this._config.api;
    patchState({ fetching: true, dataMap: {} });
    this._http.get(generateEndpoint(baseUrl, endpoints.scenario.list, projectId, caseId)).subscribe(
      ({ scenarios }: any) => dispatch(new Scenario.GetListSuccess({ scenarios, caseId })),
      ({ error }) => dispatch(new Scenario.GetListFailure({ error })),
    );
  }

  @Action(Scenario.GetListSuccess)
  getListSuccess(
    { patchState }: StateContext<ScenarioStateModel>,
    { payload: { scenarios, caseId } }: Scenario.GetListSuccess,
  ) {
    const mappedData = scenarios.map((scenario) => ScenarioModel.mapIncoming(scenario));
    const fetchedMap = mappedData.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    patchState({
      fetching: false,
      fetchedFor: [caseId],
      dataMap: { ...fetchedMap },
    });
  }

  @Action(Scenario.GetListFailure)
  getListFailure(ctx: StateContext<ScenarioStateModel>, { payload: { error } }: Scenario.GetListFailure) {
    this.snackBar.simpleError(this.translate.instant(`Scenario.errors.${error.error}`));
    ctx.patchState({ fetching: false });
  }

  @Action(Scenario.GetResults)
  getResults({ patchState, dispatch }, action: Scenario.GetResults) {
    patchState({ gettingResults: true });
    const { baseUrl, endpoints } = this._config.api;
    const { projectId, caseId, scenarioId } = action.payload;
    this._http
      .get(generateEndpoint(baseUrl, endpoints.scenario.results, projectId, caseId, scenarioId))
      .pipe(
        mergeMap(({ resultsUrl }: any) => this._http.get(resultsUrl)),
        mergeMap((fileBlob) => blobToString$(fileBlob)),
        map((blobString) => JSON.parse(blobString as string)),
      )
      .subscribe(
        ({ results }) => dispatch(new Scenario.GetResultsSuccess({ results })),
        ({ error }) => dispatch(new Scenario.GetResultsFailure({ error })),
      );
  }

  @Action(Scenario.GetResultsSuccess)
  downloadJsonResults({ patchState, dispatch }, action: Scenario.GetResultsSuccess) {
    const mappedResults = CalculationResultsModel.mapIncoming(action.payload.results);
    patchState({ results: mappedResults, gettingResults: false });
  }

  @Action(Scenario.GetResultsFailure)
  getResultsFailure(ctx: StateContext<ScenarioStateModel>, { payload: { error } }: Scenario.GetResultsFailure) {
    this.snackBar.simpleError(this.translate.instant(`Scenario.errors.${error.error}`));
    ctx.patchState({ results: [], gettingResults: false });
  }

  @Action(Scenario.DownloadResults)
  downloadResults(
    { dispatch, patchState }: StateContext<ScenarioStateModel>,
    { payload: { projectId, caseId, scenarioId, filter } }: Scenario.DownloadResults,
  ) {
    const { baseUrl, endpoints } = this._config.api;
    patchState({ downloadingResults: true });
    const jsonStringParams = ['distance', 'duration'];
    const sanitizedQSParams = jsonStringParams
      .filter((jsp) => jsp in filter)
      .reduce(
        // construct the sanitized qs param
        (acc, curr) => {
          acc[curr] = JSON.stringify(filter[curr]);
          return acc;
        },
        { ...filter, lang: this.currentLanguage },
      );
    this._http
      .get(generateEndpoint(baseUrl, endpoints.scenario.downloadResults, projectId, caseId, scenarioId), {
        params: sanitizedQSParams as any,
      })
      .pipe(first())
      .subscribe(
        (data: any) => {
          dispatch(
            new Scenario.DownloadResultsSuccess({
              url: data.resultsUrl,
              scenarioId,
              format: filter.format,
            }),
          );
        },
        ({ error }) => dispatch(new Scenario.DownloadResultsFailure({ error, scenarioId })),
      );
  }

  @Action(Scenario.DownloadResultsSuccess)
  async downloadResultsSuccess(
    { getState, patchState, dispatch }: StateContext<ScenarioStateModel>,
    { payload: { url, scenarioId, format } }: Scenario.DownloadResultsSuccess,
  ) {
    this._http.get(url, { observe: 'response' }).subscribe(
      (r) => {
        const matchedStudy = getState().dataMap[scenarioId];
        let filename = `results_${matchedStudy.name}.${format}`;
        if (format === 'employee_details') {
          filename = `Employee_details_${matchedStudy.name}.xlsx`;
        }

        saveAs(r.body as Blob, filename);
        this.snackBar.simpleSuccess(this.translate.instant('Scenario.messages.downloadResultsSuccess'));
        patchState({ downloadingResults: false });
      },
      (error) => {
        if (error.status === 404) {
          const timeout = format === 'pdf' ? 10000 : 3000;
          setTimeout(() => {
            dispatch(new Scenario.DownloadResultsSuccess({ url, scenarioId, format }));
          }, timeout);
        }
      },
    );
  }

  @Action(Scenario.DownloadResultsFailure)
  downloadResultsFailure(
    { patchState }: StateContext<ScenarioStateModel>,
    { payload: { error, scenarioId } }: Scenario.DownloadResultsFailure,
  ) {
    this.snackBar.simpleError(this.translate.instant(`Scenario.errors.${error.error}`));
    patchState({ downloadingResults: false });
  }

  @Action(Scenario.CreateFleet)
  createFleet(
    { patchState, dispatch }: StateContext<ScenarioStateModel>,
    { payload: { projectId, caseId, data } }: Scenario.CreateFleet,
  ) {
    patchState({ creating: true });
    const { baseUrl, endpoints } = this._config.api;
    const { isFromResult, scenarioId, ...postBody } = data;

    const endPoint = isFromResult ? endpoints.scenario.resultFleet : endpoints.study.fleetScenarios;
    this._http.post(generateEndpoint(baseUrl, endPoint, projectId, caseId, scenarioId), { ...postBody }).subscribe(
      (res) => {
        dispatch(new Scenario.CreateSuccess({ data: 'success' }));
      },
      ({ error }) => {
        dispatch(new Scenario.CreateFailure({ error }));
      },
    );
  }
}

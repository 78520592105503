import { Inject, Injectable } from '@angular/core';
import { ApiConfig, API_CONFIG } from 'eyes-core';
import { newEndpoints } from 'triangle-env';

@Injectable({
  providedIn: 'root',
})
export class EndpointProvider {
  constructor(@Inject(API_CONFIG) private readonly api: ApiConfig) {}

  forGetDocument(): string {
    return this.joinWithBaseUrl('getDocument');
  }

  forDiscoverDemo(): string {
    return this.joinWithBaseUrl('discoverDemo');
  }

  private joinWithBaseUrl(path: string): string {
    return `${this.api.baseUrl}${newEndpoints[path]}`;
  }
}

export enum WorkPoint {
  ANALYSIS = 'analysis',
  VISUALIZATION = 'visualization',
  SIMULATION = 'simulation',
  SECURITY = 'security',
  TRUSTWORTHY = 'trustworthy',
}

export const WORK_POINT_NUMBER: Record<WorkPoint, number> = {
  [WorkPoint.ANALYSIS]: 1,
  [WorkPoint.VISUALIZATION]: 2,
  [WorkPoint.SIMULATION]: 3,
  [WorkPoint.SECURITY]: 4,
  [WorkPoint.TRUSTWORTHY]: 5,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CalculationDatePickerComponent } from './calculation-date-picker.component';

@NgModule({
  declarations: [CalculationDatePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    TranslateModule,
    MatNativeDateModule,
  ],
  exports: [CalculationDatePickerComponent],
})
export class CalculationDatePickerModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursPipe',
})
export class HoursPipePipe implements PipeTransform {
  HR_LABEL = '';
  AND_LABEL = '';
  MIN_LABEL = '';
  transform(
    value: string | number,
    hourStr: string = 'h',
    andStr: string = 'and',
    minStr: string = 'm',
  ): string | undefined {
    if (isNaN(+value) || +value <= 0) {
      return undefined;
    } else {
      this.HR_LABEL = hourStr;
      this.AND_LABEL = andStr;
      this.MIN_LABEL = minStr;
      return this.roundHours(+value);
    }
  }

  roundHours(mins: number): string {
    if (mins <= 60) {
      return `${Math.round(mins)}`;
    } else {
      const hourValue = Math.floor(+mins / 60);
      const minutes = Math.round(+mins % 60);
      return this.formatHrMin(hourValue, minutes);
    }
  }

  formatHrMin(hr: number, min: number): string {
    if (min === 0) {
      return `${hr}${this.HR_LABEL}`;
    } else {
      return `${hr}${this.HR_LABEL} ${min}${this.MIN_LABEL}`;
    }
  }
}

<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center" class="media-link">
    <img
      src="/assets/homepage/watch_the_video_icon.png"
      data-test="watchVideo"
      class="cursor-pointer"
      (click)="onWatchVideo()"
    />
    <span class="media-header">
      {{ 'Homepage.texts.watchVideo' | translate | uppercase }}
    </span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start end">
    <div fxLayout="column" fxLayoutGap="32px" class="media-punch-line">
      <span>{{ 'Homepage.texts.andProtectOur' | translate }}</span>
      <span class="media-environment">
        {{ 'Homepage.texts.environment' | translate }}
      </span>
    </div>
  </div>
</div>

import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewportQueryService {
  viewportAt$ = (widthInPx: number) =>
    this.breakpoint.observe([`(max-width: ${widthInPx}px)`]).pipe(map((state) => state.matches));

  constructor(private breakpoint: BreakpointObserver) {}
}

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="160px">
  <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start start">
    <div
      fxLayout="column"
      fxLayoutGap="16px"
      data-test="howItWorksHeader"
      class="how-it-works-header"
    >
      <span>{{ 'Homepage.texts.works' | translate }}</span>
      <span>{{ 'Homepage.texts.worksReport' | translate }}</span>
    </div>
    <button
      data-test="learnMore"
      mat-flat-button
      class="actions-primary learn-more-btn"
      color="accent"
      (click)="onLearnMore()"
    >
      {{ 'Homepage.texts.learnMore' | translate | uppercase }}
    </button>
  </div>
  <triangle-work-points data-test="workPoints"></triangle-work-points>
</div>

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AuthData, AuthStore, User, UserFacadeService } from 'eyes-core';
import { Observable, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<User> {
  constructor(
    private authStore: AuthStore,
    private userFacade: UserFacadeService,
  ) {}

  resolve(): Observable<User> {
    return this.authStore.data$.pipe(
      tap(this.fetchUserInfo.bind(this)),
      mergeMap(this.determineStreamToRelay.bind(this)),
      take(1),
    );
  }

  private fetchUserInfo(data: AuthData) {
    if (!!data) {
      this.userFacade.getByEmail(data.userInfo.email);
    }
  }

  private determineStreamToRelay(data: AuthData) {
    return !!data ? this.userFacade.clientUser$ : of({});
  }
}

import { GetInTouchReason } from './support.model';

export abstract class GetInTouchMetadata {
  abstract reason: GetInTouchReason;

  stringify(): string {
    return JSON.stringify(this);
  }
}

export class EnterpriseMetadata extends GetInTouchMetadata {
  reason = GetInTouchReason.ENTERPRISE;
}

export class AdvancedMapMetadata extends GetInTouchMetadata {
  reason = GetInTouchReason.ADVANCED_MAP;
  projectId: string;
  studyId: string;

  constructor(projectId: string, studyId: string) {
    super();
    this.projectId = projectId;
    this.studyId = studyId;
  }
}

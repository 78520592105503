<div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start start">
    <button mat-button class="iconized-button" (click)="onBack()">
      <mat-icon>arrow_back</mat-icon>
      {{ 'Upload.buttons.back' | translate }}
    </button>
  </div>
  <div class="half-viewport-height" fxLayoutAlign="center center">
    <triangle-spinner *ngIf="!(previewTemplate$ | async); else loaded"></triangle-spinner>
    <ng-template #loaded>
      <embed class="full-height full-width" [src]="templateInBase64$ | async | sanitize" />
    </ng-template>
  </div>
</div>

<div class="container" fxFlex fxLayout="column" fxLayoutAlign="none center" fxLayoutGap="2em">
  <h2>Choose your type of scenario</h2>
  <mat-icon class="close" (click)="onClose()">close</mat-icon>
  <div
    class="options"
    [ngClass]="{
      'column-one': options.length === 1,
      'column-two': options.length === 2
    }"
  >
    <ng-container *ngFor="let option of options; let i = index">
      <triangle-create-scenario-option-button
        [hasBorder]="i > 0"
        [image]="selectedOptionIndex === i ? option.activeImage : option.image"
        [label]="option.label"
        (click)="onSelectOption(i)"
      ></triangle-create-scenario-option-button>
    </ng-container>
  </div>
  <button (click)="onContinue()" mat-raised-button color="primary" [disabled]="selectedOptionIndex === null">
    Continue
  </button>
</div>

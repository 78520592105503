import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqSectionComponent } from './faq-section.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FaqSectionComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [FaqSectionComponent],
})
export class FaqSectionModule {}

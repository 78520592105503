import { ScenarioTypes } from 'triangle-study/models/package-proposal.models';
import { Exclusion, PersonalInputModel, SiteInputModel } from '../../+case-study/stores';
import { AccessCategory } from '../../sign-up/store';

export interface ScenarioModel {
  id: string;
  projectId: string;
  caseId: string;
  name: string;
  owner?: string;
  createdAt?: string;
  personalInputs?: PersonalInputModel[];
  siteInputs?: SiteInputModel[];
  productPackage?: AccessCategory;
  exclusions?: Exclusion[];
  scenarioType?: ScenarioTypes | undefined;
  parentScenario?: string;
}

export namespace ScenarioModel {
  export const mapIncoming = ({
    projectUuid,
    caseUuid,
    scenarioUuid,
    name,
    owner,
    createdAt,
    personalInput,
    sitesInput,
    productPackage,
    exclusions,
    scenarioType,
    parentScenario,
  }): ScenarioModel => ({
    id: scenarioUuid,
    projectId: projectUuid,
    caseId: caseUuid,
    name,
    owner,
    createdAt,
    personalInputs: (personalInput || []).map((personal) => PersonalInputModel.mapIncoming(personal)),
    siteInputs: (sitesInput || []).map((site) => SiteInputModel.mapIncoming(site)),
    productPackage,
    exclusions,
    scenarioType,
    parentScenario,
  });
}

export enum ScenarioTypeEnum {
  FLEET_STANDARD_SCENARIO = 'Standard Fleet',
  FLEET_ADVANCED_SCENARIO = 'Advanced Fleet',
  MOBILITY_SCENARIO = 'Mobility',
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { AccessCategory } from 'triangle-signup/store';
import {
  ReasonHierarchy,
  ReasonKeyMap,
  UploadPropositionCriteria,
  UploadReason,
  UploadReasonKey,
} from 'triangle-study/models';

@Component({
  selector: 'triangle-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalSummaryComponent {
  private readonly criteriaCache =
    new BehaviorSubject<UploadPropositionCriteria>(null);
  readonly criteria$ = this.selectTruthyCriteria();
  readonly reasons$ = this.selectReasons();

  @Input() set criteria(value: UploadPropositionCriteria) {
    this.criteriaCache.next(value);
  }

  @Input() package: AccessCategory = AccessCategory.Free;

  private selectReasons(): Observable<UploadReasonKey[]> {
    return this.selectTruthyCriteria().pipe(
      pluck('reason'),
      map((reason) => this.interpolateOtherReasons(reason)),
      map((reasons) => this.reasonsToKeys(reasons)),
      map((reasons) => this.coerceReasonToTest(reasons)),
    );
  }

  private coerceReasonToTest(reasons: UploadReasonKey[]): UploadReasonKey[] {
    return reasons.length ? reasons : [UploadReasonKey.TEST];
  }

  private interpolateOtherReasons(from: UploadReason): UploadReason[] {
    return ReasonHierarchy.slice(0, ReasonHierarchy.indexOf(from) + 1);
  }

  private reasonsToKeys(reasons: UploadReason[]): UploadReasonKey[] {
    return reasons.map((reason) => ReasonKeyMap[reason]);
  }

  private selectTruthyCriteria(): Observable<UploadPropositionCriteria> {
    return this.criteriaCache.pipe(filter((criteria) => !!criteria));
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HoursPipePipe } from './hours-pipe.pipe';

@NgModule({
  declarations: [HoursPipePipe],
  imports: [CommonModule],
  exports: [HoursPipePipe],
})
export class HoursPipeModule {}

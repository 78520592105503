function string(alleged: string | undefined | null): string {
  return alleged || '';
}

function voidFn(
  alleged: (_: unknown) => void | undefined | null,
): (_: unknown) => void {
  return alleged || (() => {});
}

function object<T>(alleged: T | undefined | null): T {
  return alleged || ({} as T);
}

export const Coerce = {
  string,
  voidFn,
  object,
};

export enum InequalityRelationship {
  EQUAL = 'equal',
  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',
  GREATER_THAN_EQUAL = 'greater_than_equal',
  LESS_THAN_EQUAL = 'less_than_equal',
}

export const InequalitySymbols: {} = {
  [InequalityRelationship.EQUAL]: '=',
  [InequalityRelationship.GREATER_THAN]: '>',
  [InequalityRelationship.GREATER_THAN_EQUAL]: '>=',
  [InequalityRelationship.LESS_THAN]: '<',
  [InequalityRelationship.LESS_THAN_EQUAL]: '<=',
};

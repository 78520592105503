import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { Coerce } from 'triangle-core';
import { AccessCategory } from 'triangle-signup/store';
import { PackageProposer } from 'triangle-study/services';
import { ContactAgreements } from 'triangle-support/models';
import { ContactDetails } from '../contact-details';
import {
  DemoDiscoveryService,
  DiscoverRequest
} from './demo-discovery.service';

@Component({
  selector: 'triangle-demo-discovery',
  templateUrl: './demo-discovery.component.html',
  styleUrls: ['./demo-discovery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoDiscoveryComponent {
  @Input() package: AccessCategory = AccessCategory.Free;

  readonly details = new FormControl();
  readonly agreements = new FormControl();

  readonly invalid$ = this.selectDetailsInvalid();
  readonly discovering$ = this.service.loading$;
  readonly criteria$ = this.proposer.selectCriteria();

  constructor(
    private readonly service: DemoDiscoveryService,
    private readonly proposer: PackageProposer,
  ) {}

  onGetReport(): void {
    this.service
      .discover(this.buildDiscoverRequest(), this.package)
      .pipe(take(1))
      .subscribe();
  }

  private buildDiscoverRequest(): DiscoverRequest {
    return { ...this.details.value, marketing: this.optedInMarketing() };
  }

  private optedInMarketing(): boolean {
    return Coerce.object(this.agreements.value).marketing;
  }

  private selectDetailsInvalid(): Observable<boolean> {
    return this.selectTriggers().pipe(
      map(
        ([details, agreements]) =>
          ![this.areValid(details), this.isAgreementsValid(agreements)].every(
            Boolean,
          ),
      ),
      startWith(true),
    );
  }

  private isAgreementsValid(agreements: ContactAgreements): boolean {
    return !!Coerce.object(agreements).privacyStatement;
  }

  private selectTriggers(): Observable<[ContactDetails, ContactAgreements]> {
    return combineLatest([
      this.details.valueChanges,
      this.agreements.valueChanges,
    ]);
  }

  private areValid(details: ContactDetails): boolean {
    const { email, name } = details;
    return [email, name].every(Boolean);
  }
}

<div class="layout-footer-section" fxLayout="column">
  <div class="layout-footer-upper" fxFlex="50%" fxLayout="row" fxLayoutAlign="space-around center">
    <span class="footer-know-more-text">{{ 'Footer.texts.knowMore' | translate }}</span>
    <button mat-flat-button color="accent" class="footer-touch-button" (click)="onGetInTouch()">
      {{ 'Footer.texts.getInTouch' | translate }}
      <mat-icon>east</mat-icon>
    </button>
  </div>
  <div class="layout-footer-lower" fxFlex fxLayout="column" fxLayoutAlign="center none" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <img src="/assets/homepage/footer_logo.png" alt="" class="footer-logo-offset" />
      <div fxLayout="column" fxLayoutAlign="center end" fxLayoutGap="32px">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center">
          <div *ngFor="let link of footerLinks" class="footer-link">
            <a (click)="onLinkClick(link?.urls)" class="cursor-pointer">
              {{ 'Footer.links.' + link?.label | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around" class="footer-lower-bar" fxLayoutGap="8px">
      <img src="/assets/homepage/footer_color_bar.png" alt="" />
      <div fxLayout="row" fxLayoutAlign="space-between none">
        <span>{{ appVersion }}</span>
        <span>
          {{ 'Footer.texts.shaping' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<div [formGroup]="agreements" fxLayout="column" fxLayoutGap="8px">
  <mat-checkbox
    color="accent"
    data-test="privacyStatement"
    formControlName="privacyStatement"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
      <span>
        {{ 'Support.messages.privacyStatement' | translate }}
      </span>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px"
        class="privacy-link"
        data-test="privacyLink"
        (click)="onPrivacyLinkClick()"
      >
        <mat-icon [inline]="true">open_in_new</mat-icon>
        <span>
          {{ 'Support.messages.privacyLink' | translate }}
        </span>
      </div>
    </div>
  </mat-checkbox>
  <mat-checkbox color="accent" formControlName="marketing">
    <p
      class="marketing-text"
      [innerHTML]="'Support.messages.marketing' | translate"
    ></p
  ></mat-checkbox>
</div>

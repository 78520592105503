import { Injectable } from '@angular/core';

import { ResultsFilter } from '../../+case-study/stores';

export namespace Scenario {
  const API_NAME = '[ScenarioAPI] ';

  @Injectable({ providedIn: 'root' })
  export class ActionSubject {
    constructor() {}
  }

  export class Create {
    static readonly type = API_NAME + 'Create';
    constructor(public payload: { projectId: string; caseId: string; data: any }) {}
  }

  export class CreateFleet {
    static readonly type = API_NAME + 'CreateFleet';
    constructor(public payload: { projectId: string; caseId: string; data: any }) {}
  }

  export class CreateSuccess {
    static readonly type = API_NAME + 'CreateSuccess';
    constructor(public payload: { data: any }) {}
  }

  export class CreateFailure {
    static readonly type = API_NAME + 'CreateFailure';
    constructor(public payload: { error: any }) {}
  }

  export class Get {
    static readonly type = API_NAME + 'Get';
    constructor(public payload: { projectId: string; caseId: string; scenarioId: string }) {}
  }

  export class GetSuccess {
    static readonly type = API_NAME + 'Get Success';
    constructor(public payload: { data: any }) {}
  }

  export class GetFailure {
    static readonly type = API_NAME + 'Get Failure';
    constructor(public payload: { error: any }) {}
  }

  export class GetList {
    static readonly type = API_NAME + 'GetList';
    constructor(public payload: { projectId: string; caseId: string }) {}
  }

  export class GetListSuccess {
    static readonly type = API_NAME + 'GetListSuccess';
    constructor(public payload: { scenarios: any; caseId: string }) {}
  }

  export class GetListFailure {
    static readonly type = API_NAME + 'GetListFailure';
    constructor(public payload: { error: any }) {}
  }

  export class GetResults {
    static readonly type = API_NAME + 'GetResults';
    constructor(public payload: { projectId: string; caseId: string; scenarioId: string }) {}
  }

  export class GetResultsSuccess {
    static readonly type = API_NAME + 'GetResultsSuccess';
    constructor(public payload: { results: any }) {}
  }

  export class GetResultsFailure {
    static readonly type = API_NAME + 'GetResultsFailure';
    constructor(public payload: { error: any }) {}
  }

  export class DownloadResults {
    static readonly type = API_NAME + 'DownloadResults';
    constructor(public payload: { projectId: string; caseId: string; scenarioId: string; filter?: ResultsFilter }) {}
  }

  export class DownloadResultsSuccess {
    static readonly type = API_NAME + 'DownloadResultsSuccess';
    constructor(public payload: { url: string; scenarioId: string; format: string }) {}
  }

  export class DownloadResultsFailure {
    static readonly type = API_NAME + 'DownloadResultsFailure';
    constructor(public payload: { error: any; scenarioId: string }) {}
  }
}

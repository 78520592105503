import { EnvConfig } from 'eyes-core';

const PROJECT_LIST = 'projects';
const ADMIN_ROOT = 'admin';
const SINGLE_PROJECT = `${PROJECT_LIST}/:projectId`;
const STUDY_LIST = `${SINGLE_PROJECT}/cases`;
const SINGLE_STUDY = `${STUDY_LIST}/:studyId`;
const ADMIN_PROJ_LIST = `${ADMIN_ROOT}/projects`;
const SINGLE_ADMIN_PROJECT = `${ADMIN_PROJ_LIST}/:projectId`;
const ADMIN_CASE_LIST = `${SINGLE_ADMIN_PROJECT}/cases`;
const SINGLE_ADMIN_CASE = `${ADMIN_CASE_LIST}/:studyId`;
const SCENARIO_LIST = `${SINGLE_STUDY}/scenarios`;
const SCENARIO_FLEET_LIST = `${SINGLE_STUDY}/fleetScenarios`;
const SINGLE_SCENARIO = `${SCENARIO_LIST}/:scenarioId`;
const RESULT_FLEET_SCENARIO = `${SINGLE_SCENARIO}/fleetScenarios`;
const ADMIN_STUDY_LIST = `${SINGLE_PROJECT}/cases/:caseId`;

export interface TriangleConfig extends EnvConfig {
  serviceDeskUrl: string;
}

export const newEndpoints: Record<string, string> = {
  getDocument: 'document',
  discoverDemo: 'contactUs/discoverDemo',
};

export const endpoints = {
  get: {
    signedUploadURL: 'generate_signed_upload_url/:filename',
  },
  study: {
    list: STUDY_LIST,
    single: SINGLE_STUDY,
    geocoding: `${SINGLE_STUDY}/geocodingStatus`,
    upload: `${STUDY_LIST}/generateSignedUploadURL`,
    calculate: `${SINGLE_STUDY}/simulate`,
    results: `${SINGLE_STUDY}/results`,
    downloadResults: `${SINGLE_STUDY}/resultsDownloadUrl`,
    template: 'template',
    params: `${SINGLE_STUDY}/parameters`,
    downloadInput: `${SINGLE_STUDY}/input`,
    exclusionScenarios: SCENARIO_LIST,
    fleetScenarios: SCENARIO_FLEET_LIST,
  },
  scenario: {
    list: SCENARIO_LIST,
    single: SINGLE_SCENARIO,
    results: `${SINGLE_SCENARIO}/results`,
    downloadResults: `${SINGLE_SCENARIO}/resultsDownloadUrl`,
    resultFleet: RESULT_FLEET_SCENARIO,
  },
  project: {
    list: PROJECT_LIST,
    single: SINGLE_PROJECT,
  },
  signUp: {
    request: 'access',
  },
  admin: {
    pendingCases: `${ADMIN_ROOT}/pendingCases`,
    superProjects: ADMIN_PROJ_LIST,
    changeStudyStatus: `${SINGLE_ADMIN_CASE}/validate`,
    cities: `${ADMIN_ROOT}/cities`,
    upload: `${ADMIN_STUDY_LIST}/generateSignedUploadURL`,
    patch: `${ADMIN_STUDY_LIST}`,
  },
  user: {
    getUserById: 'users/:id',
    getUserByEmail: 'users/filter_email/:email',
  },
  invoice: {
    proforma: 'proformaInvoice',
    prices: 'prices',
  },
  support: {
    support: 'createTicket',
    contactUs: 'contactUs',
  },
};

let _version: any;
try {
  _version = require('./environment-version') || undefined;
} catch (e) {
  console.warn(
    'No environment-version.ts found! Please run `npm run env:version`',
  );
  _version = undefined;
}

export const TRIANGLE_VERSION = _version;

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { InputFilterModule } from 'triangle-shared/components/input-filter';
import { ParametersManifestComponent } from './parameters-manifest.component';

@NgModule({
  declarations: [ParametersManifestComponent],
  imports: [CommonModule, MatTableModule, TranslateModule, MatPaginatorModule, InputFilterModule],
  exports: [ParametersManifestComponent],
})
export class ParametersManifestModule {}

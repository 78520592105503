import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FaqSectionModule } from './faq-section/faq-section.module';
import { FooterModule } from './footer';
import { HowItWorksModule } from './how-it-works/how-it-works.module';
import { LandingMediaModule } from './landing-media';
import { LandingReportsModule } from './landing-reports';
import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    TranslateModule,
    FooterModule,
    MatTooltipModule,
    RouterModule,
    HowItWorksModule,
    FaqSectionModule,
    LandingMediaModule,
    LandingReportsModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}

import { endpoints, TriangleConfig, TRIANGLE_VERSION } from './environment-commons';

export const environment: TriangleConfig = {
  name: 'd2',
  production: true,
  api: {
    // TODO: Update based on actual API Gateway URL!
    baseUrl: 'https://api.d2.triangle.te-ded.com/',
    endpoints,
  },
  cognito: {
    // TODO: Update based on AWS (for Triangle) config!
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_NXuN2nrx1',
      userPoolWebClientId: '3e3rj22d6a1l25h53gn8tpsesm',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'triangle-d2.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://d2.triangle.te-ded.com/home',
        redirectSignOut: 'https://d2.triangle.te-ded.com/home',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: true,
        },
        identityProvider: 'triangle-d2-idp',
      },
    },
  },
  sentryDsn: 'https://085b455cbf264775a7b34fdd3ca91f3e@o258615.ingest.sentry.io/5745740',
  googleApiKey: 'AIzaSyDcXblgOQzvPIStGtyc62E6y3Ljo2S7PGM',
  captchaSiteKey: '6LdpANAZAAAAADttfKCLyaY6Uic-Q0l0Y2L3_rVL',
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  serviceDeskUrl: 'https://tractebel-et.atlassian.net/jira/servicedesk/projects/TRSD/issues',
  version: TRIANGLE_VERSION,
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { DocumentRetrieverService, EndpointProvider } from 'triangle-core';
import { AccessCategory } from 'triangle-signup/store';
import { ContactDetails } from '../contact-details';

export interface DiscoverRequest extends ContactDetails {
  readonly marketing: boolean;
}

@Injectable({ providedIn: 'root' })
export class DemoDiscoveryService {
  private readonly discovered = new Subject<boolean>();
  private readonly loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loading.asObservable();
  readonly discovered$ = this.discovered.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProvider,
    private readonly documents: DocumentRetrieverService,
  ) {}

  discover(body: DiscoverRequest, pkg: AccessCategory): Observable<unknown> {
    this.loading.next(true);
    return this.http.post(this.endpoints.forDiscoverDemo(), body).pipe(
      mergeMap(() => this.documents.getDocument(pkg)),
      finalize(() => this.onDiscover()),
    );
  }

  private onDiscover(): void {
    this.loading.next(false);
    this.discovered.next(true);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { BaseComponent, CustomValidators } from 'eyes-shared';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map, mergeMapTo, startWith, tap } from 'rxjs/operators';

import { InequalitySymbols } from './inequality-input.model';

export interface InequalityInputConfig {
  label?: string;
  appearance?: MatFormFieldAppearance;
  unit?: string;
}

@Component({
  selector: 'triangle-inequality-input',
  templateUrl: './inequality-input.component.html',
  styleUrls: ['./inequality-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InequalityInputComponent,
      multi: true,
    },
  ],
})
export class InequalityInputComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Input() config: InequalityInputConfig;
  @Input() isPrefixed = true;
  @Input() isDisabled = true;
  readonly inequalitySymbols: { value; display }[] = [
    { value: undefined, display: 'N/A' },
    ...Object.keys(InequalitySymbols).map((sym) => ({
      value: sym,
      display: InequalitySymbols[sym],
    })),
  ];

  form = this.builder.group({
    symbol: [undefined],
    value: [0, CustomValidators.mustBePositiveNumber],
  });
  symbolControl = this.form.get('symbol');
  valueControl = this.form.get('value');

  /** Piped streams */
  isDisabled$ = new BehaviorSubject<boolean>(true);
  valueHintKey$ = this.symbolControl.valueChanges.pipe(
    startWith(undefined as string),
    map((sym) => (!!sym ? `Shared.hints.${sym}` : undefined)),
  );
  symbolCtrlValue$ = this.symbolControl.valueChanges.pipe(startWith(undefined as string));
  containerLabelStyle$ = this.isDisabled$.pipe(map((isIt) => (isIt ? 'container-label-disabled' : 'container-label')));
  onChange: (value: any) => void = () => {};

  constructor(private builder: FormBuilder) {
    super();
  }
  ngOnInit() {
    this.isDisabled$.next(this.isDisabled);
    this.form.valueChanges.pipe(this.takeUntil(), debounceTime(100)).subscribe((value) => {
      const actualSymbol = InequalitySymbols[value.symbol];
      const transformed = [actualSymbol, value.value];
      this.onChange(transformed.every((t) => !!t) ? transformed : undefined);
    });
    this.isDisabled$
      .pipe(
        tap((isIt) => (isIt ? this.form.disable() : this.form.enable())),
        mergeMapTo(this.symbolCtrlValue$),
        this.takeUntil(),
      )
      .subscribe((sym) => {
        !!sym ? this.valueControl.enable() : this.valueControl.disable();
      });
  }

  getErrorMessage() {
    if (this.valueControl.hasError('required')) {
      return 'Shared.errors.required';
    }
    if (this.valueControl.hasError('mustBePositiveNumber')) {
      return 'Shared.errors.mustBePositiveNumber';
    }
    return 'Shared.errors.invalid';
  }

  // ControlValueAccessor methods
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled$.next(isDisabled);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coerce, getCVAProvider } from 'triangle-core';
import { CVA } from 'triangle-core/classes';
import { ContactDetails } from './contact-details.models';

@UntilDestroy()
@Component({
  selector: 'triangle-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getCVAProvider(ContactDetailsComponent)],
})
export class ContactDetailsComponent extends CVA<ContactDetails> {
  readonly details = this.builder.group({
    name: '',
    email: '',
    company: '',
  } as ContactDetails);

  constructor(private readonly builder: FormBuilder) {
    super();
    this.subscribeToChangesForCVAPropagation();
  }
  writeValue(_: Partial<ContactDetails>): void {
    // do nothing
  }

  private subscribeToChangesForCVAPropagation(): void {
    this.details.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((details) => Coerce.voidFn(this.onChange)(details));
  }
}

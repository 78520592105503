import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class ParametersManifestService {
  private manifestSubject = new BehaviorSubject<unknown>({});
  manifest$ = this.manifestSubject.asObservable();

  constructor(private http: HttpClient, private translate: TranslateService) {
    this.subscribeToLangChangeForRefetch();
  }

  get(): void {
    this.http
      .get(`assets/i18n/parameters/manifest_${this.getCurrentLanguage()}.json`)
      .pipe(take(1))
      .subscribe({
        next: this.onSuccess.bind(this),
      });
  }

  private subscribeToLangChangeForRefetch(): void {
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(this.get.bind(this));
  }

  private getCurrentLanguage(): string {
    return this.translate.currentLang;
  }
  private onSuccess(response: unknown): void {
    this.manifestSubject.next(response);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ContactAgreementsModule } from 'triangle-support/components/contact-agreements';
import { ContactDetailsModule } from '../contact-details';
import { ProposalSummaryModule } from '../proposal-summary';
import { DemoDiscoveryComponent } from './demo-discovery.component';

@NgModule({
  declarations: [DemoDiscoveryComponent],
  imports: [
    CommonModule,
    ContactDetailsModule,
    ContactAgreementsModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslateModule,
    FlexLayoutModule,
    ProposalSummaryModule,
  ],
  exports: [DemoDiscoveryComponent],
})
export class DemoDiscoveryModule {}

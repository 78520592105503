import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'triangle-proforma-preview',
  templateUrl: './proforma-preview.component.html',
  styleUrls: ['./proforma-preview.component.scss'],
})
export class ProformaPreviewComponent implements OnInit {
  @Output() preview = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onPreview() {
    this.preview.emit();
  }
}

<div fxLayout="column" [formGroup]="details">
  <div fxLayout="row" fxLayoutGap="8px">
    <triangle-input
      fxFlex
      data-test="name"
      formControlName="name"
      [required]="true"
      [label]="'Support.labels.fullName' | translate"
      [icon]="'Support.icons.fullName' | translate"
    ></triangle-input>
    <triangle-input
      fxFlex
      data-test="email"
      formControlName="email"
      [required]="true"
      [label]="'Support.labels.email' | translate"
      [icon]="'Support.icons.email' | translate"
    ></triangle-input>
  </div>
  <triangle-input
    data-test="company"
    formControlName="company"
    [label]="'Support.labels.company' | translate"
    [icon]="'Support.icons.company' | translate"
  ></triangle-input>
</div>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'triangle-create-scenario-option-button',
  templateUrl: './create-scenario-option-button.component.html',
  styleUrls: ['./create-scenario-option-button.component.scss'],
})
export class CreateScenarioOptionButtonComponent implements OnInit {
  @Input() image: string;
  @Input() label: string;
  @Input() hasBorder: boolean;
  @Output() click = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.click.emit();
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, bulletAnimation, doNothing, slideDownAnimation } from 'eyes-shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, skip, startWith } from 'rxjs/operators';
import { Exclusion } from '../../../+case-study/stores';
import { NameValuePair, NOT_APPLICABLE, serializeValues } from '../../../app.references';

@Component({
  selector: 'triangle-export-exclusion',
  templateUrl: './export-exclusion.component.html',
  styleUrls: ['./export-exclusion.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExportExclusionComponent,
      multi: true,
    },
  ],
  animations: [slideDownAnimation, bulletAnimation],
})
export class ExportExclusionComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  readonly COLUMN_DEF_MAPPING = {
    site: 'site',
    department: 'department',
    mode: 'transportMode',
  };
  readonly COLUMN_DEFS = [...Object.keys(this.COLUMN_DEF_MAPPING), 'clear'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() sites: NameValuePair[];
  @Input() departments: NameValuePair[];
  @Input() modes: NameValuePair[];
  @Input() allowSingleSelection = false;

  onChange;
  singleCriteriaForm = this.formBuilder.group({
    site: undefined,
    department: undefined,
    mode: undefined,
  } as Exclusion);
  currExclusion$ = new BehaviorSubject<Exclusion[]>([]);
  dataSource$ = this.currExclusion$.pipe(
    map((data) => {
      const source = new MatTableDataSource(data);
      source.paginator = this.paginator;
      return source;
    }),
  );
  isCombinationUnique$ = combineLatest([
    this.currExclusion$.pipe(map((curr) => curr.map((c) => c.id))),
    this.singleCriteriaForm.valueChanges.pipe(
      debounceTime(100),
      map((formVal) => serializeValues(formVal)),
    ),
  ]).pipe(
    map(([current, candidate]) => !current.includes(candidate)),
    startWith(true),
  );
  isComboInputValid$ = this.singleCriteriaForm.valueChanges.pipe(
    // if at least 2 of the controls have values
    map((formVal) => {
      const dataLength = Object.values(formVal).filter((v) => !!v).length;
      return this.allowSingleSelection ? dataLength >= 1 : dataLength >= 2;
    }),
    startWith(false),
  );

  /**  button reactions */
  addBtnDisable$ = combineLatest([this.isCombinationUnique$, this.isComboInputValid$]).pipe(
    map((bools) => bools.some((b) => !b)),
  );
  clearBtnDisable$ = this.currExclusion$.pipe(map((curr) => !!!curr.length));

  constructor(private formBuilder: FormBuilder, private translator: TranslateService) {
    super();
  }

  ngOnInit() {
    this.currExclusion$.pipe(skip(1), this.takeUntilShare()).subscribe((val) => this.onChange([...val]));
  }

  onAddExclusion = () => {
    const formVal = this.singleCriteriaForm.value;
    // prepend the new combination to the running list and reset the form
    this.currExclusion$.next([{ ...formVal, id: serializeValues(formVal) }, ...this.currExclusion$.value]);
    this.singleCriteriaForm.reset();
  };

  mapSite = (id: string) => (this.sites.find((s) => s.value === id) || {})['name'] || NOT_APPLICABLE;
  mapDepartment = (id: string) => (this.departments.find((d) => d.value === id) || {})['name'] || NOT_APPLICABLE;
  mapMode = (id: string) => (this.modes.find((m) => m.value === id) || {})['name'] || NOT_APPLICABLE;

  onClearFilters = () => this.currExclusion$.next([]);

  onDelete = ({ id }: Exclusion) => this.currExclusion$.next(this.currExclusion$.value.filter((e) => e.id !== id));

  /** Control Value Accessor methods */
  writeValue = (criteria: Exclusion[]) => doNothing();
  registerOnChange = (fn: any) => (this.onChange = fn);
  registerOnTouched = (fn: any) => doNothing();

  getTransportModeTransKey = (mode: string) => `General.transportModes.${mode}`;
  getHeaderLabelTransKey = (label: string) => `CaseStudy.labels.${label}`;
}

/* tslint:disable: max-file-line-count*/

export enum AccessCategory {
  Free = 'free',
  Essential = 'essential',
  EssentialPlus = 'essential_plus',
  Standard = 'standard',
  Premium = 'premium',
  Enterprise = 'enterprise',
  FreeFleet = 'free_fleet',
  EssentialFleet = 'essential_fleet',
  EssentialPlusFleet = 'essential_plus_fleet',
  StandardFleet = 'standard_fleet',
  PremiumFleet = 'premium_fleet',
}

export const ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO = [
  AccessCategory.EssentialFleet,
  AccessCategory.EssentialPlusFleet,
  AccessCategory.StandardFleet,
  AccessCategory.PremiumFleet,
];

export const ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO = [
  AccessCategory.EssentialFleet,
  AccessCategory.EssentialPlusFleet,
  AccessCategory.StandardFleet,
  AccessCategory.PremiumFleet,
  AccessCategory.FreeFleet,
];

export const ELIGIBLE_PACKAGES_SCENARIO = [
  AccessCategory.Standard,
  AccessCategory.Premium,
  AccessCategory.Enterprise,
  AccessCategory.StandardFleet,
  AccessCategory.PremiumFleet,
];

export interface CompleteAddress {
  companyAddress: string;
  city: string;
  country: string;
  zipCode: string;
}

export const REQUIRED_PARAMS = [
  'name',
  'surname',
  'position',
  'company',
  'companyAddress',
  'companyCity',
  'companyZipcode',
  'companyCountry',
  'vatNumber',
  'phoneNumber',
  'email',
  'backupEmail',
  'accessCategory',
  'managedEmployees',
  'managedOffices',
  'language',
  'reasons',
];
export interface UserForm {
  name: string;
  surname?: string;
  position?: string;
  company: string;
  companyAddress: string;
  companyCity: string;
  companyZipcode: string;
  companyCountry: string;
  vatNumber: string;
  phoneNumber?: string;
  email: string;
  backupEmail?: string;
  demo?: boolean;

  accessCategory: AccessCategory;
  managedEmployees?: number;
  managedOffices?: number;
  language?: string;
  reasons: string[];
  captcha: string;
}

export const buildAddress = ({ companyAddress: address, city, country, zipCode: zip }: CompleteAddress) =>
  `${address}, ${zip} ${city}, ${country}`;

export const categoryConstraints: {
  [cat: string]: {
    employee?;
    office?;
    defaultEmployee?;
    defaultOffice?;
    addOns?;
    support?;
    usages?;
    exclusions?;
    displayName?;
    constraints?;
    color?;
    fleet?;
  };
} = {
  free: {
    color: 'cornflowerblue',
    defaultOffice: 1,
    defaultEmployee: 50,
    employee: 50,
    office: 1,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.max2Times', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Free',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: 'AccessCategories.constraints.maxFifty' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: 1 },
      { tnKey: 'AccessCategories.constraints.useLimitation', value: 'AccessCategories.constraints.freeUseLimitation' },
    ],
  },
  free_fleet: {
    color: 'cornflowerblue',
    defaultOffice: 1,
    defaultEmployee: 50,
    employee: 50,
    office: 1,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.max2Times', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Free & Fleet',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: 'AccessCategories.constraints.maxFifty' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: 1 },
      { tnKey: 'AccessCategories.constraints.useLimitation', value: 'AccessCategories.constraints.freeUseLimitation' },
      {
        tnKey: 'AccessCategories.constraints.fleetAnalysis',
        value: 'AccessCategories.constraints.fleetAnalysisIncluded',
      },
    ],
  },
  essential: {
    color: 'dodgerblue',
    employee: 5000,
    office: 1,
    defaultOffice: 1,
    defaultEmployee: 150,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.1hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Essential',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: 1 },
    ],
  },
  essential_fleet: {
    color: 'dodgerblue',
    defaultOffice: 1,
    defaultEmployee: 150,
    employee: 5000,
    office: 1,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.1hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Essential & Fleet',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: 1 },
      {
        tnKey: 'AccessCategories.constraints.fleetAnalysis',
        value: 'AccessCategories.constraints.fleetAnalysisIncluded',
      },
    ],
  },
  essential_plus: {
    color: 'blue',
    defaultOffice: 2,
    defaultEmployee: 150,
    employee: 5000,
    office: 10,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.1hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Essential Plus',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '2 - 10' },
    ],
  },
  essential_plus_fleet: {
    color: 'blue',
    defaultOffice: 2,
    defaultEmployee: 150,
    employee: 5000,
    office: 10,
    addOns: ['AccessCategories.addOns.standardReport', 'AccessCategories.addOns.finalExcel'],
    support: ['AccessCategories.support.1hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    exclusions: ['AccessCategories.exclusions.resultsFiltering'],
    displayName: 'Essential Plus & Fleet',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.tenPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '2 - 10' },
      {
        tnKey: 'AccessCategories.constraints.fleetAnalysis',
        value: 'AccessCategories.constraints.fleetAnalysisIncluded',
      },
    ],
  },
  standard: {
    color: 'limegreen',
    defaultOffice: 1,
    defaultEmployee: 150,
    employee: 5000,
    office: 1,
    addOns: [
      'AccessCategories.addOns.standardReport',
      'AccessCategories.addOns.finalExcel',
      'AccessCategories.addOns.personalizedResults',
      'AccessCategories.addOns.mobilitySiteScenarios',
    ],
    support: ['AccessCategories.support.2hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    displayName: 'Standard',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmcs1m1s1m2',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.twentyPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '1' },
    ],
  },
  standard_fleet: {
    color: 'limegreen',
    defaultOffice: 1,
    defaultEmployee: 150,
    employee: 5000,
    office: 1,
    addOns: [
      'AccessCategories.addOns.standardReport',
      'AccessCategories.addOns.finalExcel',
      'AccessCategories.addOns.personalizedResults',
      'AccessCategories.addOns.mobilitySiteScenarios',
    ],
    support: ['AccessCategories.support.2hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.singleUse'],
    displayName: 'Standard & Fleet',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.scmcs1m1s1m2',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.twentyPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '1' },
      {
        tnKey: 'AccessCategories.constraints.fleetAnalysis',
        value: 'AccessCategories.constraints.fleetAnalysisIncluded',
      },
    ],
  },
  premium: {
    color: 'green',
    defaultOffice: 2,
    defaultEmployee: 150,
    employee: 5000,
    office: 10,
    addOns: [
      'AccessCategories.addOns.standardReport',
      'AccessCategories.addOns.finalExcel',
      'AccessCategories.addOns.personalizedResults',
      'AccessCategories.addOns.mobilitySiteScenarios',
    ],
    support: ['AccessCategories.support.12hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.12Uses'],
    displayName: 'Premium',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.sxmx',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.twentyFourPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '2 - 10' },
    ],
  },
  premium_fleet: {
    color: 'green',
    defaultOffice: 2,
    defaultEmployee: 150,
    employee: 5000,
    office: 10,
    addOns: [
      'AccessCategories.addOns.standardReport',
      'AccessCategories.addOns.finalExcel',
      'AccessCategories.addOns.personalizedResults',
      'AccessCategories.addOns.mobilitySiteScenarios',
    ],
    support: ['AccessCategories.support.12hSupport', 'AccessCategories.support.online'],
    usages: ['AccessCategories.usages.12Uses'],
    displayName: 'Premium & Fleet',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.sxmx',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.twentyFourPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '1 - 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '2 - 10' },
      {
        tnKey: 'AccessCategories.constraints.fleetAnalysis',
        value: 'AccessCategories.constraints.fleetAnalysisIncluded',
      },
    ],
  },
  enterprise: {
    color: 'orangered',
    employee: null,
    office: null,
    addOns: ['AccessCategories.addOns.tailorMade', 'AccessCategories.addOns.severalPresentation'],
    support: ['AccessCategories.support.recurrent'],
    displayName: 'Enterprise',
    constraints: [
      {
        tnKey: 'AccessCategories.constraints.companyMobilityPlan',
        value: 'AccessCategories.constraints.scmc',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.existingSituationAnalysis',
        value: 'AccessCategories.constraints.sxmx',
        prefixCheck: true,
      },
      {
        tnKey: 'AccessCategories.constraints.standardReport',
        value: 'AccessCategories.constraints.twentyFourPages',
        prefixCheck: true,
      },
      { tnKey: 'AccessCategories.constraints.numberEmployees', value: '> 5000' },
      { tnKey: 'AccessCategories.constraints.sitesLimitation', value: '> 10' },
    ],
  },
};

export const DEFAULT_ACCESS_CATEGORY = {
  accessCategory: AccessCategory.Free,
  managedEmployees: categoryConstraints[AccessCategory.Free]['employee'],
  managedOffices: categoryConstraints[AccessCategory.Free]['office'],
};

export interface ProformaPreview {
  readonly package: AccessCategory;
  readonly sites: number;
  readonly employees: number;
  readonly lang?: string;
}

export const reasonsOption = [
  'UsageReasons.reasons.mobilityPlan',
  'UsageReasons.reasons.travelCost',
  'UsageReasons.reasons.objectives',
  'UsageReasons.reasons.travelTime',
  'UsageReasons.reasons.businessMove',
  'UsageReasons.reasons.otherReason',
];

export const updateReasons = (usageReasons: string[], otherReason: any) => {
  return otherReason ? [...usageReasons, otherReason] : usageReasons;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { DocType, GetDocRsp, Language } from './document-retriever.models';
import { EndpointProvider } from './endpoint-provider.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentRetrieverService {
  private readonly loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this.loading.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProvider,
    private readonly translate: TranslateService,
  ) {}

  getDocument(type: DocType): Observable<GetDocRsp> {
    this.loading.next(true);
    return this.http
      .get<GetDocRsp>(this.endpoints.forGetDocument(), {
        params: this.buildGetDocParams(type),
      })
      .pipe(
        tap((rsp) => this.implicitlyDownload(rsp.signedUrl)),
        finalize(() => this.loading.next(false)),
      );
  }

  private implicitlyDownload(url: string): void {
    window.open(url, '_blank');
  }

  private buildGetDocParams(type: DocType): {} {
    return {
      type,
      lang: this.getCurrentLang(),
    };
  }

  private getCurrentLang(): Language {
    return this.translate.currentLang as Language;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from 'eyes-shared';
import { RouteHistoryService } from 'ngx-route-history';
import { RESOURCE_CONTAINERS } from './app.references';
import { AdminAccountGuard } from './core/guards';
import { LoginRedirectionGuard } from './core/guards/login-redirection.guard';
import { SignUpPageComponent } from './sign-up/containers';

export interface TriangleFeature {
  path?: string;
  icon?: string;
  adminOnly?: boolean;
  showInDrawer?: boolean;
  disabled?: boolean;
}

export const FEATURES: { [key: string]: TriangleFeature } = {
  signup: {
    path: 'register',
  },
  login: {
    path: 'login',
  },
  home: {
    path: 'home',
    icon: 'home',
    showInDrawer: true,
  },
  projects: {
    path: RESOURCE_CONTAINERS.projects,
    icon: 'list',
    showInDrawer: true,
  },
  study: {
    icon: 'edit',
    showInDrawer: true,
    disabled: true,
  },
  profile: {
    icon: 'perm_identity',
    showInDrawer: true,
    disabled: true,
    // path: 'TODO: Once profile is ready'
  },
  admin: {
    path: RESOURCE_CONTAINERS.admin,
    icon: 'dashboard',
    adminOnly: true,
    showInDrawer: true,
  },
  cities: {
    path: RESOURCE_CONTAINERS.cities,
    icon: 'dashboard',
    adminOnly: true,
    showInDrawer: true,
  },
  sandbox: {
    path: RESOURCE_CONTAINERS.sandbox,
    icon: 'bathtub',
  },
  error: {
    path: 'error',
  },
  support: {
    path: RESOURCE_CONTAINERS.support,
    showInDrawer: true,
    icon: 'support',
  },
};

const routes: Routes = [
  // {
  //   path: FEATURES.signup.path,
  //   component: SignUpPageComponent,
  // },
  {
    path: FEATURES.home.path,
    loadChildren: () => import('./+landing/landing.module').then((m) => m.LandingModule),
    data: FEATURES.landing,
  },
  {
    path: FEATURES.projects.path,
    loadChildren: () => import('./+project/project.module').then((m) => m.ProjectModule),
    data: FEATURES.project,
    canActivate: [LoginRedirectionGuard],
  },
  {
    path: FEATURES.admin.path,
    loadChildren: () => import('./+admin/admin.module').then((m) => m.AdminModule),
    canActivate: [LoginRedirectionGuard, AdminAccountGuard],
  },
  {
    path: FEATURES.sandbox.path,
    loadChildren: () => import('./sandbox/sandbox.module').then((m) => m.SandboxModule),
  },
  // Add new routes from here
  {
    path: FEATURES.support.path,
    loadChildren: () => import('./+support/support.module').then((module) => module.SupportModule),
  },
  // to here
  {
    path: '',
    redirectTo: FEATURES.home.path,
    pathMatch: 'full',
  },
  {
    path: FEATURES.error.path,
    component: ErrorPageComponent,
    data: FEATURES.error,
  },
  {
    path: '**',
    redirectTo: FEATURES.error.path,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [RouteHistoryService],
})
export class AppRoutingModule {
  constructor(private _: RouteHistoryService) {}
}

/* tslint:disable: max-file-line-count*/

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccessCategory, ProformaPreview } from '../../sign-up/store';
import { CaseStudyModel, ResultsFilter, ValidationFeedback } from './case-study.models';

export namespace CaseStudy {
  const API_NAME = '[CaseStudyAPI] ';

  @Injectable({
    providedIn: 'root',
  })
  export class ActionSubject {
    isCreateSuccess$ = new Subject();
    fetchSuccess$ = new Subject();
    getResultsSuccess$ = new Subject();
    getTemplateUrlSuccess$ = new Subject();
    constructor() {}
  }

  export class Upload {
    static readonly type = API_NAME + 'Upload';
    constructor(
      public payload: {
        file: File;
        projectId: string;
        name: string;
        productPackage: AccessCategory;
      },
    ) {}
  }

  export class UploadSuccess {
    static readonly type = API_NAME + 'UploadSuccess';
    constructor(public payload: { data: CaseStudyModel; filename: string }) {}
  }

  export class UploadFailure {
    static readonly type = API_NAME + 'UploadFailure';
    constructor(public payload: { error }) {}
  }

  export class ConfirmCreate {
    static readonly type = API_NAME + 'Create';
    constructor() {}
  }

  export class Validate {
    static readonly type = API_NAME + 'Validate';
    constructor(public payload: { data: CaseStudyModel }) {}
  }

  export class CreateOK {
    static readonly type = API_NAME + 'Create - OK';
    constructor(public payload: { data: any }) {}
  }

  export class ValidateOK {
    static readonly type = API_NAME + 'Validate - OK';
    constructor(public payload: { warnings: ValidationFeedback[] }) {}
  }

  export class ValidateNOK {
    static readonly type = API_NAME + 'Validate - NOK';
    constructor(public payload: { sites: any[]; personal: any[]; global: any[] }) {}
  }

  export class ValidateCreateError {
    static readonly type = API_NAME + 'ValidateAndCreateError';
    constructor(public payload: { error }) {}
  }

  export class Get {
    static readonly type = API_NAME + 'Get';
    constructor(public payload: { projectId: string; id: string }) {}
  }

  export class GetSuccess {
    static readonly type = API_NAME + 'GetSuccess';
    constructor(public payload: { data: CaseStudyModel }) {}
  }

  export class GetFailure {
    static readonly type = API_NAME + 'GetFailure';
    constructor(public payload: { id: string; error }) {}
  }

  export class GetList {
    static readonly type = API_NAME + 'Get List';
    constructor(public payload: { projectId: string }) {}
  }

  export class GetListSuccess {
    static readonly type = API_NAME + 'Get List Success';
    constructor(public payload: { projectId: string; cases: any[] }) {}
  }

  export class GetListFailure {
    static readonly type = API_NAME + 'Get List Failure';
    constructor(public payload: { error }) {}
  }
}

export namespace Calculation {
  const API_NAME = '[Calculation API] ';

  export class Start {
    static readonly type = API_NAME + 'Start';
    constructor(public payload: { projectId: string; caseId: string; calculationDate?: string }) {}
  }

  export class StartSuccess {
    static readonly type = API_NAME + 'Start Success';
    constructor(public payload: { projectId: string; caseId: string }) {}
  }

  export class StartFailure {
    static readonly type = API_NAME + 'Start Failure';
    constructor(public payload: { error }) {}
  }

  export class StartDemo {
    static readonly type = API_NAME + 'Start Demo';
    constructor(public payload: { projectId: string; caseId: string }) {}
  }

  export class StartDemoSuccess {
    static readonly type = API_NAME + 'Start Demo Success';
    constructor(public payload: { projectId: string; caseId: string }) {}
  }

  export class DownloadResults {
    static readonly type = API_NAME + 'Get Results';
    constructor(public payload: { projectId; caseId; filter?: ResultsFilter }) {}
  }

  export class DownloadResultsSuccess {
    static readonly type = API_NAME + 'Get Results Success';
    constructor(public payload: { url: string; caseId: string; format: string }) {}
  }

  export class DownloadResultsFailure {
    static readonly type = API_NAME + 'Get Results Failure';
    constructor(public payload: { error; caseId: string }) {}
  }

  export class Directionalize {
    static readonly type = API_NAME + 'Directionalize';
    constructor(public payload: { projectId; caseId }) {}
  }

  export class DirectionSuccess {
    static readonly type = API_NAME + 'Directionalize Success';
    constructor(public payload: { results }) {}
  }

  export class DirectionFailure {
    static readonly type = API_NAME + 'Directionalize Failure';
    constructor(public payload: { error }) {}
  }
}

export namespace Geocoding {
  const API_NAME = '[Geocoding API] ';

  export class Check {
    static readonly type = API_NAME + 'Check Status';
    constructor(public payload: { projectId: string; id: string }) {}
  }

  export class InProgress {
    static readonly type = API_NAME + 'InProgress';
    constructor(public payload: { projectId: string; id: string; count: number }) {}
  }

  export class Error {
    static readonly type = API_NAME + 'Error';
    constructor(public payload: { error }) {}
  }

  export class Finished {
    static readonly type = API_NAME + 'Finished';
    constructor(public payload: { projectId: string; id: string }) {}
  }
}

export namespace Template {
  const API_NAME = '[Template] ';

  export class GetSignedURL {
    static readonly type = API_NAME + 'Get Signed Url';
    constructor() {}
  }

  export class GetSignedURLSuccess {
    static readonly type = API_NAME + 'Get Signed URL Success';
    constructor(public payload: { templateUrl }) {}
  }

  export class GetSignedURLFailure {
    static readonly type = API_NAME + 'Get Signed URL Failure';
    constructor(public payload: { error }) {}
  }
}

export namespace Parameters {
  const API_NAME = '[Parameters] ';

  export class Get {
    static readonly type = API_NAME + 'Get';
    constructor(public payload: { projectId: string; id: string }) {}
  }

  export class GetSuccess {
    static readonly type = API_NAME + 'Get Success';
    constructor(public payload: { id: string; params: any }) {}
  }

  export class GetFailure {
    static readonly type = API_NAME + 'Get Failure';
    constructor(public payload: { error }) {}
  }

  export class Patch {
    static readonly type = API_NAME + 'Update';
    constructor(public payload: { projectId: string; id: string; params: any; rerun: boolean }) {}
  }

  export class PatchSuccess {
    static readonly type = API_NAME + 'Update Success';
    constructor(public payload: { projectId: string; id: string; params: any; rerun: boolean }) {}
  }

  export class PatchFailure {
    static readonly type = API_NAME + 'Update Failure';
    constructor(public payload: { error }) {}
  }

  export class PatchPresenceRatio {
    static readonly type = API_NAME + 'Patch Presence Ratio';
    constructor(
      public payload: {
        sites: Array<{ id: string; name: string; presenceRatio: number }>;
        projectid: string;
        caseid: string;
      },
    ) {}
  }

  export class PatchPresenceRatioSuccess {
    static readonly type = API_NAME + 'Patch Presence Ratio Success';
    constructor() {}
  }

  export class PatchPresenceRatioFailure {
    static readonly type = API_NAME + 'Patch Presence Ratio Failure';
    constructor(public payload: { error }) {}
  }
}

export namespace ExcelInput {
  const API_NAME = '[Excel Input] ';

  export class GetSignedURL {
    static readonly type = API_NAME + 'Get Signed URL';
    constructor(public payload: { study: CaseStudyModel }) {}
  }

  export class GetSignedURLSuccess {
    static readonly type = API_NAME + 'Get Signed URL Success';
    constructor(public payload: { url: string; filename: string }) {}
  }

  export class GetSignedURLFailure {
    static readonly type = API_NAME + 'Get Signed URL Failure';
    constructor(public payload: { error }) {}
  }
}

export namespace Invoice {
  const API_NAME = '[Invoice] ';

  export class GetPrices {
    static readonly type = API_NAME + 'Get Prices';
    constructor(public payload: { proformaPreview?: ProformaPreview }) {}
  }

  export class GetPricesSuccess {
    static readonly type = API_NAME + 'Get Prices Success';
    constructor(public payload: { body: any; priceId?: string }) {}
  }

  export class GetPricesFailure {
    static readonly type = API_NAME + 'Get Prices Failure';
    constructor(public payload: { error }) {}
  }
}

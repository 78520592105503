import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap, skipUntil } from 'rxjs/operators';

import { CaseStudyStore } from '../../../+case-study/stores';
import { AccessCategory, ProformaPreview } from '../../../sign-up/store';

import { AuthStore } from 'eyes-core';

@Component({
  selector: 'triangle-package-projections',
  templateUrl: './package-projections.component.html',
  styleUrls: ['./package-projections.component.scss'],
  providers: [CurrencyPipe],
})
export class PackageProjectionsComponent implements OnInit {
  _package$ = new BehaviorSubject<AccessCategory>(undefined);
  @Input() set package(pkg: AccessCategory) {
    this._package$.next(pkg);
  }
  _sites$ = new BehaviorSubject<number>(0);
  @Input() set sites(sites: number) {
    this._sites$.next(sites);
  }
  _employees$ = new BehaviorSubject<number>(0);
  @Input() set employees(emp: number) {
    this._employees$.next(emp);
  }
  @Output() preview = new EventEmitter<ProformaPreview>();

  isPriceValid$ = new BehaviorSubject<boolean>(false);
  debouncedValid$ = this.isPriceValid$.pipe(debounceTime(100));
  fetchingPrice$ = this.studyStore.fetchingPrice$;
  price$ = combineLatest([
    this._package$.pipe(startWith(AccessCategory.Free)),
    this._sites$.pipe(startWith(1)),
    this._employees$.pipe(startWith(50)),
  ]).pipe(
    switchMap(([pkg, sites, emps]) => this.studyStore.getSpecificPrice$(pkg, sites, emps).pipe(startWith(0))),
    tap((price) => this.isPriceValid$.next(!!price)),
    map((price) => (!!price ? this._currencyPipe.transform(price, 'EUR') : 'N/A')),
  );

  constructor(private _currencyPipe: CurrencyPipe, private studyStore: CaseStudyStore, private authStore: AuthStore) {}

  ngOnInit() {
    this.fetchPrices();
  }

  fetchPrices = () => {
    combineLatest([
      this._package$.pipe(startWith(AccessCategory.Free)),
      this._sites$.pipe(startWith(1)),
      this._employees$.pipe(startWith(50)),
      this.authStore.authenticated$
    ])
      .pipe(
        debounceTime(2),
        filter((stream) => stream.every(bool => !!bool)),
        map(([pkg, s, e]) =>
          this.studyStore.getPrices({
            package: pkg,
            employees: e,
            sites: s,
          }),
        ),
      )
      .subscribe();
  };

  onPreview = () =>
    this.preview.emit({ package: this._package$.value, employees: this._employees$.value, sites: this._sites$.value });
}

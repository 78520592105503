import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface VidPlayerData {
  readonly url: string;
}

@Component({
  selector: 'triangle-vid-player',
  templateUrl: './vid-player.component.html',
  styleUrls: ['./vid-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VidPlayerComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: VidPlayerData) {}
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'eyes-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalCacheService, TriangleLoaderService } from '../../../core/services';
import { ProformaPreview } from '../../../sign-up/store';
import { UploadService } from '../../../upload-study/services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'triangle-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
  providers: [TriangleLoaderService, UploadService],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewPageComponent extends BaseComponent implements OnInit {
  readonly LOADER_NAME = 'preview';
  @Input() proformaDetails: ProformaPreview;
  @Output() back = new EventEmitter();
  // tslint:disable-next-line: no-output-native
  @Output() close = new EventEmitter();
  previewTemplate$ = this.service.selectors.proforma$;
  previewing$ = this.service.selectors.previewing$;
  templateInBase64$: Observable<string>;

  constructor(
    private loader: TriangleLoaderService,
    private service: UploadService,
    private localCache: LocalCacheService,
  ) {
    super();
  }

  ngOnInit() {
    const language = this.localCache.preferredLang$.value;
    const withLang: ProformaPreview = { ...this.proformaDetails, lang: language };
    this.service.previewProforma(withLang);
    this.initSubscriptions();

    this.templateInBase64$ = this.getBase64Stream();
  }

  initSubscriptions = () => {
    this.previewing$
      .pipe(this.takeUntil())
      .subscribe((isIt) => (isIt ? this.loader.show(this.LOADER_NAME) : this.loader.hide(this.LOADER_NAME)));
  };

  onBack = () => this.back.emit();
  onClose = () => this.close.emit();

  private getBase64Stream(): Observable<string> {
    return this.previewTemplate$.pipe(
      filter((template) => !!template),
      map(this.buildSrcMetadata.bind(this)),
    );
  }

  private buildSrcMetadata(base64String: string): string {
    return `data:application/pdf;base64,${base64String}`;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { SuccessCardComponent } from './success-card.component';

@NgModule({
  declarations: [SuccessCardComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule],
  exports: [SuccessCardComponent],
})
export class SuccessCardModule {}

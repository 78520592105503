import { HttpClientTestingModule } from '@angular/common/http/testing';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { RouterTestingModule } from '@angular/router/testing';

import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TriangleCoreModule } from './core/core.module';

export const APP_NAME = 'Triangle';
export const APP_VERSION = '1.0.0-beta';

export const MARKER_SIZE = { height: 40, width: 40 };
export const MARKER_ICON_MAP = {
  personal: {
    url: './assets/icons/personal_alt.png',
    scaledSize: MARKER_SIZE,
  },
  site: {
    url: './assets/icons/site.png',
    scaledSize: MARKER_SIZE,
  },
};

export const DEFAULT_PROJECT_NAME = 'default';
export const DEFAULT_PROJECT_ID = '00000000-0000-0000-0000-000000000000';

export const GENERIC_ERROR = 'An unexpected error occurred';

export enum StudyCreationStatus {
  CreatingProject = 'creatingProject',
  GeneratingSignedUrl = 'generatingUrl',
  Uploading = 'uploading',
  Validating = 'validating',
  Validated = 'validated',
  Creating = 'creating',
  error = 'error',
}

export const MOBILE_WIDTH_PX = 500;

export const RESOURCE_CONTAINERS = {
  projects: 'projects',
  studies: 'studies',
  admin: 'admin',
  home: 'home',
  sandbox: 'sandbox',
  support: 'support',
  cities: 'admin/cities',
};

export const TRIANGLE_TEMPLATE_FILENAME = 'tractozero_template.xls';
export const TRIANGLE_FLEET_TEMPLATE_FILENAME = 'tractozero_fleet_template.xlsx';
export const TRIANGLE_RESULTS_FILENAME = 'results_tractozero.xlsx';
export const TRIANGLE_REPORT_FILENAME_PREFIX = 'report_tractozero';
export const TRIANGLE_REPORT_OUTPUT_FILENAME = 'report_tractozero.pdf';

export const TR_FAQ_SAMPLE_REPORTS = {
  REPLACE_KEY: '<a>link</a>',
  FREE: 'Example_FREE',
  STANDARD: 'Example_Standard',
  PREMIUM: 'Example_Premium',
};

export enum TransportationMode {
  PUBLIC = 'PT',
  CAR = 'Car',
  BIKE = 'Bike',
  WALK = 'Walk',
}

export const transportModePairs: NameValuePair[] = [
  { name: 'PT', value: TransportationMode.PUBLIC },
  { name: 'Car', value: TransportationMode.CAR },
  { name: 'Bike', value: TransportationMode.BIKE },
  { name: 'Walk', value: TransportationMode.WALK },
];

export const EMPLOYEE_DETAILS_FORMAT = 'employee_details';
export const EXPORT_RESULT_FORMATS = ['pdf', 'xlsx', EMPLOYEE_DETAILS_FORMAT];

export const NOT_APPLICABLE = 'N/A';

export const NOT_APPLICABLE_PAIR: NameValuePair = { name: 'N/A', value: undefined };

export const DIALOG_SIZES = {
  xsmall: '480px',
  smaller: '580px',
  small: '640px',
  smedium: '800px',
  medium: '960px',
  large: '1280px',
  xLarge: '1600px',
};

export interface NameValuePair {
  name: string;
  value: string;
}

export const serializeValues = (object: any) =>
  Object.values(object)
    .filter((v) => !!v)
    .reduce((prev: string, next: string) => prev + next, '') as string;

export const insensitiveIncludes = (reference: string, includes: string) =>
  reference.toLowerCase().includes(includes.toLowerCase());

export const ellipsisIfApplicable = (expression = '', stringLimit: number) =>
  expression.substring(0, stringLimit).concat(expression.length > stringLimit ? '...' : '');

export const TRIANGLE_ADMIN_GROUP = 'triangle_admin';
export const TRIANGLE_DEMO_GROUP = 'triangle_demo';

export const ACCEPTABLE_IDLE_TIME = 7000;

export interface Country {
  readonly isoCode: string;
  readonly language: string;
  readonly mainLanguage: string;
}
export const DEFAULT_COUNTRY: Country = {
  isoCode: 'gb',
  language: 'en',
  mainLanguage: 'English',
};
export const SUPPORTED_COUNTRIES: Country[] = [
  DEFAULT_COUNTRY,
  { isoCode: 'fr', language: 'fr', mainLanguage: 'Français' },
  { isoCode: 'nl', language: 'nl', mainLanguage: 'Nederlands' },
];

export namespace Language {
  export const DEFAULT_LANGUAGE = 'en';
  export const APP_LANGUAGES = SUPPORTED_COUNTRIES.map((country) => country.language);
  export const isLangSupported = (lang: string) => APP_LANGUAGES.includes(lang);
  export const getDefaultLanguage = (preferred: string) => (isLangSupported(preferred) ? preferred : DEFAULT_LANGUAGE);
  export const currentLang$ = new BehaviorSubject<string>(undefined);
}

export const isArrayValid = (arr: any[]) => !!arr && !!arr.length;

export namespace TriangleTesting {
  export const modules = [
    TriangleCoreModule,
    RouterTestingModule,
    BrowserDynamicTestingModule,
    HttpClientTestingModule,
  ];

  export function asyncExpect(subject: Subject<any>, assertFn: () => void) {
    subject.pipe(debounceTime(100)).subscribe(assertFn);
  }
}

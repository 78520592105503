import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { WorkPointComponent } from './work-point.component';

@NgModule({
  declarations: [WorkPointComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule],
  exports: [WorkPointComponent],
})
export class WorkPointModule {}

import { AccessCategory } from 'triangle-signup/store';
import {
  UploadDemandEmployees,
  UploadDemandSites,
  UploadReason,
} from './upload-proposition.models';

export interface UploadPropositionCriteria {
  readonly employees: UploadDemandEmployees;
  readonly sites: UploadDemandSites;
  readonly reason: UploadReason;
  readonly fleet: boolean;
}

/** Upload Proposal Interface and Implementation */
export abstract class PackageProposal {
  abstract packageName(criteria: UploadPropositionCriteria): AccessCategory;
  abstract isApplicable(criteria: UploadPropositionCriteria): boolean;

  protected isSingleSite(sites: UploadDemandSites) {
    return sites === UploadDemandSites.ONE;
  }
  protected isTwoToTenSites(sites: UploadDemandSites) {
    return sites === UploadDemandSites.TWO_TO_TEN;
  }
  protected isLessFiftyEmployees(employees: UploadDemandEmployees) {
    return employees === UploadDemandEmployees.LESS_THAN_EQUAL_FIFTY;
  }
  protected isFiftyOneToFiveKEmployees(employees: UploadDemandEmployees) {
    return employees === UploadDemandEmployees.FIFTY_ONE_TO_FIVE_K;
  }
  protected isForTesting(reason: UploadReason) {
    return reason === UploadReason.TEST;
  }
  protected isForAnalyze(reason: UploadReason) {
    return reason === UploadReason.ANALYZE;
  }
  protected isForMore(reason: UploadReason) {
    return UploadReason.ANALYZE < reason;
  }
  protected isOneToFiveKEmployees(employees: UploadDemandEmployees) {
    return employees === UploadDemandEmployees.ONE_TO_FIVE_K;
  }
  protected isMoreThanTenSites(sites: UploadDemandSites) {
    return sites === UploadDemandSites.MORE_THAN_TEN;
  }
  protected isMoreThanFiveKEmployees(employees: UploadDemandEmployees) {
    return employees === UploadDemandEmployees.MORE_THAN_FIVE_K;
  }
  protected forAnalysisAndMore(reason: UploadReason, moreCondition: boolean) {
    return this.isForAnalyze(reason) && moreCondition;
  }
}

class Free extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return !!fleet ? AccessCategory.FreeFleet : AccessCategory.Free;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { employees, reason, sites } = criteria;
    const sitesAndEmployeesCondition =
      this.isSingleSite(sites) && this.isLessFiftyEmployees(employees);
    return (
      this.isForTesting(reason) ||
      this.forAnalysisAndMore(reason, sitesAndEmployeesCondition)
    );
  }
}

class Essential extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return !!fleet ? AccessCategory.EssentialFleet : AccessCategory.Essential;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { reason, sites, employees } = criteria;
    return (
      this.isForAnalyze(reason) &&
      this.isSingleSite(sites) &&
      this.isFiftyOneToFiveKEmployees(employees)
    );
  }
}

class EssentialPlus extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return !!fleet
      ? AccessCategory.EssentialPlusFleet
      : AccessCategory.EssentialPlus;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { employees, reason, sites } = criteria;
    const sitesAndEmployeesCondition =
      this.isTwoToTenSites(sites) &&
      (this.isLessFiftyEmployees(employees) ||
        this.isFiftyOneToFiveKEmployees(employees));
    return this.forAnalysisAndMore(reason, sitesAndEmployeesCondition);
  }
}

class Standard extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return !!fleet ? AccessCategory.StandardFleet : AccessCategory.Standard;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { reason, sites, employees } = criteria;
    return (
      this.isForMore(reason) &&
      this.isSingleSite(sites) &&
      this.isOneToFiveKEmployees(employees)
    );
  }
}

class Premium extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return !!fleet ? AccessCategory.PremiumFleet : AccessCategory.Premium;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { reason, sites, employees } = criteria;
    return (
      this.isForMore(reason) &&
      this.isTwoToTenSites(sites) &&
      this.isOneToFiveKEmployees(employees)
    );
  }
}

export class Enterprise extends PackageProposal {
  packageName(criteria: UploadPropositionCriteria): AccessCategory {
    const { fleet } = criteria;
    return AccessCategory.Enterprise;
  }
  isApplicable(criteria: UploadPropositionCriteria): boolean {
    const { employees, sites } = criteria;
    return (
      this.isMoreThanFiveKEmployees(employees) || this.isMoreThanTenSites(sites)
    );
  }
}

export function createPackageProposals(): PackageProposal[] {
  return [
    new Free(),
    new Essential(),
    new EssentialPlus(),
    new Standard(),
    new Premium(),
    new Enterprise(),
  ];
}

export enum ScenarioTypes {
  Mobility = 'mobility_scenario',
  StandardFleet = 'fleet_standard_scenario',
  AdvancedFleet = 'fleet_advanced_scenario',
}

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { API_CONFIG, AUTH_CONFIG, ENV_CONFIG, ErrorService, EyesCoreModule } from 'eyes-core';
import { EyesSharedModule } from 'eyes-shared';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../../environments';
import { HttpLoaderFactory } from './utils';

@NgModule({
  imports: [
    MatBadgeModule,
    BrowserAnimationsModule,
    MatBottomSheetModule,
    EyesSharedModule.forRoot(),
    BrowserModule,
    EyesCoreModule,
    HttpClientModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxSkeletonLoaderModule,
    StoreDevtoolsModule.instrument(),
    NgxSpinnerModule,
  ],
  exports: [EyesSharedModule],
  providers: [
    {
      provide: ErrorHandler,
      useClass: environment.production ? ErrorService : ErrorHandler,
    },
    { provide: ENV_CONFIG, useValue: environment },
    { provide: API_CONFIG, useValue: environment.api },
    { provide: AUTH_CONFIG, useValue: environment.cognito.Auth },
  ],
})
export class TriangleCoreModule {}

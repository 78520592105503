<button
  mat-icon-button
  color="accent"
  [disabled]="isDisabled"
  [matMenuTriggerFor]="showTooltip"
  [matTooltip]="quickHelp"
>
  <mat-icon class="icon-display">help</mat-icon>
</button>
<mat-menu #showTooltip="matMenu" [overlapTrigger]="overlapTrigger">
  <span class="context-content">{{ contextHelpContent }}</span>
</mat-menu>

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { rubberBandAnimation } from 'angular-animations';
import { ConfigService } from 'eyes-core';
import { BehaviorSubject } from 'rxjs';
import { APP_VERSION } from '../../app.references';

@Component({
  selector: 'triangle-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [rubberBandAnimation({ anchor: 'socialHover', direction: '=>' })],
})
export class FooterComponent implements OnInit {
  private hoverSubject = new BehaviorSubject<string>(undefined);
  readonly appVersion = this.resolveVersion();
  footerLinks: FooterLink[];
  @Input() language = 'en';

  /** Custom streams */
  hovering$ = this.hoverSubject.asObservable();

  constructor(private router: Router, private config: ConfigService) {}

  ngOnInit(): void {
    this.footerLinks = this.makeFooterLinks();
  }

  onLinkClick(urls: { [lang: string]: string }) {
    const resolvedLink = urls[this.language];
    if (resolvedLink) {
      window.open(resolvedLink, '_blank');
    }
  }
  onGetInTouch(): void {
    this.router.navigate(['support', 'contact']);
  }

  onMouseEnter(element: string): void {
    this.hoverSubject.next(element);
  }
  onMouseLeave(): void {
    this.hoverSubject.next(undefined);
  }

  private resolveVersion(): string {
    return this.config?.version?.version || APP_VERSION;
  }

  private makeFooterLinks(): FooterLink[] {
    return [
      {
        label: 'dataPrivacy',
        urls: {
          fr: 'https://tractebel-engie.be/fr/vie-priv%c3%a9e',
          en: 'https://tractebel-engie.com/en/data-privacy',
          nl: 'https://tractebel-engie.be/nl/privacy',
        },
      },
      {
        label: 'termsOfUse',
        urls: {
          fr: 'https://tractebel-engie.be/fr/conditions-d-utilisation',
          nl: 'https://tractebel-engie.be/nl/gebruiksvoorwaarden',
          en: 'https://tractebel-engie.com/en/terms-of-use',
        },
      },
      {
        label: 'suppliers',
        urls: {
          fr: 'https://tractebel-engie.be/fr/fournisseurs',
          nl: 'https://tractebel-engie.be/nl/leveranciers',
          en: 'https://tractebel-engie.com/en/suppliers',
        },
      },
      {
        label: 'ethics',
        urls: {
          fr: 'https://tractebel-engie.be/fr/ethique-et-conformite-tractebel',
          nl: 'https://tractebel-engie.be/nl/ethiek-compliance-tractebel',
          en: 'https://tractebel-engie.com/en/ethics-compliance',
        },
      },
      {
        label: 'credits',
        urls: {
          fr: 'https://tractebel-engie.be/fr/credits',
          nl: 'https://tractebel-engie.be/nl/credits',
          en: 'https://tractebel-engie.com/en/credits',
        },
      },
    ];
  }
}

interface FooterLink {
  label: string;
  urls: { [language: string]: string };
}

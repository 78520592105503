<triangle-layout
  (drawerSelect)="onDrawerSelect($event)"
  (login)="onLogin()"
  (logout)="onLogout()"
  (signup)="onSignup()"
  (languageSelect)="onLangSwitch($event)"
  (report)="onReportIssue()"
  [user]="userName$ | async"
  [currentLanguage]="currentLang$ | async"
  [drawerItems]="triangleFeatures$ | async"
>
  <div
    *ngIf="routeResolving$ | async; else outlet"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    class="full-height"
  >
    <triangle-spinner></triangle-spinner>
  </div>

  <ng-template #outlet>
    <router-outlet></router-outlet>
  </ng-template>
</triangle-layout>

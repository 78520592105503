import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  AccessCategory,
  ELIGIBLE_PACKAGES_SCENARIO,
  ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO,
  ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO,
} from 'triangle-signup/store';
import { ScenarioTypes } from 'triangle-study/models/package-proposal.models';

@Component({
  selector: 'triangle-create-scenario-options-dialog',
  templateUrl: './create-scenario-options-dialog.component.html',
  styleUrls: ['./create-scenario-options-dialog.component.scss'],
})
export class CreateScenarioOptionsDialogComponent implements OnInit {
  private imgPath = 'assets/images';
  private scenario: { [type: string]: ScenarioTypes } = ScenarioTypes;
  selectedOptionIndex: number = null;
  options = [];
  @Output() submitSelectedScenario = new EventEmitter();

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialogRef<CreateScenarioOptionsDialogComponent>,
  ) {}

  private canCreateMobility = (data) => {
    // Scenarios can't create anymore mobility scenarios
    if (!!data.scenarioType) {
      return false;
    }

    return ELIGIBLE_PACKAGES_SCENARIO.includes(data.productPackage);
  };

  private canCreateStandardFleet = (data) => {
    if (!!!data.scenarioType) {
      return ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO.includes(data.productPackage);
    } else {
      // Only mobility scenarios are allowed to create fleet scenarios
      return (
        ELIGIBLE_PACKAGES_STANDARD_FLEET_SCENARIO.includes(data.productPackage) &&
        data.scenarioType === ScenarioTypes.Mobility
      );
    }
  };

  private canCreateAdvancedFleet = (data) => {
    if (!!!data.scenarioType) {
      return ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO.includes(data.productPackage);
    } else {
      // Only mobility scenarios are allowed to create fleet scenarios
      return (
        ELIGIBLE_PACKAGES_ADVANCED_FLEET_SCENARIO.includes(data.productPackage) &&
        data.scenarioType === ScenarioTypes.Mobility
      );
    }
  };

  ngOnInit(): void {
    this.initOptions();
  }

  initOptions() {
    const options = [
      {
        image: `${this.imgPath}/Icon_mobility_active.png`,
        activeImage: `${this.imgPath}/Icon_mobility_click.png`,
        label: this.translate.instant(`CaseStudy.labels.mobility`),
        scenario: this.scenario.Mobility,
        visibilityChecker: this.canCreateMobility,
      },
      {
        image: `${this.imgPath}/Icon_fleet_scenario_standard_active.png`,
        activeImage: `${this.imgPath}/Icon_fleet_scenario_standard_click.png`,
        label: this.translate.instant(`CaseStudy.labels.standardFleet`),
        scenario: this.scenario.StandardFleet,
        visibilityChecker: this.canCreateStandardFleet,
      },
      {
        image: `${this.imgPath}/Icon_fleet_scenario_advanced_active.png`,
        activeImage: `${this.imgPath}/Icon_fleet_scenario_advanced_click.png`,
        label: this.translate.instant(`CaseStudy.labels.advanceFleet`),
        scenario: this.scenario.AdvancedFleet,
        visibilityChecker: this.canCreateAdvancedFleet,
      },
    ];
    this.options = options.filter((option) => option.visibilityChecker(this.data));
  }

  onSelectOption(index) {
    this.selectedOptionIndex = index;
  }
  onContinue() {
    const { scenario } = this.options[this.selectedOptionIndex];
    this.submitSelectedScenario.emit(scenario);
  }
  onClose() {
    this.dialog.close();
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { map } from 'rxjs/operators';
import { StatefulSubject, stringifyObject } from '../../core/utils';
import { ProformaPreview } from '../../sign-up/store';

interface UploadState {
  loadingPreview?: boolean;
  proformaTemplate?: string;
  previewError?: any;
}

@Injectable()
export class UploadService {
  state$ = new StatefulSubject<UploadState>({});
  readonly selectors = {
    proforma$: this.state$.pipe(map((state) => state.proformaTemplate)),
    previewing$: this.state$.pipe(map((state) => state.loadingPreview)),
  };
  constructor(private http: HttpClient, private config: ConfigService) {}

  previewProforma(details: ProformaPreview) {
    this.state$.update({ loadingPreview: true, proformaTemplate: undefined });
    const {
      api: { baseUrl, endpoints },
    } = this.config;
    this.http
      .get(generateEndpoint(baseUrl, endpoints.invoice.proforma), { params: stringifyObject(details) })
      .subscribe({
        next: this.onSuccess.bind(this),
        error: this.onFailure.bind(this),
      });
  }

  private onSuccess(response: { template: unknown }): void {
    this.state$.update({ loadingPreview: false, proformaTemplate: response.template as string });
  }

  private onFailure(errorResponse: HttpErrorResponse): void {
    this.state$.update({ loadingPreview: false, previewError: errorResponse.error.error });
  }
}

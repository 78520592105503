import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { InputFilterComponent } from './input-filter.component';

@NgModule({
  declarations: [InputFilterComponent],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatIconModule],
  exports: [InputFilterComponent],
})
export class InputFilterModule {}

import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TriangleSpinnerModule } from 'triangle-shared/components/spinner';
import { environment } from '../environments/environment';
import { CaseStudyRootModule } from './+case-study/case-study-root.module';
import { ProjectRootModule } from './+project/project-root.module';
import { ScenarioRootModule } from './+scenario/scenario-root.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TriangleCoreModule } from './core';
import { LayoutModule } from './layout';
import { SignUpModule } from './sign-up/sign-up.module';
import { UploadStudyModule } from './upload-study/upload-study.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    TriangleCoreModule,
    CaseStudyRootModule,
    ProjectRootModule,
    ScenarioRootModule,
    UploadStudyModule,
    AppRoutingModule,
    SignUpModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LayoutModule,
    TriangleSpinnerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

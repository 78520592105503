import { AccessCategory } from 'triangle-signup/store';

export interface GetDocRequest {
  readonly type: DocumentType;
  readonly lang: Language;
}

export interface GetDocRsp {
  readonly signedUrl: string;
}

export type DocType = DocumentType | AccessCategory;

export enum DocumentType {
  EMA_STUDY = 'employee_mobility_study',
}

export enum Language {
  EN = 'en',
  FR = 'fr',
  NL = 'nl',
}

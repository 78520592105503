import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { EyesSharedModule } from 'eyes-shared';

import { SharedModule } from '../shared/shared.module';
import { Scenario, ScenarioState, ScenarioStore } from './store';

@NgModule({
  imports: [NgxsModule.forFeature([ScenarioState]), EyesSharedModule.forFeature(), SharedModule],
  declarations: [],
  providers: [ScenarioStore, Scenario.ActionSubject],
  entryComponents: [],
})
export class ScenarioRootModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthStore } from 'eyes-core';
import { doNothing } from 'eyes-shared';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  CaseStudyModel,
  CaseStudyStore,
  StudyStatus,
} from '../../+case-study/stores';
import { TRIANGLE_ADMIN_GROUP } from '../../app.references';
import { TriangleSnackbar } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ResultsRedirectionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authStore: AuthStore,
    private studyStore: CaseStudyStore,
    private notif: TriangleSnackbar,
    private translate: TranslateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkValidation(route);
  }

  private checkValidation(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { projectId, studyId } = route.params;
    const currStudy$ = this.studyStore.getById$(studyId).pipe(
      tap((study) =>
        !study ? this.studyStore.get(projectId, studyId) : doNothing(),
      ),
      filter((study: CaseStudyModel) => !!study && !study.loading),
    );
    const isAdmin$ = this.authStore.data$.pipe(
      map(({ userInfo }) =>
        (userInfo.groups || []).includes(TRIANGLE_ADMIN_GROUP),
      ),
    );

    return combineLatest([isAdmin$, currStudy$]).pipe(
      map(
        ([isAdmin, currStudy]: any) =>
          isAdmin || currStudy.status === StudyStatus.VALIDATED,
      ),
      tap((isValidated) => {
        if (!isValidated) {
          this.router.navigate([`/projects/${projectId}/studies/${studyId}`]);
          this.notif.simpleError(
            this.translate.instant('CaseStudy.errors.resultsNotValidated'),
          );
        }
      }),
    );
  }
}

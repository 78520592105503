<!-- viewer container and table content -->
<div class="container" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="start center">
  <!-- go back, title, and export -->
  <div class="results-viewer-header" fxLayout="row" fxLayoutAlign="space-between end">
    <div class="results-viewer-header-title" fxLayout="column">
      <a mat-button color="accent" [routerLink]="backUrl" *ngIf="backUrl; else empty" [@backIn]>
        <mat-icon>chevron_left</mat-icon>
        {{ 'CaseStudy.labels.dashboard' | translate }}
      </a>
      <h1 class="title">{{ title }}</h1>
    </div>
    <div class="results-viewer-header-actions" fxLayoutGap="8px" fxLayoutAlign="end">
      <button
        mat-raised-button
        color="accent"
        [class.quarter-opacity]="creating"
        [class.hide-button-text]="creating"
        [class.spinner]="creating"
        [disabled]="loading || creating"
        *ngIf="!loading && canCreateFleet$ | async"
        (click)="onCreate()"
      >
        <mat-icon>content_copy</mat-icon>
        {{ 'CaseStudy.labels.createFleetScenario' | translate }}
      </button>
      <button
        *ngIf="!loading; else empty"
        mat-raised-button
        [matTooltip]="'CaseStudy.tooltips.exportAsExcel' | translate"
        [disabled]="loading || exporting"
        [class.spinner]="exporting"
        (click)="onExportClick()"
        color="accent"
      >
        <mat-icon>save_alt</mat-icon>
        {{ 'CaseStudy.actions.export' | translate }}
      </button>
    </div>
  </div>

  <div class="full-height" fxLayout="column" fxLayoutAlign="center center" *ngIf="loading; else loaded">
    <mat-spinner mode="indeterminate" diameter="150"></mat-spinner>
  </div>

  <ng-template #loaded>
    <div class="results-tab-group">
      <mat-tab-group (selectedTabChange)="onParentTabChange($event)">
        <mat-tab [label]="'CaseStudy.labels.results' | translate">
          <div class="triangle-list-content results-viewer-container">
            <div class="full-height" fxLayout="column" fxLayoutAlign="space-between stretch" [@tableIn]>
              <mat-tab-group (selectedTabChange)="onTabChange($event)">
                <!-- Result Set 1 -->
                <mat-tab *ngIf="!(limitedPackage$ | async)" [label]="'CaseStudy.labels.resultSet1' | translate">
                  <div class="triangle-table-container results-viewer-table">
                    <table
                      *ngIf="s1m1NonEmpty$ | async; else noRecords"
                      mat-table
                      [dataSource]="s1m1Datasource$ | async"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'transportMode'">
                            {{ getTransportationModeKey(result[columnDef]) | translate }}
                          </div>
                          <div *ngSwitchCase="'duration'">
                            <ng-template
                              *ngTemplateOutlet="durationTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchCase="'distance'">
                            <ng-template
                              *ngTemplateOutlet="distanceTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterSMColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterSMColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- Result Set 2 -->
                <mat-tab *ngIf="!(limitedPackage$ | async)" [label]="'CaseStudy.labels.resultSet2' | translate">
                  <div class="triangle-table-container results-viewer-table">
                    <table
                      *ngIf="s1m2NonEmpty$ | async; else noRecords"
                      mat-table
                      [dataSource]="s1m2Datasource$ | async"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'transportMode'">
                            {{ getTransportationModeKey(result[columnDef]) | translate }}
                          </div>
                          <div *ngSwitchCase="'duration'">
                            <ng-template
                              *ngTemplateOutlet="durationTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchCase="'distance'">
                            <ng-template
                              *ngTemplateOutlet="distanceTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="filterSMColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterSMColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- Result Set 3 -->
                <mat-tab *ngIf="!(limitedPackage$ | async)" [label]="'CaseStudy.labels.resultSet3' | translate">
                  <div class="triangle-table-container results-viewer-table">
                    <table
                      *ngIf="s2m1NonEmpty$ | async; else noRecords"
                      mat-table
                      [dataSource]="s2m1Datasource$ | async"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'transportMode'">
                            {{ getTransportationModeKey(result[columnDef]) | translate }}
                          </div>
                          <div *ngSwitchCase="'duration'">
                            <ng-template
                              *ngTemplateOutlet="durationTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchCase="'distance'">
                            <ng-template
                              *ngTemplateOutlet="distanceTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterSMColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterSMColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- Current Result -->
                <mat-tab [label]="'CaseStudy.labels.originalResultSet' | translate">
                  <div class="triangle-table-container results-viewer-table" fxLayout="row" fxLayoutAlign="center none">
                    <table
                      *ngIf="scmcNonEmpty$ | async; else noRecords"
                      mat-table
                      [dataSource]="scmcDataSource$ | async"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'transportMode'">
                            {{ getTransportationModeKey(result[columnDef]) | translate }}
                          </div>
                          <div *ngSwitchCase="'duration'">
                            <ng-template
                              *ngTemplateOutlet="durationTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchCase="'distance'">
                            <ng-template
                              *ngTemplateOutlet="distanceTemp; context: { $implicit: result[columnDef] }"
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterScMcColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterScMcColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
              </mat-tab-group>
              <div fxLayout="row" fxLayoutAlign="end end">
                <!-- paginator craziness -->
                <mat-paginator
                  class="results-viewer-paginator"
                  *ngIf="!(limitedPackage$ | async)"
                  #s1m1Paginator
                  [pageSizeOptions]="pageOptions"
                  [hidden]="(activeTabIdx$ | async) !== 0"
                  [@bottomIn]
                ></mat-paginator
                ><mat-paginator
                  class="results-viewer-paginator"
                  *ngIf="!(limitedPackage$ | async)"
                  #s1m2Paginator
                  [pageSizeOptions]="pageOptions"
                  [hidden]="(activeTabIdx$ | async) !== 1"
                  [@bottomIn]
                ></mat-paginator
                ><mat-paginator
                  class="results-viewer-paginator"
                  *ngIf="!(limitedPackage$ | async)"
                  #s2m1Paginator
                  [pageSizeOptions]="pageOptions"
                  [hidden]="(activeTabIdx$ | async) !== 2"
                  [@bottomIn]
                ></mat-paginator>
                <mat-paginator
                  class="results-viewer-paginator"
                  #scmcPaginator
                  [hidden]="(activeTabIdx$ | async) !== 3 && !(limitedPackage$ | async)"
                  [pageSizeOptions]="pageOptions"
                  [@bottomIn]
                ></mat-paginator>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab
          *ngIf="isFleet && (hasChargingSpaces$ | async)"
          [label]="'CaseStudy.labels.chargingSpaces' | translate"
        >
          <div class="triangle-list-content results-viewer-container charging-station-results">
            <div class="full-height" fxLayout="column" fxLayoutAlign="space-between stretch" [@tableIn]>
              <mat-tab-group #chargingSpacesTabs>
                <!-- S1M1 Charging Stations-->
                <mat-tab [label]="'S1M1'">
                  <div
                    class="triangle-table-container results-viewer-table scs-table"
                    fxLayout="row"
                    fxLayoutAlign="center none"
                  >
                    <table
                      mat-table
                      [dataSource]="s1m1SCSDataSource$ | async"
                      *ngIf="s1m1SCSNonEmpty$ | async; else noRecords"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'parkingSpaces'">
                            <ng-template
                              *ngTemplateOutlet="
                                numberOfParkingSpaceTemp;
                                context: { total: result['total'], parkingSpaces: result['parkingSpaces'] }
                              "
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterESEColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterESEColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- S1M2 Charging Stations-->
                <mat-tab [label]="'S1M2'">
                  <div
                    class="triangle-table-container results-viewer-table scs-table"
                    fxLayout="row"
                    fxLayoutAlign="center none"
                  >
                    <table
                      mat-table
                      [dataSource]="s1m2SCSDataSource$ | async"
                      *ngIf="s1m2SCSNonEmpty$ | async; else noRecords"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'parkingSpaces'">
                            <ng-template
                              *ngTemplateOutlet="
                                numberOfParkingSpaceTemp;
                                context: { total: result['total'], parkingSpaces: result['parkingSpaces'] }
                              "
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterESEColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterESEColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- S2M1 Charging Stations-->
                <mat-tab [label]="'S2M1'">
                  <div
                    class="triangle-table-container results-viewer-table scs-table"
                    fxLayout="row"
                    fxLayoutAlign="center none"
                  >
                    <table
                      mat-table
                      [dataSource]="s2m1SCSDataSource$ | async"
                      *ngIf="s2m1SCSNonEmpty$ | async; else noRecords"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'parkingSpaces'">
                            <ng-template
                              *ngTemplateOutlet="
                                numberOfParkingSpaceTemp;
                                context: { total: result['total'], parkingSpaces: result['parkingSpaces'] }
                              "
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterESEColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterESEColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
                <!-- SCMC Charging Stations-->
                <mat-tab [label]="'ScMc'">
                  <div
                    class="triangle-table-container results-viewer-table scs-table"
                    fxLayout="row"
                    fxLayoutAlign="center none"
                  >
                    <table
                      mat-table
                      [dataSource]="scmcSCSDataSource$ | async"
                      *ngIf="scmcSCSNonEmpty$ | async; else noRecords"
                    >
                      <ng-container *ngFor="let columnDef of columnDefs" [matColumnDef]="columnDef">
                        <th mat-header-cell *matHeaderCellDef>
                          {{ getTableHeaderKey(columnDef) | translate }}
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let result"
                          [style.background]="appropriateBGColor(columnDef)"
                          [ngSwitch]="columnDef"
                        >
                          <div *ngSwitchCase="'parkingSpaces'">
                            <ng-template
                              *ngTemplateOutlet="
                                numberOfParkingSpaceTemp;
                                context: { total: result['total'], parkingSpaces: result['parkingSpaces'] }
                              "
                            ></ng-template>
                          </div>
                          <div *ngSwitchDefault>
                            {{ roundIfApplicable(result[columnDef]) || '-' }}
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="filterESEColumns(columnDefs); sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: filterESEColumns(columnDefs)" [@rowIn]></tr>
                    </table>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>
</div>

<!-- conditional templates -->
<ng-template #noRecords>
  <div class="no-records">
    <span *ngIf="limitedPackage$ | async; else noneAvailable">
      {{ 'General.messages.limitedResults' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #noneAvailable>
  <span>
    {{ 'General.messages.noRecords' | translate }}
  </span>
</ng-template>

<ng-template #empty>
  <div fxFlex="10"></div>
</ng-template>

<ng-template #durationTemp let-minutes>
  {{ roundIfApplicable(minutes) | hoursPipe: ('Shared.abbreviation.hour' | translate) }}
</ng-template>

<ng-template #distanceTemp let-distance>
  {{ distance > 0 ? (distance | number: '1.0-0') : distance }}
</ng-template>

<ng-template #numberOfParkingSpaceTemp let-total="total" let-parkingSpaces="parkingSpaces">
  <div class="parkingSpace" fxFlex fxLayoutAlign="center center">
    {{ parkingSpaces }}
    <mat-icon *ngIf="total > parkingSpaces" [matMenuTriggerFor]="showTooltip" class="warning">warning</mat-icon>
  </div>
  <mat-menu overlayPanelClass="warning-message" #showTooltip="matMenu" [overlapTrigger]="true">
    <span class="context-content">{{ 'CaseStudy.messages.numberOfParkingSpaceWarning' | translate }}</span>
  </mat-menu>
</ng-template>

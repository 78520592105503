import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export const HttpLoaderFactory = (http: HttpClient) => {
  return new MultiTranslateHttpLoader(http, [
    // Declare all language translate files here
    { prefix: './assets/i18n/literals/literals_', suffix: '.json' },
    { prefix: './assets/i18n/support/support_', suffix: '.json' },
    { prefix: './assets/i18n/faq/faq_', suffix: '.json' },
  ]);
};

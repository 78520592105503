import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Project } from './project.action';
import { ProjectModel } from './project.models';
import { StudyCreationStatus } from '../../app.references';

@Injectable({
  providedIn: 'root',
})
export class ProjectStore {
  @Select(({ project }) => project.creating) creating$: Observable<boolean>;
  @Select(({ project }) => project.creationStatus) creationStatus$: Observable<StudyCreationStatus>;

  fetching$: Observable<boolean>;
  notYetFetched$: Observable<boolean>;
  dataList$: Observable<ProjectModel[]>;
  getByName$ = (name: string) =>
    this.dataList$.pipe(
      first(),
      map((projects) => projects.find((project) => project.name === name)),
    );
  getById$ = (id: string) =>
    this.store.select(({ project }) => project.dataMap).pipe(map((dataMap) => dataMap[id] as ProjectModel));

  constructor(private store: Store) {
    const projectState$ = this.store.select(({ project }) => project);
    this.dataList$ = projectState$.pipe(map(({ dataMap }) => Object.values(dataMap)));
    this.fetching$ = projectState$.pipe(map(({ fetchStatus }) => fetchStatus === 'fetching'));
    this.notYetFetched$ = projectState$.pipe(map(({ fetchStatus }) => !!!fetchStatus));
  }

  getList = () => this.store.dispatch(new Project.GetList());

  create = (name: string) => this.store.dispatch(new Project.Create({ name }));

  get = (id: string) => this.store.dispatch(new Project.Get({ id }));
}

/* tslint:disable:cyclomatic-complexity */
import { Injectable } from '@angular/core';
import { Actions, Select, Store } from '@ngxs/store';
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AccessCategory, ProformaPreview } from 'triangle-signup/store';
import { StudyCreationStatus } from '../../app.references';
import { Calculation, CaseStudy, ExcelInput, Geocoding, Invoice, Parameters, Template } from './case-study.actions';
import { CaseStudyModel, ResultsFilter, UploadActionPayload, ValidationFeedback } from './case-study.models';

@Injectable()
export class CaseStudyStore {
  // Active creation status
  @Select(({ caseStudy }) => caseStudy.creationStatus)
  creationStatus$: Observable<StudyCreationStatus>;
  @Select(({ caseStudy }) => caseStudy.startingCalc) startingCalc$: Observable<boolean>;
  @Select(({ caseStudy }) => caseStudy.directionizing)
  directionizing$: Observable<boolean | 'finished'>;
  recentTrips$ = new Subject();
  @Select(({ caseStudy }) => caseStudy.results) results$: Observable<any>;
  @Select(({ caseStudy }) => caseStudy.templating) templating$: Observable<boolean>;
  @Select(({ caseStudy }) => caseStudy.downloadingInput) inputting$: Observable<boolean>;
  @Select(({ caseStudy: { studies } }) => studies.parameterizing)
  parameterizing$: Observable<'set' | 'get'>;

  // Is a case study being uploaded?
  uploading$: Observable<boolean>;
  dataList$: Observable<CaseStudyModel[]>;
  // Is the list of IDs being fetched?
  @Select(({ caseStudy }) => caseStudy.studies.fetching) fetching$: Observable<boolean>;
  // Has an uploadSuccess action been dispatched?
  uploadSuccess$ = this.actions.pipe(
    filter(
      ({ action, status }: ActionContext) =>
        action instanceof CaseStudy.UploadSuccess && status === ActionStatus.Successful,
    ),
  );
  // invoice price map
  prices$: Observable<any>;
  @Select(({ caseStudy }) => caseStudy.fetchingPrice) fetchingPrice$: Observable<boolean>;
  // Recent validation errors
  recentErrors$ = new Subject<any>();
  @Select(({ caseStudy }) => caseStudy.validationErrors) private errors$: Observable<any>;
  @Select(({ caseStudy }) => caseStudy.validationFeedback) feedback$: Observable<ValidationFeedback[]>;
  // Get the instance of CaseStudy by ID
  getById$ = (id: string): Observable<CaseStudyModel> =>
    this.store.select(({ caseStudy: { studies } }) => studies.dataMap).pipe(map((dataMap) => dataMap[id]));
  // Get the matched CaseStudies by projectID
  getByProjectId$ = (id: string): Observable<CaseStudyModel[]> =>
    this.dataList$.pipe(map((studies) => studies.filter((study) => study.projectId === id)));
  // Get a specific price given a package, site count and employee count
  getSpecificPrice$ = (pkg: AccessCategory, sites: number, employees: number): Observable<any> =>
    this.prices$.pipe(
      map((priceMap) => {
        const shouldApply = !!pkg && [sites, employees].every((p) => p !== 0);
        const priceKey = `${pkg}_${sites}_${employees}`;
        return shouldApply && !!priceMap ? priceMap[priceKey] : 0;
      }),
    );

  constructor(private store: Store, private actions: Actions) {
    const state$ = this.store.select(({ caseStudy }) => caseStudy);
    // Get all available CaseStudy instances
    this.dataList$ = state$.pipe(map(({ studies }) => Object.values(studies.dataMap)));
    this.errors$.subscribe((errors) => this.recentErrors$.next(errors));
    this.results$.pipe(filter((trips) => !!trips)).subscribe((trips) => this.recentTrips$.next(trips));
    this.uploading$ = state$.pipe(
      map(({ creationStatus }) => !!creationStatus && creationStatus !== StudyCreationStatus.Validated),
    );
    this.prices$ = state$.pipe(map(({ priceMap }) => priceMap));
  }

  upload = (data: UploadActionPayload) => this.store.dispatch(new CaseStudy.Upload(data));

  confirmCreation = () => this.store.dispatch(new CaseStudy.ConfirmCreate());

  getList = (projectId: string) => this.store.dispatch(new CaseStudy.GetList({ projectId }));

  get = (projectId: string, id: string) => this.store.dispatch(new CaseStudy.Get({ projectId, id }));

  getGeocodingStatus = (projectId: string, caseStudyId: string) =>
    this.store.dispatch(new Geocoding.Check({ projectId, id: caseStudyId }));

  startCalculation = (projectId: string, caseId: string, calculationDate?: string) =>
    this.store.dispatch(new Calculation.Start({ projectId, caseId, calculationDate }));

  directionalize = (projectId, caseId) => this.store.dispatch(new Calculation.Directionalize({ caseId, projectId }));

  downloadTemplate = () => this.store.dispatch(new Template.GetSignedURL());

  downloadInput = (study: CaseStudyModel) => this.store.dispatch(new ExcelInput.GetSignedURL({ study }));

  downloadResults = (projectId, caseId) => this.store.dispatch(new Calculation.DownloadResults({ projectId, caseId }));

  downloadFiltered = (projectId, caseId, _filter: ResultsFilter) =>
    this.store.dispatch(new Calculation.DownloadResults({ projectId, caseId, filter: _filter }));

  getParams = (projectId, id) => this.store.dispatch(new Parameters.Get({ projectId, id }));

  patchParams = (projectId, id, params, rerun) =>
    this.store.dispatch(new Parameters.Patch({ projectId, id, params, rerun }));

  getPrices = (proformaPreview?: ProformaPreview) => this.store.dispatch(new Invoice.GetPrices({ proformaPreview }));

  startDemoCalculation(projectId: string, caseId: string): void {
    this.store.dispatch(new Calculation.StartDemo({ projectId, caseId }));
  }

  patchPresenceRatio(
    sites: Array<{ id: string; name: string; presenceRatio: number }>,
    projectid: string,
    caseid: string,
  ): void {
    this.store.dispatch(new Parameters.PatchPresenceRatio({ sites, projectid, caseid }));
  }
}

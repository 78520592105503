import { forwardRef, Provider } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { filter } from 'rxjs/operators';

export const blobToString$ = (fileBlob) =>
  new Observable((observer: Observer<any>) => {
    const readerGoneWild = new FileReader();
    readerGoneWild.onloadend = () => {
      observer.next(readerGoneWild.result);
      observer.complete();
    };
    readerGoneWild.readAsText(fileBlob);
  });
export class StatefulSubject<T> extends BehaviorSubject<T> {
  update(next: T) {
    const newState = Object.assign(this.value as any, next);
    this.next(newState);
  }
}

export const stringifyObject = (obj: any) =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = String(obj[curr]);
    return acc;
  }, {});

export const cleanPipe = (stream: Observable<any>) =>
  stream.pipe(filter((data) => !!data));

export const getCVAProvider = (component: unknown): Provider => ({
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => component),
  multi: true,
});

export const getValidatorProvider = (component: unknown): Provider => ({
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => component),
  multi: true,
});

import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { EyesSharedModule } from 'eyes-shared';
import { CalculationDatePickerModule } from 'triangle-shared/components/calculation-date-picker';
import { ParametersManifestModule } from 'triangle-support/components/parameters-manifest';
import { TriangleLoaderService } from '../core';
import { SharedModule } from '../shared/shared.module';
import {
  CalculationConfirmationDialogComponent,
  CaseUploadComponent,
  CreateAdvanceFleetScenarioDialogComponent,
  CreateScenarioDialogComponent,
  CreateStandardFleetScenarioDialogComponent,
  PackagePropositionComponent,
  ParametersDialogComponent,
  UnsupportedCityDialogComponent,
  UploadDemandComponent,
  UploadFleetComponent,
  UploadReasonOptionsComponent,
  UploadReasonsComponent,
  ValidationFormComponent,
} from './components';
import { DemoDiscoveryModule } from './components/demo-discovery';
import { PackagePostulationComponent } from './components/package-postulation';
import { UploadCaseDialogComponent } from './containers';
import {
  CaseStudy,
  CaseStudyState,
  CaseStudyStore,
  StudiesState,
} from './stores';

@NgModule({
  imports: [
    NgxsModule.forFeature([CaseStudyState, StudiesState]),
    EyesSharedModule.forFeature(),
    SharedModule,
    ParametersManifestModule,
    CalculationDatePickerModule,
    DemoDiscoveryModule,
  ],
  declarations: [
    ParametersDialogComponent,
    CreateScenarioDialogComponent,
    CalculationConfirmationDialogComponent,
    UploadCaseDialogComponent,
    UploadReasonsComponent,
    UploadReasonOptionsComponent,
    UploadDemandComponent,
    PackagePropositionComponent,
    CaseUploadComponent,
    ValidationFormComponent,
    UploadFleetComponent,
    CreateStandardFleetScenarioDialogComponent,
    CreateAdvanceFleetScenarioDialogComponent,
    UnsupportedCityDialogComponent,
    PackagePostulationComponent,
  ],
  providers: [CaseStudyStore, CaseStudy.ActionSubject, TriangleLoaderService],
  entryComponents: [
    ParametersDialogComponent,
    CreateScenarioDialogComponent,
    CalculationConfirmationDialogComponent,
    UploadCaseDialogComponent,
    UploadReasonsComponent,
    CreateStandardFleetScenarioDialogComponent,
    CreateAdvanceFleetScenarioDialogComponent,
  ],
  exports: [ValidationFormComponent],
})
export class CaseStudyRootModule {}

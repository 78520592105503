import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coerce, getCVAProvider } from 'triangle-core';

@UntilDestroy()
@Component({
  selector: 'triangle-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [getCVAProvider(InputComponent)],
})
export class InputComponent implements ControlValueAccessor {
  private onChange: (value: string) => void;
  @Input() label = '';
  @Input() icon = '';
  @Input() error = '';
  @Input() required = false;

  readonly control: FormControl = new FormControl();

  constructor() {
    this.subscribeToChangesForCVAPropagation();
  }

  writeValue(obj: any): void {
    this.control.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(): void {
    // do nothing
  }
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  private subscribeToChangesForCVAPropagation(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => Coerce.voidFn(this.onChange)(value));
  }
}

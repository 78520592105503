import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResultsFilter } from '../../+case-study/stores';
import { Scenario } from './scenario.actions';
import { ScenarioModel } from './scenario.models';

@Injectable()
export class ScenarioStore {
  constructor(private store: Store) {
    const state$ = this.store.select(({ scenario }) => scenario);
    this.creating$ = this.store.select(({ scenario }) => scenario.creating);
    this.fetching$ = state$.pipe(map(({ fetching }) => fetching));
    this.dataList$ = state$.pipe(map(({ dataMap }) => Object.values(dataMap)));
    this.downloading$ = state$.pipe(map(({ downloadingResults }) => downloadingResults));
  }
  creating$: Observable<boolean>;
  fetching$: Observable<boolean>;
  dataList$: Observable<ScenarioModel[]>;
  downloading$: Observable<boolean>;

  results$: Observable<any> = this.store.select((globalState) => globalState.scenario.results);
  isGettingResults$: Observable<boolean> = this.store.select((globalState) => globalState.scenario.gettingResults);

  // Get the instance of scenario by id
  getById$ = (id: string): Observable<ScenarioModel> =>
    this.store.select(({ scenario }) => scenario.dataMap).pipe(map((dataMap) => dataMap[id]));
  // Get the matched scenarios by caseId
  getByCaseId$ = (id: string): Observable<ScenarioModel[]> =>
    this.dataList$.pipe(map((scenarios) => scenarios.filter((scenario) => scenario.caseId === id)));

  create = (projectId: string, caseId: string, data: any) =>
    this.store.dispatch(new Scenario.Create({ projectId, caseId, data }));

  get = (projectId: string, caseId: string, scenarioId: string) =>
    this.store.dispatch(new Scenario.Get({ projectId, caseId, scenarioId }));

  getList = (projectId: string, caseId: string) => this.store.dispatch(new Scenario.GetList({ projectId, caseId }));

  getResults = (projectId: string, caseId: string, scenarioId: string) =>
    this.store.dispatch(new Scenario.GetResults({ projectId, caseId, scenarioId }));

  downloadResults = (projectId: string, caseId: string, scenarioId: string, filter?: ResultsFilter) =>
    this.store.dispatch(new Scenario.DownloadResults({ projectId, caseId, scenarioId, filter }));

  createFleet = (projectId: string, caseId: string, data: any) =>
    this.store.dispatch(new Scenario.CreateFleet({ projectId, caseId, data }));
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { RouteHistoryService } from 'ngx-route-history';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TriangleConfig } from 'triangle-env';
import { ServiceDeskForm, ServiceDeskIssueType } from 'triangle-support/models';
@Injectable()
export class ServiceDeskService {
  private createdTicketNumberSubject: BehaviorSubject<string>;
  private loadingSubject: Subject<boolean>;
  private errorSubject: Subject<string>;

  constructor(
    private httpClient: HttpClient,
    private config: ConfigService,
    private routeHistory: RouteHistoryService,
  ) {
    this.createdTicketNumberSubject = new BehaviorSubject('');
    this.loadingSubject = new Subject();
    this.errorSubject = new Subject();
  }

  submitRequest(requestForm: ServiceDeskForm): void {
    this.loadingSubject.next(true);
    this.httpClient
      .post(this.buildCreateTicketEndpoint(), this.buildPayload(requestForm))
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe({
        next: this.onSuccess.bind(this),
        error: this.onFailure.bind(this),
      });
  }

  getTicketNumberStream(): Observable<string> {
    return this.createdTicketNumberSubject.asObservable();
  }

  getLoadingStream(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  getErrorStream(): Observable<string> {
    return this.errorSubject.asObservable();
  }

  ejectRecentTicket(): void {
    this.createdTicketNumberSubject.next('');
  }

  navigateToServiceDesk(): void {
    window.open(this.buildServiceDeskTicketURL(), '_blank');
  }

  private buildServiceDeskTicketURL(): string {
    const config = this.config.environment as TriangleConfig;
    return `${config.serviceDeskUrl}`;
  }

  private buildCreateTicketEndpoint(): string {
    const {
      api: { baseUrl, endpoints },
    } = this.config;
    return generateEndpoint(baseUrl, endpoints.support.support);
  }

  private buildPayload(requestForm: ServiceDeskForm): ServiceDeskRequest {
    const { summary, description, issueType } = requestForm;
    const issueTypeValue = issueType.value;
    return {
      fields: {
        summary,
        description,
        issuetype: issueTypeValue,
        request_type: issueTypeValue,
        version: this.config.version.version,
        url: this.routeHistory.previousRoute,
      },
    };
  }

  private onSuccess(response: ServiceDeskResponse) {
    this.createdTicketNumberSubject.next(response.ticket.key);
  }

  private onFailure(error: HttpErrorResponse) {
    const errorCode = error.error.error;
    this.errorSubject.next(errorCode);
  }
}

interface ServiceDeskRequest {
  readonly fields: {
    readonly summary: string;
    readonly description: string;
    readonly version: string;
    readonly issuetype: ServiceDeskIssueType;
    readonly request_type: ServiceDeskIssueType;
    readonly url: string;
  };
}

interface ServiceDeskResponse {
  readonly ticket: {
    readonly id: string;
    readonly key: string;
  };
}

/* tslint:disable:cyclomatic-complexity max-file-line-count*/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { saveAs } from 'file-saver';
import { throwError } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { RESOURCE_CONTAINERS, StudyCreationStatus, TRIANGLE_TEMPLATE_FILENAME } from '../../app.references';
import { LocalCacheService } from '../../core/services';
import { TriangleSnackbar } from '../../core/services/snackbar.service';
import { blobToString$, stringifyObject } from '../../core/utils';
import { AccessCategory } from '../../sign-up/store';
import { Calculation, CaseStudy, ExcelInput, Geocoding, Invoice, Template, Parameters } from './case-study.actions';
import { CalculationResultsModel, CaseStudyModel, StudyStatus, ValidationFeedback } from './case-study.models';
import { StudiesState } from './studies.state';

export interface CaseStudyStateModel {
  creationStatus?: StudyCreationStatus;
  error?: any;
  validationErrors?: any;
  validationFeedback?: ValidationFeedback[];
  startingCalc?: boolean;
  templating?: boolean;
  downloadingInput?: boolean;
  pendingCreation?: CaseStudyModel;
  // invoice-specific
  fetchingPrice?: boolean;
  priceMap?: { [key: string]: number | '' };
  // directions-specific
  directionizing?: boolean | 'finished';
  results?: any;
}

@State<CaseStudyStateModel>({
  name: 'caseStudy',
  defaults: {},
  children: [StudiesState],
})
@Injectable()
export class CaseStudyState {
  constructor(
    private http: HttpClient,
    private _config: ConfigService,
    private notif: TriangleSnackbar,
    private actionSubj: CaseStudy.ActionSubject,
    private _translate: TranslateService,
    private cache: LocalCacheService,
  ) {}

  @Action(CaseStudy.Upload)
  upload({ patchState, dispatch }, { payload: { file, projectId, name, productPackage } }: CaseStudy.Upload) {
    const { baseUrl, endpoints } = this._config.api;
    patchState({
      creationStatus: StudyCreationStatus.GeneratingSignedUrl,
      error: undefined,
      validationErrors: undefined,
      pendingCreation: undefined,
    });
    this.http
      .get(generateEndpoint(baseUrl, endpoints.study.upload, projectId), {
        params: { filename: file.name },
      })
      .pipe(
        mergeMap(({ url, caseStudyUuid }: any) => {
          patchState({ creationStatus: StudyCreationStatus.Uploading });
          const actualUrl = Array.isArray(url) ? url[1] : url;
          return this.http.put(actualUrl, file).pipe(
            map((_) => ({ caseStudyUuid })),
            catchError((error) => throwError(error)),
          );
        }),
      )
      .subscribe(
        ({ caseStudyUuid }) =>
          dispatch(
            new CaseStudy.UploadSuccess({
              data: { id: caseStudyUuid, projectId, name, productPackage },
              filename: file.name,
            }),
          ),
        (error) => dispatch(new CaseStudy.UploadFailure({ error })),
      );
  }

  @Action(CaseStudy.UploadSuccess)
  uploadSuccess({ dispatch }: StateContext<CaseStudyStateModel>, { payload: { data } }: CaseStudy.UploadSuccess) {
    // Pass through!
    dispatch(new CaseStudy.Validate({ data }));
  }

  @Action(CaseStudy.UploadFailure)
  uploadFailure({ patchState }, { payload: { error } }: CaseStudy.UploadFailure) {
    patchState({ creationStatus: undefined, error });
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  @Action(Geocoding.Check)
  geocodingCheck({ dispatch }: StateContext<any>, { payload: { projectId, id } }: Geocoding.Check) {
    const { baseUrl, endpoints } = this._config.api;
    const pauseFor = (_size: number) => _size * 1000; // Arbitrary
    this.http
      .get(generateEndpoint(baseUrl, endpoints.study.geocoding, projectId, id))
      .subscribe((statusResponse: any) => {
        const { status, count } = statusResponse;
        if (status === StudyStatus.GEOCODING) {
          setTimeout(() => dispatch(new Geocoding.Check({ projectId, id })), pauseFor(count));
        } else {
          dispatch(new Geocoding.Finished({ projectId, id }));
        }
      });
  }

  @Action(Geocoding.Finished)
  geocodingFinished(
    { dispatch }: StateContext<CaseStudyStateModel>,
    { payload: { projectId, id } }: Geocoding.Finished,
  ) {
    dispatch(new CaseStudy.Get({ projectId, id }));
  }

  @Action(CaseStudy.Validate)
  validate({ patchState, dispatch }: StateContext<CaseStudyStateModel>, { payload: { data } }: CaseStudy.Validate) {
    patchState({ creationStatus: StudyCreationStatus.Validating, error: undefined });
    const { baseUrl, endpoints } = this._config.api;
    const { projectId, id, name, productPackage } = data;
    this.http
      .post(
        generateEndpoint(baseUrl, endpoints.study.list, projectId),
        // should contain id, projId, and name
        {
          caseStudyName: name,
          caseUuid: id,
          package: productPackage,
          validateOnly: true,
        },
      )
      .subscribe(
        (_data) => {
          patchState({ pendingCreation: { ...data } });
          dispatch(new CaseStudy.ValidateOK(_data as any));
        },
        ({ error }) => {
          const { global, personal, sites } = error;
          const nextAction = [global, personal, sites].some((e) => !!e)
            ? new CaseStudy.ValidateNOK({ global, personal, sites })
            : new CaseStudy.ValidateCreateError({ error });
          dispatch(nextAction);
        },
      );
  }

  @Action(CaseStudy.ConfirmCreate)
  create({ patchState, dispatch, getState }: StateContext<CaseStudyStateModel>) {
    patchState({ creationStatus: StudyCreationStatus.Creating, error: undefined });
    const { baseUrl, endpoints } = this._config.api;
    const { projectId, id, name, productPackage } = getState().pendingCreation;
    this.http
      .post(
        generateEndpoint(baseUrl, endpoints.study.list, projectId),
        // should contain id, projId, and name
        {
          caseStudyName: name,
          caseUuid: id,
          package: productPackage,
          lang: this.cache.preferredLang$.value,
        },
      )
      .subscribe(
        (_data) => dispatch(new CaseStudy.CreateOK({ data: _data })),
        ({ error }) => dispatch(new CaseStudy.ValidateCreateError({ error })),
      );
  }

  @Action(CaseStudy.ValidateOK)
  validateOK({ patchState }: StateContext<CaseStudyStateModel>, { payload }: CaseStudy.ValidateOK) {
    const optionalFeedback = !!payload ? payload.warnings : undefined;
    patchState({
      creationStatus: StudyCreationStatus.Validated,
      validationFeedback: optionalFeedback,
    });
  }

  @Action(CaseStudy.ValidateNOK)
  validateNOK(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { sites, personal, global } }: CaseStudy.ValidateNOK,
  ) {
    patchState({
      creationStatus: undefined,
      validationErrors: { sites, personal, global },
    });
  }

  @Action(CaseStudy.CreateOK)
  createOK({ patchState }: StateContext<CaseStudyStateModel>, { payload: { data } }: CaseStudy.CreateOK) {
    patchState({ creationStatus: undefined, error: undefined });
    const message = `${(data || {}).name || 'Case'} ${this._translate.instant('CaseStudy.messages.createSuccess')}`;
    const { projects, studies } = RESOURCE_CONTAINERS;
    this.notif.navigatingSuccess({
      message,
      action: this._translate.instant('CaseStudy.actions.goThere'),
      route: [projects, data.projectUuid, studies, data.caseUuid],
    });
    this.actionSubj.isCreateSuccess$.next();
  }

  @Action(CaseStudy.ValidateCreateError)
  validateAndCreateError(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { error } }: CaseStudy.ValidateCreateError,
  ) {
    patchState({ creationStatus: undefined, error });
    const returnMsg = !!error ? this._translate.instant(`CaseStudy.errors.${error.error}`) : undefined;
    this.notif.simpleError(returnMsg);
  }

  @Action(Calculation.StartDemo)
  startDemoCalculation(
    { dispatch, patchState }: StateContext<CaseStudyStateModel>,
    { payload: { projectId, caseId } }: Calculation.StartDemo,
  ) {
    const { baseUrl, endpoints } = this._config.api;
    const endpoint = generateEndpoint(baseUrl, endpoints.study.calculate, projectId, caseId);
    const calculationDate = '2021-04-21T00:00:00+00:00';
    const config = { duration: 5000 } as MatSnackBarConfig;
    patchState({ startingCalc: true });
    this.notif.simpleSuccess(this._translate.instant('CaseStudy.messages.calcSuccessDemo'), undefined, config);
    this.http.post(endpoint, { acceptInvoice: true, calculationDate }).subscribe(
      // calculation has successfully started
      () => dispatch(new Calculation.StartDemoSuccess({ projectId, caseId })),
      // either simulation is still on going, or addressess are unavailable
      ({ error }) => dispatch(new Calculation.StartFailure({ error })),
    );
  }

  @Action(Calculation.StartDemoSuccess)
  startDemoCalcSuccess(
    { patchState, dispatch }: StateContext<CaseStudyStateModel>,
    { payload: { projectId, caseId } }: Calculation.StartSuccess,
  ) {
    this.notif.simpleSuccess(this._translate.instant('CaseStudy.placeholders.calculationsFinished'));
    patchState({ startingCalc: false });
    // patchState({ pendingCreation: { status: StudyStatus.VALIDATED } as CaseStudyModel });
    // refetch to update store entity
    dispatch(new CaseStudy.Get({ projectId, id: caseId }));
  }

  @Action(Calculation.Start)
  startCalculation(
    { dispatch, patchState }: StateContext<CaseStudyStateModel>,
    { payload: { projectId, caseId, calculationDate } }: Calculation.Start,
  ) {
    const { baseUrl, endpoints } = this._config.api;
    patchState({ startingCalc: true });
    this.http
      .post(generateEndpoint(baseUrl, endpoints.study.calculate, projectId, caseId), {
        acceptInvoice: true,
        calculationDate,
      })
      .subscribe(
        // calculation has successfully started
        () => dispatch(new Calculation.StartSuccess({ projectId, caseId })),
        // either simulation is still on going, or addressess are unavailable
        ({ error }) => dispatch(new Calculation.StartFailure({ error })),
      );
  }

  @Action(Calculation.StartSuccess)
  startCalcSuccess(
    { patchState, dispatch }: StateContext<CaseStudyStateModel>,
    { payload: { projectId, caseId } }: Calculation.StartSuccess,
  ) {
    patchState({ startingCalc: false });
    this.notif.autoNavigateSuccess({
      message: this._translate.instant('CaseStudy.messages.calcSuccess'),
      route: ['/'],
    });
    // refetch to update store entity
    dispatch(new CaseStudy.Get({ projectId, id: caseId }));
  }

  @Action(Calculation.StartFailure)
  startCalcFailure(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { error } }: Calculation.StartFailure,
  ) {
    patchState({ startingCalc: false });
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  @Action(Calculation.Directionalize)
  directionalize(
    { patchState, dispatch }: StateContext<CaseStudyStateModel>,
    { payload: { caseId, projectId } }: Calculation.Directionalize,
  ) {
    const { baseUrl, endpoints } = this._config.api;
    patchState({ directionizing: true, results: undefined });
    this.http
      .get(generateEndpoint(baseUrl, endpoints.study.results, projectId, caseId))
      .pipe(
        mergeMap(({ resultsUrl }: any) => this.http.get(resultsUrl)),
        mergeMap((fileBlob) => blobToString$(fileBlob)),
        map((blobString) => JSON.parse(blobString as string)),
      )
      .subscribe(
        ({ results }) => dispatch(new Calculation.DirectionSuccess({ results })),
        (error) => dispatch(new Calculation.DirectionFailure({ error })),
      );
  }

  @Action(Calculation.DirectionSuccess)
  directionSuccess(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { results } }: Calculation.DirectionSuccess,
  ) {
    const mappedResults = CalculationResultsModel.mapIncoming(results);
    patchState({ results: mappedResults, directionizing: 'finished' });
    this.actionSubj.getResultsSuccess$.next();
  }

  @Action(Calculation.DirectionFailure)
  directionFailure(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { error } }: Calculation.DirectionFailure,
  ) {
    patchState({ error, directionizing: false });
  }

  // Template-specific effects
  @Action(Template.GetSignedURL)
  getSignedUrl({ patchState, dispatch }: StateContext<CaseStudyStateModel>) {
    const { baseUrl, endpoints } = this._config.api;
    patchState({ templating: true });
    this.http.get(generateEndpoint(baseUrl, endpoints.study.template)).subscribe(
      ({ templateUrl }: any) => dispatch(new Template.GetSignedURLSuccess({ templateUrl })),
      ({ error }) => dispatch(new Template.GetSignedURLFailure({ error })),
    );
  }

  @Action(Template.GetSignedURLSuccess)
  async getSignedUrlSuccess(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { templateUrl } }: Template.GetSignedURLSuccess,
  ) {
    this.actionSubj.getTemplateUrlSuccess$.next();
    const templateFile = await this.http.get(templateUrl).toPromise();
    if (!!templateFile) {
      patchState({ templating: false });
    }
    saveAs(templateFile as Blob, TRIANGLE_TEMPLATE_FILENAME);
  }

  @Action(Template.GetSignedURLFailure)
  getSignedUrlFailure(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { error } }: Template.GetSignedURLFailure,
  ) {
    patchState({ templating: false });
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  @Action(ExcelInput.GetSignedURL)
  inputGetSigned(ctx: StateContext<CaseStudyStateModel>, { payload: { study } }: ExcelInput.GetSignedURL) {
    ctx.patchState({ downloadingInput: true });
    const { baseUrl, endpoints } = this._config.api;
    const { projectId, id, fileName } = study;
    this.http
      .get(generateEndpoint(baseUrl, endpoints.study.downloadInput, projectId, id))
      .pipe(first())
      .subscribe(
        ({ inputUrl }: any) => ctx.dispatch(new ExcelInput.GetSignedURLSuccess({ url: inputUrl, filename: fileName })),
        ({ error: { error } }) => ctx.dispatch(new ExcelInput.GetSignedURLFailure({ error })),
      );
  }

  @Action(ExcelInput.GetSignedURLSuccess)
  async inputGetSignedSuccess(
    ctx: StateContext<CaseStudyStateModel>,
    { payload: { url, filename } }: ExcelInput.GetSignedURLSuccess,
  ) {
    const inputFile = await this.http.get(url).toPromise();
    if (!!inputFile) {
      ctx.patchState({ downloadingInput: false });
    }
    saveAs(inputFile as Blob, filename || 'input.xlsx');
  }

  @Action(ExcelInput.GetSignedURLFailure)
  inputGetSignedFailure(
    ctx: StateContext<CaseStudyStateModel>,
    { payload: { error } }: ExcelInput.GetSignedURLFailure,
  ) {
    ctx.patchState({ downloadingInput: false });
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  @Action(Invoice.GetPrices)
  getPrices(
    { patchState, dispatch, getState }: StateContext<CaseStudyStateModel>,
    { payload: { proformaPreview } }: Invoice.GetPrices,
  ) {
    // replace undefined or null values for Enterprise to prevent errors returned by backend
    const queryParams =
      !!proformaPreview && proformaPreview.package === AccessCategory.Enterprise
        ? { package: AccessCategory.Enterprise, sites: '1', employees: '1' }
        : stringifyObject(proformaPreview);
    // priceId will be the key used for specific prices per package, site and employees
    // format: packageName_siteCount_employeeCount
    const priceId = !!queryParams
      ? `${queryParams['package']}_${queryParams['sites']}_${queryParams['employees']}`
      : undefined;

    // [pseudo-caching] if priceId is already available in priceMap, return at once
    const prices = getState().priceMap;
    if (!!prices && !!priceId && prices[priceId]) {
      return;
    }
    // [pseudo-caching] check/return if all base prices for each package is available in priceMap
    const packages = Object.values(AccessCategory);
    const allPackagePrices = !!prices && packages.every((pkg) => prices[pkg]);
    if (!!!priceId && allPackagePrices) {
      return;
    }

    patchState({ fetchingPrice: true });
    const { baseUrl, endpoints } = this._config.api;
    this.http
      .get(generateEndpoint(baseUrl, endpoints.invoice.prices), { params: queryParams, observe: 'response' })
      .subscribe(
        (response: any) => dispatch(new Invoice.GetPricesSuccess({ body: response.body, priceId })),
        ({ error }) => dispatch(new Invoice.GetPricesFailure({ error })),
      );
  }

  @Action(Invoice.GetPricesSuccess)
  getPricesSuccess(
    { patchState, getState }: StateContext<CaseStudyStateModel>,
    { payload: { body, priceId } }: Invoice.GetPricesSuccess,
  ) {
    const fetchedPrice = !!priceId ? { [priceId]: Object.values(body)[0] } : body;
    patchState({
      fetchingPrice: false,
      priceMap: { ...getState().priceMap, ...fetchedPrice },
    });
  }

  @Action(Invoice.GetPricesFailure)
  getPricesFailure(
    { patchState }: StateContext<CaseStudyStateModel>,
    { payload: { error } }: Invoice.GetPricesFailure,
  ) {
    patchState({ fetchingPrice: false });
    this.notif.simpleError(this._translate.instant('Invoice.errors.getFailed'));
  }

  @Action(Parameters.PatchPresenceRatio)
  patchPresenceRatio(ctx: StateContext<CaseStudyStateModel>, action: Parameters.PatchPresenceRatio) {
    const { baseUrl } = this._config.api;
    const { sites, projectid, caseid } = action.payload;
    const endpoint = generateEndpoint(baseUrl, `projects/${projectid}/cases/${caseid}/sites`);
    ctx.patchState({ startingCalc: true });
    this.http
      // TODO: Add to the endpoints in config!!!
      .patch(endpoint, { sites })
      .subscribe(
        (r) => {
          // Fetch the case again when successful
          ctx.patchState({ startingCalc: false });
          ctx.dispatch(new CaseStudy.Get({ projectId: projectid, id: caseid }));
        },
        (error) => {
          ctx.patchState({ startingCalc: false });
          ctx.dispatch(new Parameters.PatchPresenceRatioFailure({ error }));
        },
      );
  }
}

export interface BreadcrumbModel {
  projectName: string;
  studyName?: string;
  crumbs?: string[];
}

export const scatterCrumbs = (crumb: BreadcrumbModel): string => {
  const { projectName, studyName, crumbs } = crumb;
  const proj = `<b>Project: </b> ${projectName}`;
  const stud = !!studyName ? `<b>Case Study: </b> ${studyName}` : undefined;
  const foundation = [proj, stud].filter((_) => !!_);
  const fullyFurnished = [...foundation, ...(crumbs || []).map((c) => `<b>${c}</b>`)];
  return fullyFurnished.join(' > ');
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ContactAgreementsComponent } from './contact-agreements.component';

@NgModule({
  declarations: [ContactAgreementsComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [ContactAgreementsComponent],
})
export class ContactAgreementsModule {}

import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export abstract class CVA<T> implements ControlValueAccessor {
  onChange: (value: T) => void;
  onTouched: () => void;
  abstract writeValue(obj: Partial<T>): void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

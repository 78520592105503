import { AfterViewInit, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ParametersManifestService } from 'triangle-support/services';

@Component({
  selector: 'triangle-parameters-manifest',
  templateUrl: './parameters-manifest.component.html',
  styleUrls: ['./parameters-manifest.component.scss'],
  providers: [ParametersManifestService],
})
export class ParametersManifestComponent implements AfterViewInit {
  private filteredSubject = new BehaviorSubject<Parameter[]>(undefined);

  dataSource$: Observable<MatTableDataSource<Parameter>>;
  parameters$: Observable<Parameter[]>;

  constructor(private service: ParametersManifestService) {
    this.service.get();
  }

  ngAfterViewInit(): void {
    this.dataSource$ = this.makeMatTableDataSourceStream().pipe(delay(0));
    this.parameters$ = this.getParametersStream().pipe(delay(0));
  }

  onFiltered(filteredList: Parameter[]): void {
    this.filteredSubject.next(filteredList);
  }

  private makeMatTableDataSourceStream(): Observable<MatTableDataSource<Parameter>> {
    return this.getFilteredStream().pipe(map(this.mapToMatTableDataSource.bind(this)));
  }

  private getFilteredStream() {
    return combineLatest([this.filteredSubject, this.getParametersStream()]).pipe(
      map(([filtered, original]) => {
        return filtered || original;
      }),
    );
  }

  private mapToMatTableDataSource(parameters: Parameter[]): MatTableDataSource<Parameter> {
    const dataSource = new MatTableDataSource(parameters);
    return dataSource;
  }

  private getParametersStream(): Observable<Parameter[]> {
    return this.getManifestStream().pipe(
      map((manifest) => Object.entries(manifest).map(([key, value]) => ({ name: key, description: value }))),
    );
  }

  private getManifestStream(): Observable<Manifest> {
    return this.service.manifest$.pipe(map((data) => data as Manifest));
  }
}

type Manifest = {
  [name: string]: string;
};

type Parameter = {
  name: string;
  description: string;
};

<form [formGroup]="optionalFieldsForm">
  <div fxLayout="row" fxLayoutGap="8px">
    <div *ngIf="siteItems" fxFlex>
      <div formArrayName="siteAttributes"
        *ngFor="let item of siteAttributes.controls; let i=index">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'CaseStudy.labels.siteFilterBy' | translate}}
            {{item.value.label}}</mat-label>
          <div [formGroupName]="i">
            <mat-select formControlName="key">
              <mat-option *ngFor="let value of item.value.values"
                [value]="value.value">{{value.name}}</mat-option>
            </mat-select>
          </div>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="personalItems" fxFlex>
      <div formArrayName="personalAttributes"
        *ngFor="let item of personalAttributes.controls; let i=index">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'CaseStudy.labels.personalFilterBy' | translate}}
            {{item.value.label}}</mat-label>
          <div [formGroupName]="i">
            <mat-select formControlName="key">
              <mat-option *ngFor="let value of item.value.values"
                [value]="value.value">{{value.name}}</mat-option>
            </mat-select>
          </div>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessCategory } from 'triangle-signup/store';
import {
  createPackageProposals,
  PackageProposal,
  UploadDemandEmployees,
  UploadDemandSites,
  UploadPropositionCriteria,
  UploadReason,
} from 'triangle-study/models';

type Criteria = UploadPropositionCriteria;
type Employees = UploadDemandEmployees;
type Sites = UploadDemandSites;

@Injectable({
  providedIn: 'root',
})
export class PackageProposer {
  private readonly criteria = new BehaviorSubject<Criteria>(
    this.initCriteria(),
  );
  private readonly proposals: PackageProposal[] = createPackageProposals();

  setReason(reason: UploadReason): void {
    this.updateCriteria({ reason });
  }

  setDemands(employees: Employees, sites: Sites): void {
    this.updateCriteria({
      employees,
      sites,
    });
  }

  setFleet(fleet: boolean): void {
    this.updateCriteria({ fleet });
  }

  selectProposal(): Observable<AccessCategory> {
    return this.criteria.pipe(
      map((criteria) => this.propose(criteria)),
      map((proposal) => this.deduceAccessCategory(proposal)),
    );
  }

  selectIsEnterprise(): Observable<boolean> {
    return this.selectProposal().pipe(
      map((proposal) => this.isEnterprise(proposal)),
    );
  }

  getProposal(): AccessCategory {
    return this.deduceAccessCategory(this.propose(this.criteria.value));
  }

  selectCriteria(): Observable<Criteria> {
    return this.criteria.asObservable();
  }

  private deduceAccessCategory(proposal: PackageProposal): AccessCategory {
    return this.fallbackToFree(proposal).packageName(this.criteria.value);
  }

  private fallbackToFree(proposal: PackageProposal): PackageProposal {
    return proposal || this.proposals[0];
  }

  private isEnterprise(proposal: AccessCategory): boolean {
    return proposal === AccessCategory.Enterprise;
  }

  private initCriteria(): Criteria {
    return {
      employees: UploadDemandEmployees.ONE_TO_FIVE_K,
      sites: UploadDemandSites.ONE,
      fleet: false,
      reason: UploadReason.TEST,
    };
  }

  private updateCriteria(partial: Partial<Criteria>): void {
    this.criteria.next({ ...this.criteria.value, ...partial });
  }

  private propose(criteria: Criteria): PackageProposal {
    return this.proposals.find((proposal) => proposal.isApplicable(criteria));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'triangle-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
})
export class InputFilterComponent implements OnInit {
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() placeholder = '';
  @Input() dataList: unknown[] = [];
  @Input() properties = [];
  @Output() filtered: EventEmitter<unknown[]> = new EventEmitter();

  control = new FormControl();
  constructor() {}
  ngOnInit(): void {
    this.subscribeToControlValueForFilteredEvent();
  }

  private subscribeToControlValueForFilteredEvent(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: this.onFilterChange.bind(this),
    });
  }

  private onFilterChange(newValue: string): void {
    const filtered = this.dataList.filter((item) => this.isItemApplicable(item, newValue));
    this.filtered.next(filtered);
  }

  private isItemApplicable(item: unknown, runningFilter: string): boolean {
    const itemPropertyValues = this.properties.map((prop) => item[prop]).filter((val) => !!val);
    return itemPropertyValues.some((val) => String(val).toLowerCase().includes(runningFilter.toLowerCase()));
  }
}

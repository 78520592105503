<mat-form-field class="full-width" appearance="fill" (click)="picker.open()">
  <mat-label>{{ 'CaseStudy.labels.calculationDate' | translate }}</mat-label>
  <input
    matInput
    [min]="minDate"
    [max]="maxDate"
    [matDatepickerFilter]="filterOutWeekends"
    [matDatepicker]="picker"
    [formControl]="calculationDate"
  />
  <mat-hint class="calculation-confirmation-hint">
    {{ 'CaseStudy.messages.calculationDate' | translate }}
  </mat-hint>
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon matDatepickerToggleIcon color="accent">calendar_today</mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker disabled="false"></mat-datepicker>
</mat-form-field>

<div [formGroup]="exportOptionsForm" class="full-height" fxLayout="column" fxLayoutAlign="space-between stretch">
  <!-- dialog header -->
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1>{{ 'General.labels.exportResults' | translate }}</h1>
    <button (click)="onCancelClick()" mat-button>
      {{ 'General.buttons.close' | translate }}
    </button>
  </div>
  <mat-form-field appearance="outline">
    <mat-label> {{ 'CaseStudy.labels.reportFormat' | translate }} </mat-label>
    <mat-select formControlName="format">
      <mat-option *ngFor="let format of supportedFormats" [value]="format">
        {{ format | uppercase }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- dialog content -->
  <div *ngIf="isFilterableFormat$ | async" class="triangle-dialog-content" fxLayout="column">
    <!-- Show message if package is below standard -->
    <mat-hint
      *ngIf="filterHint$ | async"
      [innerHTML]="'CaseStudy.messages.filterResultsInfo' | translate"
      class="filter-hint"
    >
    </mat-hint>
    <div [hidden]="filterHint$ | async" fxLayout="column" fxLayoutGap="8px" class="bordered-container">
      <mat-slide-toggle formControlName="isFiltered">
        {{ 'CaseStudy.labels.filterResults' | translate }}
      </mat-slide-toggle>
      <mat-accordion>
        <!-- INCLUSION -->
        <mat-expansion-panel expanded [disabled]="!(filterToggle$ | async)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'CaseStudy.panel.titleInclusion' | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ 'CaseStudy.panel.descInclusion' | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div fxLayout="column">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CaseStudy.labels.department' | translate }} </mat-label>
              <mat-select formControlName="department">
                <mat-option *ngFor="let dept of departments" [value]="dept.value">
                  {{ dept.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="8px">
              <mat-form-field fxFlex appearance="outline">
                <mat-label>{{ 'CaseStudy.labels.site' | translate }} </mat-label>
                <mat-select formControlName="site">
                  <mat-option *ngFor="let site of sites" [value]="site.value">
                    {{ site.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex appearance="outline">
                <mat-label>
                  {{ 'CaseStudy.labels.transportMode' | translate }}
                </mat-label>
                <mat-select formControlName="mode">
                  <mat-option *ngFor="let mode of modes" [value]="mode.value">
                    {{ getTransportModeTransKey(mode.name) | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <!-- FILTER BY DISTANCE AND DURATION -->
              <triangle-export-measurement-fields formControlName="measurementFields">
              </triangle-export-measurement-fields>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <!-- action buttons -->
  <div class="triangle-dialog-buttons" fxLayout="row" fxLayoutAlign="start end">
    <button color="primary" (click)="onExportClick()" mat-flat-button>
      {{ 'General.actions.export' | translate }}
    </button>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="48px">
  <div fxLayout="row" fxLayoutGap="64px" fxLayoutAlign="start stretch">
    <triangle-proposal-summary
      fxFlex
      [package]="package"
      [criteria]="criteria$ | async"
    ></triangle-proposal-summary>
    <div fxLayout="column" fxFlex="55" fxFlex.gt-md="60" fxFlex.gt-lg="65">
      <triangle-contact-details
        [formControl]="details"
      ></triangle-contact-details>
      <triangle-contact-agreements
        fxFlex
        [formControl]="agreements"
      ></triangle-contact-agreements>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px">
    <ng-content></ng-content>
    <button
      data-test="getReport"
      mat-raised-button
      color="accent"
      [disabled]="invalid$ | async"
      [class.spinner]="discovering$ | async"
      (click)="onGetReport()"
    >
      {{ 'Homepage.buttons.getReport' | translate | uppercase }}
    </button>
  </div>
</div>

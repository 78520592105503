import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CaseStudyModel } from '../../+case-study/stores';
import { APP_VERSION } from '../../app.references';

const getRecentValue = (storageId: string, parse = false) =>
  parse ? JSON.parse(localStorage.getItem(storageId)) : localStorage.getItem(storageId);

@Injectable({ providedIn: 'root' })
export class LocalCacheService {
  readonly HIDDEN_PROJECTS_KEY = 'hiddenProjects';
  readonly HIDDEN_STUDIES_KEY = 'hiddenStudies';
  readonly MOST_RECENT_STUDY = 'recentStudy';
  readonly VERSION_ACCESSED = 'versionAccessed';
  readonly PREFERRED_LANG = 'preferredLang';

  hiddenProjects$ = new BehaviorSubject<string[]>(getRecentValue(this.HIDDEN_PROJECTS_KEY, true));
  hiddenStudies$ = new BehaviorSubject<string[]>(getRecentValue(this.HIDDEN_STUDIES_KEY, true));
  recentStudy$ = new BehaviorSubject<{
    project: string;
    study: string;
    owner: string;
  }>(getRecentValue(this.MOST_RECENT_STUDY, true));
  versionAccessed$ = new BehaviorSubject<string>(getRecentValue(this.VERSION_ACCESSED));
  preferredLang$ = new BehaviorSubject<string>(getRecentValue(this.PREFERRED_LANG));

  constructor() {
    // establish the logical data => localStorage connection
    this.hiddenProjects$.subscribe((ids) =>
      localStorage.setItem(this.HIDDEN_PROJECTS_KEY, JSON.stringify([...new Set(ids)])),
    );
    this.hiddenStudies$.subscribe((ids) =>
      localStorage.setItem(this.HIDDEN_STUDIES_KEY, JSON.stringify([...new Set(ids)])),
    );
    this.recentStudy$.subscribe((rec) => localStorage.setItem(this.MOST_RECENT_STUDY, JSON.stringify(rec)));
    this.versionAccessed$.subscribe((ver) => localStorage.setItem(this.VERSION_ACCESSED, ver));
    this.preferredLang$.subscribe((lang) => localStorage.setItem(this.PREFERRED_LANG, lang));
  }

  hideProject = (id: string) => this.hiddenProjects$.next([...this.hiddenProjects$.value, id]);
  hideStudy = (id: string) => this.hiddenStudies$.next([...this.hiddenStudies$.value, id]);
  registerRecentStudy = ({ projectId, id, owner }: CaseStudyModel) =>
    this.recentStudy$.next({ project: projectId, study: id, owner });
  cacheCurrentVersion() {
    this.versionAccessed$.next(APP_VERSION);
  }
  preferLanguage = (lang: string) => this.preferredLang$.next(lang);
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'triangle-contextual-help',
  templateUrl: './contextual-help.component.html',
  styleUrls: ['./contextual-help.component.scss'],
})
export class ContextualHelpComponent implements OnInit {
  @Input() quickHelp: string;
  @Input() isDisabled = false;
  @Input() overlapTrigger = false;
  @Input() contextHelpContent: string;

  constructor() {}

  ngOnInit() {}
}

/* tslint:disable: max-file-line-count*/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ConfigService } from 'eyes-core';
import { generateEndpoint } from 'eyes-shared';
import { saveAs } from 'file-saver';
import { produce } from 'immer';
import { first } from 'rxjs/operators';

import { Language } from '../../app.references';
import { TriangleSnackbar } from '../../core/services';
import { Calculation, CaseStudy, Parameters } from './case-study.actions';
import { CaseStudyModel } from './case-study.models';

export interface StudiesStateModel {
  fetching: boolean;
  dataMap: { [key: string]: CaseStudyModel };
  fetchedFor: string[];

  // Parameter state
  parameterizing?: 'get' | 'set' | undefined;
}

@State<StudiesStateModel>({
  name: 'studies',
  defaults: { dataMap: {}, fetching: false, fetchedFor: [] },
})
@Injectable()
export class StudiesState {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private notif: TriangleSnackbar,
    private actionSubj: CaseStudy.ActionSubject,
    private _translate: TranslateService,
  ) {
    Language.currentLang$.subscribe((language) => (this.currentLanguage = language));
  }

  readonly defaultLanguage = 'en';
  currentLanguage = Language.getDefaultLanguage(this.defaultLanguage);

  @Action(CaseStudy.GetList)
  getList(
    { dispatch, getState, patchState }: StateContext<StudiesStateModel>,
    { payload: { projectId } }: CaseStudy.GetList,
  ) {
    if (getState().fetchedFor.includes(projectId)) {
      return;
    }
    const { baseUrl, endpoints } = this.config.api;
    patchState({ fetching: true });
    this.http.get(generateEndpoint(baseUrl, endpoints.study.list, projectId)).subscribe(
      ({ cases }: any) =>
        dispatch(
          new CaseStudy.GetListSuccess({
            projectId,
            cases,
          }),
        ),
      ({ error }) => dispatch(new CaseStudy.GetListFailure({ error })),
    );
  }

  @Action(CaseStudy.GetListSuccess)
  getListSuccess(
    { patchState, getState }: StateContext<StudiesStateModel>,
    { payload: { cases, projectId } }: CaseStudy.GetListSuccess,
  ) {
    const mappedCases = cases.map((study) => CaseStudyModel.mapIncoming(study));
    const fetchedMap = mappedCases.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    patchState({
      fetchedFor: [...getState().fetchedFor, projectId],
      fetching: false,
      dataMap: { ...getState().dataMap, ...fetchedMap },
    });
  }

  @Action(CaseStudy.GetListFailure)
  getListFailure(_, { payload: { error } }: CaseStudy.GetListFailure) {
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  @Action(CaseStudy.Get)
  get({ dispatch, setState }: StateContext<StudiesStateModel>, { payload: { id, projectId } }: CaseStudy.Get) {
    const { baseUrl, endpoints } = this.config.api;
    setState(
      produce((draft: StudiesStateModel) => {
        draft.dataMap[id] = { id, projectId, loading: true };
      }),
    );
    this.http.get(generateEndpoint(baseUrl, endpoints.study.single, projectId, id)).subscribe(
      (data: any) => dispatch(new CaseStudy.GetSuccess({ data })),
      ({ message }) => dispatch(new CaseStudy.GetFailure({ error: message, id })),
    );
  }

  @Action(CaseStudy.GetSuccess)
  getSuccess({ setState }: StateContext<StudiesStateModel>, { payload: { data } }: CaseStudy.GetSuccess) {
    setState(
      produce((draft: StudiesStateModel) => {
        const mapped = CaseStudyModel.mapIncoming(data);
        draft.dataMap[mapped.id] = mapped;
      }),
    );
    this.actionSubj.fetchSuccess$.next();
  }

  @Action(CaseStudy.GetFailure)
  getFailure({ setState }: StateContext<StudiesStateModel>, { payload: { error, id } }: CaseStudy.GetFailure) {
    setState(
      produce((draft: StudiesStateModel) => {
        draft.dataMap[id].loading = false;
        draft.dataMap[id].error = error;
      }),
    );
  }

  @Action(CaseStudy.CreateOK)
  createOK({ setState }: StateContext<StudiesStateModel>, { payload: { data } }: CaseStudy.CreateOK) {
    setState(
      produce((draft: StudiesStateModel) => {
        const mapped = CaseStudyModel.mapIncoming(data);
        draft.dataMap[mapped.id] = mapped;
      }),
    );
  }

  @Action(Calculation.DownloadResults)
  downloadResults(
    { dispatch, setState }: StateContext<StudiesStateModel>,
    { payload: { projectId, caseId, filter } }: Calculation.DownloadResults,
  ) {
    const { baseUrl, endpoints } = this.config.api;
    // update study's state to reflect individual loading
    setState(
      produce((draft) => {
        draft.dataMap[caseId].downloadingResults = true;
      }),
    );
    const jsonStringParams = ['exclusions', 'distance', 'duration'];
    const sanitizedQSParams = jsonStringParams
      .filter((jsp) => jsp in filter)
      .reduce(
        // construct the sanitized qs param
        (acc, curr) => {
          acc[curr] = JSON.stringify(filter[curr]);
          return acc;
        },
        { ...filter, lang: this.currentLanguage },
      );
    this.http
      .get(generateEndpoint(baseUrl, endpoints.study.downloadResults, projectId, caseId), {
        params: sanitizedQSParams as any,
      })
      .pipe(first())
      .subscribe(
        (data: any) => {
          dispatch(
            new Calculation.DownloadResultsSuccess({
              url: data.resultsUrl,
              caseId,
              format: filter.format,
            }),
          );
        },
        ({ error }) => dispatch(new Calculation.DownloadResultsFailure({ error, caseId })),
      );
  }

  @Action(Calculation.DownloadResultsSuccess)
  async downloadResultsSuccess(
    { getState, setState, dispatch }: StateContext<StudiesStateModel>,
    { payload: { url, caseId, format } }: Calculation.DownloadResultsSuccess,
  ) {
    // do the actual download via GET
    this.http.get(url, { observe: 'response' }).subscribe(
      (r) => {
        setState(
          produce((draft) => {
            draft.dataMap[caseId].downloadingResults = false;
          }),
        );
        const matchedStudy = getState().dataMap[caseId];

        let filename = `results_${matchedStudy.name}.${format}`;
        if (format === 'employee_details') {
          filename = `Employee_details_${matchedStudy.name}.xlsx`;
        }

        saveAs(r.body as Blob, filename);
        this.notif.simpleSuccess(this._translate.instant('CaseStudy.messages.downloadResultsSuccess'));
      },
      (error) => {
        if (error.status === 404) {
          const timeout = format === 'pdf' ? 10000 : 3000;
          setTimeout(() => {
            dispatch(new Calculation.DownloadResultsSuccess({ url, caseId, format }));
          }, timeout);
        }
      },
    );
  }

  @Action(Calculation.DownloadResultsFailure)
  downloadResultsFailure(
    { setState }: StateContext<StudiesStateModel>,
    { payload: { caseId, error } }: Calculation.DownloadResultsFailure,
  ) {
    // update study's state to reflect individual loading
    setState(
      produce((draft) => {
        draft.dataMap[caseId].downloadingResults = false;
      }),
    );
    // show snackbar error
    this.notif.simpleError(this._translate.instant(`CaseStudy.errors.${error.error}`));
  }

  // Effect function to execute when Parameters.Get has been dispatched
  @Action(Parameters.Get)
  getParams({ patchState, dispatch }: StateContext<StudiesStateModel>, { payload: { projectId, id } }: Parameters.Get) {
    const { baseUrl, endpoints } = this.config.api;
    patchState({ parameterizing: 'get' });
    // get signed url
    this.http.get(generateEndpoint(baseUrl, endpoints.study.params, projectId, id)).subscribe(
      (params: any) => dispatch(new Parameters.GetSuccess({ id, params })),
      ({ error }) => dispatch(new Parameters.GetFailure({ error })),
    );
  }

  // Effect function to execute when Parameters.GetSuccess | PatchSuccess
  // has been dispatched
  @Action([Parameters.GetSuccess, Parameters.PatchSuccess])
  paramsSuccess(
    { setState }: StateContext<StudiesStateModel>,
    { payload: { id, params } }: Parameters.GetSuccess | Parameters.PatchSuccess,
  ) {
    setState(
      produce((draft: StudiesStateModel) => {
        draft.parameterizing = undefined;
        draft.dataMap[id].parameters = params;
      }),
    );
  }

  // Additional effect function to execute when Parameters.PatchSuccess has
  // been dispatched; sends out a snackbar notification.
  @Action(Parameters.PatchSuccess)
  patchSuccess() {
    const messageKey = 'CaseStudy.messages.patchParamsSuccess';
    this.notif.simpleSuccess(this._translate.instant(messageKey));
  }

  // Effect function to execte when Parameters.GetFailure | PatchFailure
  // has been dispatched
  @Action([Parameters.GetFailure, Parameters.PatchFailure, Parameters.PatchPresenceRatioFailure])
  paramsFailure({ patchState }: StateContext<StudiesStateModel>, { payload: { error } }: Parameters.GetFailure) {
    this.notif.simpleError((error.error || {}).details);
    patchState({ parameterizing: undefined });
  }

  // Effect function to execute when Parameters.Patch has been dispatched
  @Action(Parameters.Patch)
  patchParams(
    { dispatch, patchState }: StateContext<StudiesStateModel>,
    { payload: { params, projectId, id, rerun } }: Parameters.Patch,
  ) {
    const { baseUrl, endpoints } = this.config.api;
    patchState({ parameterizing: 'set' });
    this.http
      .post(generateEndpoint(baseUrl, endpoints.study.params, projectId, id), {
        ...params,
      })
      .subscribe(
        (updatedParams) => dispatch(new Parameters.PatchSuccess({ projectId, id, params: updatedParams, rerun })),
        (error) => dispatch(new Parameters.PatchFailure({ error })),
      );
  }
}

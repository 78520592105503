/* tslint:disable:cyclomatic-complexity max-file-line-count*/
import { TransportationMode } from '../../app.references';
import { AccessCategory } from '../../sign-up/store';

export interface PersonalInputModel {
  readonly address: string;
  readonly coordinates: { latitude: number; longitude: number };
  readonly department: string;
  readonly id: string;
  readonly mode: string;
  readonly currentVehicleCategory?: string;
  readonly fuel?: string;
  readonly vinNumber?: number;
  readonly privateOrLease?: string;
  readonly acquisitionDate?: number;
}
export interface SiteInputModel {
  readonly name: string;
  readonly coordinates: { latitude: number; longitude: number };
  readonly id: string;
  readonly numberOfParkingSpaces?: number;
  readonly presenceRatio?: number;
  readonly postalCode?: string;
}

export enum StudyStatus {
  GEOCODING = 'geocoding_in_progress',
  GEOCODED = 'geocoding_finished',
  GEOCODING_ERROR = 'geocoding_error',
  DIRECTING = 'directions_in_progress',
  DIRECTED = 'directions_finished',
  DIRECTIONS_ERROR = 'directions_error',
  CALCULATING = 'calculation_in_progress',
  CALCULATED = 'calculation_finished',
  CALCULATION_ERROR = 'calculation_error',
  VALIDATED = 'validation_finished',
  PENDING = 'pending',
  ERROR = 'error',
}
export const VALIDATED_STATUSES = [StudyStatus.VALIDATED];
export const DOWNLOAD_ENABLED_STATUSES = [StudyStatus.VALIDATED];
export const CALCULATION_DONE_STATUSES = [StudyStatus.CALCULATED, StudyStatus.VALIDATED];
export const RUNNING_CALCULATION_STATUSES = [StudyStatus.DIRECTING, StudyStatus.DIRECTED, StudyStatus.CALCULATING];
export const DIRECTIONALIZING_STATUSES = [
  StudyStatus.GEOCODING,
  StudyStatus.GEOCODED,
  StudyStatus.DIRECTING,
  StudyStatus.ERROR,
];
export const GEOCODING_STATUSES = [StudyStatus.GEOCODING, StudyStatus.ERROR];

export interface ParametersModel {
  // Bike-related parameters
  readonly addedTimeBike: string;
  readonly addedFractionBike: string;
  readonly walkTimeHomeBike: string;
  readonly walkTimeBikeWork: string;
  // Walk-related parameters
  readonly addedTimeWalk: string;
  readonly addedFractionWalk: string;
  // PT-related parameters
  readonly ptCost: string;
  readonly addedTimePt: string;
  readonly addedFractionPt: string;
  readonly gramCo2PerKmTrain: string;
  readonly gramCo2PerKmTram: string;
  readonly gramCo2PerKmBus: string;
  readonly gramCo2PerKmSubway: string;
  // Car-related parameters
  readonly addedTimeCar: string;
  readonly addedFractionCar: string;
  readonly gramCo2PerKmCar: string;
  readonly costCompanyCar: string;
  readonly fuelUseCar: string;
  readonly fuelCost: string;
  readonly walkTimeHomeCar: string;
  readonly walkTimeCarWork: string;
}

export interface CaseStudyModel {
  id: string;
  projectId: string;
  owner?: string;
  productPackage?: AccessCategory;
  status?: StudyStatus;
  name?: string;
  fileName?: string;
  referenceDate?: string;
  personalInputs?: PersonalInputModel[];
  siteInputs?: SiteInputModel[];
  parameters?: ParametersModel;
  hasExclusionScenarios?: boolean;
  price?: string;

  loading?: boolean;
  downloadingResults?: boolean;
  statusing?: boolean;
  error?: string;

  unsupportedCities?: SiteInputModel[];
}

export interface Exclusion {
  id: string;
  site?: string;
  department?: string;
  mode?: string;
}

export interface ResultsFilter {
  readonly format: string;
  readonly department?: string;
  readonly site?: string;
  readonly mode?: TransportationMode;
  // add more filters here if appropriate
  readonly exclusions?: Exclusion[];
}

export namespace PersonalInputModel {
  export const mapIncoming = ({
    address,
    coordinates,
    department,
    id,
    modeCurrId,
    currentVehicleCategory,
    fuel,
    vinNumber,
    privateOrLease,
    acquisitionDate,
  }): PersonalInputModel => ({
    address,
    coordinates,
    department,
    id,
    mode: modeCurrId,
    currentVehicleCategory,
    fuel,
    vinNumber,
    privateOrLease,
    acquisitionDate,
  });
}

export namespace SiteInputModel {
  export const mapIncoming = ({ name, coordinates, id, numberOfParkingSpaces, presenceRatio }): SiteInputModel => ({
    name,
    coordinates,
    id,
    numberOfParkingSpaces,
    presenceRatio,
  });

  export const mapIncomingUnsupportedCt = ({
    name,
    coordinates,
    id,
    numberOfParkingSpaces,
    postalCode,
  }): SiteInputModel => ({
    name,
    coordinates,
    id,
    numberOfParkingSpaces,
    postalCode,
  });
}

export namespace CaseStudyModel {
  export const EMPTY: CaseStudyModel = { id: undefined, projectId: undefined };
  export const mapIncoming = ({
    name,
    caseUuid,
    projectUuid,
    fileName,
    status,
    sitesInput,
    personalInput,
    productPackage,
    owner,
    referenceDate,
    hasExclusionScenarios,
    price,
    unsupportedCities,
  }: any): CaseStudyModel => ({
    name,
    id: caseUuid,
    projectId: projectUuid,
    siteInputs: (sitesInput || []).map((site) => SiteInputModel.mapIncoming(site)),
    personalInputs: (personalInput || []).map((personal) => PersonalInputModel.mapIncoming(personal)),
    unsupportedCities: (unsupportedCities || []).map((unsupportedSites) =>
      SiteInputModel.mapIncomingUnsupportedCt(unsupportedSites),
    ),
    fileName,
    status,
    productPackage,
    owner,
    referenceDate,
    hasExclusionScenarios,
    price,
  });
}

export type ValidationResult = Readonly<{
  error?: string;
  row?: string;
  field?: string;
  expected?: string;
  actual?: string;
  // sheet?: string;
}>;

export type CitiesValidationResults = Readonly<{
  id: number;
  country: string;
  municipality: string;
  postalCode: string;
}>;

export interface ValidationFeedback {
  readonly message: string;
  readonly expected: string;
  readonly actual: string;
}

export namespace CalculationResultsModel {
  const mapSingleResult = (result) =>
    result.map((trip) => ({
      ...trip,
      co2_per_year: (trip.co2_per_year || 0).toFixed(0),
      fuel_year: (trip.fuel_year || 0).toFixed(0),
      destinationId: trip.destination.id,
      destinationAddress: trip.destination.formattedAddress,
      destinationName: trip.destination.name,
      originId: trip.origin.id,
      originAddress: trip.origin.formattedAddress,
      department: trip.origin.department,
      prio_co2: ((trip.priorities || {}).prio_co2 || 0).toFixed(0),
      prio_time: ((trip.priorities || {}).prio_time || 0).toFixed(0),
      prio_money: ((trip.priorities || {}).prio_money || 0).toFixed(0),
      priority: ((trip.priorities || {}).priority || 0).toFixed(0),
      money_gain: (trip.money_gain || 0).toFixed(0),
      co2_gain: (trip.co2_gain || 0).toFixed(0),
      time_gain: (trip.time_gain || 0).toFixed(0),
      pt_last_stop: (trip.pt_last_stop || 0).toFixed(0),

      distance: (trip.distance || 0) / 1000,
      // convert distance from meters to kilometers
      duration: (trip.duration || 0) / 60,
      // convert duraation from seconds to minutes
    }));

  const mapESEResults = (results) => {
    const { scmc, scs } = results;
    const { scmc_charging_stations, s1m1_charging_stations, s1m2_charging_stations, s2m1_charging_stations } = scs;

    const siteNames = scmc.reduce((acc, cur) => {
      const { id, name } = cur.destination;
      return {
        ...acc,
        [id]: name,
      };
    }, {});

    const mapSiteNames = (charging_stations) => {
      return Object.keys(charging_stations).map((key) => {
        const { fast, slow } = charging_stations[key];
        const total = fast + slow;
        return {
          ...charging_stations[key],
          name: siteNames[key],
          total,
        };
      });
    };

    return {
      scmc_charging_stations: mapSiteNames(scmc_charging_stations || {}),
      s1m1_charging_stations: mapSiteNames(s1m1_charging_stations || {}),
      s1m2_charging_stations: mapSiteNames(s1m2_charging_stations || {}),
      s2m1_charging_stations: mapSiteNames(s2m1_charging_stations || {}),
    };
  };

  export const mapIncoming = (results) => {
    return {
      s1m1: results.s1m1 ? mapSingleResult(results.s1m1) : [],
      s1m2: results.s1m2 ? mapSingleResult(results.s1m2) : [],
      s2m1: results.s2m1 ? mapSingleResult(results.s2m1) : [],
      scmc: results.scmc ? mapSingleResult(results.scmc) : [],
      scs: results.scs ? mapESEResults(results) : {},
    };
  };
}

export interface UploadReasons {
  readonly testOption: boolean;
  readonly analyzeOption: boolean;
  readonly optimizeMode: boolean;
  readonly optimizeLocation: boolean;
  readonly createScenario: boolean;
}

export interface UploadActionPayload {
  file: File;
  projectId: string;
  name: string;
  productPackage: AccessCategory;
}

export enum FuelList {
  Electric = 'Electric',
  E85 = 'E85',
  GNV = 'GNV',
  GPL = 'GPL',
  H2 = 'H2',
  Hybrid = 'Hybrid',
  PlugInHybrid = 'Plug-in Hybrid',
  Gasoline = 'Gasoline',
  Diesel = 'Diesel',
}

export enum VehicleCategory {
  '4x4' = '4x4',
  Berline = 'Berline',
  CompactesCitadines = 'Compactes / citadines',
  Monospace = 'Monospace',
  SUV = 'SUV',
  Utilitaire = 'Utilitaire',
}

export type ConditionModel = Readonly<{
  category?: string;
  fuel?: string;
  inequalityFields?: {
    acquisition?: { value: string; symbol: string };
    consumption?: { value: string; symbol: string };
    co2?: { value: string; symbol: string };
  };
  replaceWith: {
    category: string;
    fuel: string;
  };
  hasOtherFilters?: boolean;
}>;

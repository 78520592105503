export interface ChargingStation {
  name: string;
  fast: number;
  slow: number;
  total: number;
  parkingSpace: number;
}

export interface Results {
  scmc: any[];
  s1m1?: any[];
  s1m2?: any[];
  s2m1?: any[];
  scs?: {
    s1m1_charging_stations?: ChargingStation[];
    s1m2_charging_stations?: ChargingStation[];
    s2m1_charging_stations?: ChargingStation[];
    scmc_charging_stations?: ChargingStation[];
  };
}

export const EmptyResults: Results = {
  scmc: [],
  s1m1: [],
  s2m1: [],
  s1m2: [],
  scs: {
    s1m1_charging_stations: [],
    s1m2_charging_stations: [],
    s2m1_charging_stations: [],
    scmc_charging_stations: [],
  },
};

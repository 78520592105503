<form [formGroup]="form">
  <div fxLayout="column" class="main-container">
    <div fxLayout="row" fxLayoutAlign="start start">
      <span [class]="containerLabelStyle$ | async">
        <ng-container *ngIf="isPrefixed">
          {{ 'Shared.labels.filterBy' | translate }}
        </ng-container>
        {{ config?.label }}
      </span>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      <mat-form-field [appearance]="config?.appearance" fxFlex="0 0 80px">
        <mat-label>{{ 'Shared.labels.symbol' | translate }}</mat-label>
        <mat-select formControlName="symbol">
          <mat-option *ngFor="let symbol of inequalitySymbols" [value]="symbol.value">
            {{ symbol.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- numerical value input -->
      <mat-form-field [appearance]="config?.appearance" fxFlex>
        <mat-label>Value</mat-label>
        <input matInput type="number" formControlName="value" required />
        <span matSuffix>{{ config?.unit }}</span>
        <mat-hint>
          {{ valueHintKey$ | async | translate }}
        </mat-hint>
        <mat-error *ngIf="valueControl.invalid">
          {{ config?.label + ' ' + (getErrorMessage() | translate) }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<mat-drawer-container class="triangle-layout-border">
  <mat-drawer
    #drawer
    class="triangle-drawer"
    mode="over"
    disableClose
    [autoFocus]="false"
  >
    <div fxLayout="column" fxLayoutGap="24px" class="full-height">
      <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-icon-button (click)="onDrawerClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="space-between"
        class="triangle-drawer-icons full-height"
      >
        <!-- common items -->
        <div fxLayout="column" fxLayoutGap="24px">
          <div
            fxLayout="row"
            class="layout-drawer-item"
            fxLayoutGap="24px"
            *ngFor="let item of commonItems$ | async"
            (click)="onDrawerSelect(item)"
            [class.layout-drawer-item-disabled]="item?.disabled"
          >
            <mat-icon>{{ item?.icon }}</mat-icon>
            <span>{{ item?.literalKey | translate }}</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="12px">
          <!-- direct jump to report an issue -->
          <!-- <div class="layout-drawer-item layout-report-item" fxLayoutGap="24px" fxLayoutAlign="start start">
            <mat-icon color="warn">pan_tool</mat-icon>
            <span>Report an issue</span>
          </div> -->
          <button
            mat-flat-button
            color="warn"
            class="layout-report-button"
            (click)="onReportClick()"
          >
            {{ 'Support.titles.report' | translate }}
          </button>

          <!-- admin items -->
          <div
            fxLayout="column"
            fxLayoutGap="24px"
            class="layout-admin-container"
            fxLayoutAlign="start start"
            *ngIf="adminItems$ | async"
          >
            <span class="layout-admin-header">
              {{ 'Layout.drawer.adminFunctions' | translate }}
            </span>
            <div
              fxLayout="row"
              class="layout-drawer-item"
              fxLayoutGap="24px"
              *ngFor="let item of adminItems$ | async"
              (click)="onDrawerSelect(item)"
            >
              <mat-icon>{{ item?.icon }}</mat-icon>
              <span>{{ item?.literalKey | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <button
      *ngIf="!!(isHomeAndTopPage$ | async)"
      mat-raised-button
      class="scroll-down-btn animate-bounce"
      (click)="scrollDownToVideo()"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="isHomePage$ | async" fxLayout="column" class="feature-section">
  <div #video>
    <triangle-landing-media></triangle-landing-media>
  </div>
  <triangle-how-it-works></triangle-how-it-works>
  <triangle-landing-reports></triangle-landing-reports>
</div>
<triangle-footer [language]="currentLanguage"></triangle-footer>

<!-- toolbar -->
<div
  *ngIf="!(isDrawerOpen$ | async)"
  class="triangle-toolbar-container"
  fxLayout="row"
  fxLayoutAlign="space-between"
  [@in]
  [@out]
>
  <!-- nav drawer, profile, and logout -->
  <div
    fxLayout="row"
    fxLayoutGap="8px"
    *ngIf="loggedInUser$ | async; else loggedOut"
  >
    <button
      mat-icon-button
      class="layout-drawer-button"
      color="primary"
      (click)="onDrawerOpen()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <button
      mat-raised-button
      color=""
      (click)="onViewProfile()"
      [@tada]="something$ | async"
    >
      {{ loggedInUser$ | async }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/', 'home']"
      matTooltip="Home"
    >
      <mat-icon>home</mat-icon>
    </button>
    <button mat-raised-button color="primary" (click)="onLogout()">
      {{ 'General.labels.logout' | translate }}
    </button>
  </div>
  <ng-template #loggedOut>
    <div fxLayout="row" fxLayoutGap="8px" class="layout-loggedout-toolbar-left">
      <button mat-raised-button color="" (click)="onLogin()">
        {{ 'General.labels.login' | translate }}
      </button>
      <!-- <button mat-raised-button color="primary" (click)="onSignup()">
        {{ 'General.labels.signup' | translate }}
      </button> -->
    </div>
  </ng-template>

  <!-- language selection -->
  <div fxLayoutGap="25px" fxLayoutAlign="end end">
    <div fxLayoutGap="8px" fxLayoutAlign="end end" *ngIf="isHomePage$ | async">
      <button
        mat-button
        class="layout-language"
        [routerLink]="['/', 'support', 'contact']"
      >
        {{ 'General.buttons.contact' | translate }}
      </button>
    </div>

    <div fxLayoutGap="8px" fxLayoutAlign="end end">
      <button
        *ngFor="let language of languages"
        mat-icon-button
        class="layout-language"
        [class.layout-language-selected]="currentLanguage === language"
        (click)="onSelectLanguage(language)"
      >
        {{ language | uppercase }}
      </button>
    </div>
  </div>
</div>

<div class="inner-blue" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <img class="reports-brochure" src="/assets/landing-reports/report.png" />
    <div fxLayout="row" fxLayoutGap="16px" class="reports-actions">
      <button
        mat-flat-button
        data-test="downloadProdRef"
        class="actions-primary"
        color="accent"
        [class.spinner]="downloading$ | async"
        (click)="onDownloadProdRefClick()"
      >
        {{ 'Homepage.buttons.downloadProdRef' | translate }}
      </button>
      <button
        class="other-solutions-btn"
        mat-button
        data-test="otherSolutions"
        (click)="onOtherSolutionsClick()"
      >
        {{ 'Homepage.buttons.otherSolutions' | translate }}
      </button>
    </div>
  </div>
</div>

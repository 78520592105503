import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent, doNothing } from 'eyes-shared';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EMPLOYEE_DETAILS_FORMAT } from 'triangle-app';

import { ResultsFilter } from '../../../+case-study/stores';
import { EXPORT_RESULT_FORMATS, NOT_APPLICABLE, NOT_APPLICABLE_PAIR, NameValuePair } from '../../../app.references';
import { AccessCategory } from '../../../sign-up/store';

export namespace FilterDialogUtils {
  export const removeDuplicates = (xOptions: { [key: string]: string[] }) =>
    Object.keys(xOptions).reduce((acc, curr) => {
      acc[curr] = [...new Set(xOptions[curr])];
      return acc;
    }, {});
}
@Component({
  selector: 'triangle-export-filter-dialog',
  templateUrl: './export-filter-dialog.component.html',
  styleUrls: ['./export-filter-dialog.component.scss'],
})
export class ExportFilterDialogComponent extends BaseComponent implements OnInit {
  readonly supportedFormats = EXPORT_RESULT_FORMATS;
  readonly FILTER_OPTIONS = ['department', 'site', 'mode'];

  departments = [NOT_APPLICABLE_PAIR, ...this.data.departmentOptions];
  sites = [NOT_APPLICABLE_PAIR, ...this.data.siteOptions];
  modes = [NOT_APPLICABLE_PAIR, ...this.data.modeOptions];
  productPackage = this.data.productPackage;
  filterToggle$ = new BehaviorSubject<boolean>(false);
  filterHint$ = new BehaviorSubject<boolean>(false);
  essentialFilter$ = new BehaviorSubject<boolean>(false);
  exportOptionsForm = this.formBuilder.group({
    format: this.data.format || this.supportedFormats[0],
    isFiltered: this.filterToggle$.value,
    department: this.departments[0].value,
    site: this.sites[0].value,
    mode: this.modes[0].value,
    measurementFields: undefined,
  });
  isFilterableFormat$ = new BehaviorSubject<boolean>(true);
  isFilteredControl = this.exportOptionsForm.get('isFiltered');
  siteControl = this.exportOptionsForm.get('site');
  filterControls = this.FILTER_OPTIONS.map((option) => this.exportOptionsForm.get(option));

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ExportFilterDialogData,
    private dialogRef: MatDialogRef<ExportFilterDialogComponent>,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    // form -> behavior filter toggle
    this.isFilteredControl.valueChanges.pipe(this.takeUntil()).subscribe((isIt) => this.filterToggle$.next(isIt));
    // disable all filter controls if filter toggle is false
    this.filterHint$.next(AccessCategory.Free === this.productPackage);
    this.filterHint$
      .pipe(this.takeUntil())
      .subscribe((showHint) => (showHint ? this.isFilteredControl.disable() : this.isFilteredControl.enable()));
    this.filterToggle$.pipe(this.takeUntil()).subscribe((filtered) => {
      this.filterControls.forEach((control) => (filtered ? doNothing() : control.setValue('')));
      this.filterControls.forEach((control) => (filtered ? control.enable() : control.disable()));
      filtered
        ? this.exportOptionsForm.controls.measurementFields.enable()
        : this.exportOptionsForm.controls.measurementFields.disable();
    });
    // disable site filter for Essential package
    this.essentialFilter$.next(AccessCategory.Essential === this.productPackage);
    combineLatest([this.filterToggle$, this.essentialFilter$])
      .pipe(
        map(([toggled, isEssential]) => toggled && !isEssential),
        this.takeUntilShare(),
      )
      .subscribe((siteFilterable) => (siteFilterable ? this.siteControl.enable() : this.siteControl.disable()));

    this.exportOptionsForm.get('format').valueChanges.subscribe(newFormat => {
      this.isFilterableFormat$.next(newFormat !== EMPLOYEE_DETAILS_FORMAT);
    });
  }

  onExportClick = () => {
    const formVal = this.exportOptionsForm.getRawValue();
    const sanitizedEmission = this.FILTER_OPTIONS.reduce(
      (acc, curr) => {
        const ctrlVal = formVal[curr];
        !!ctrlVal && ctrlVal !== NOT_APPLICABLE ? (acc[curr] = ctrlVal) : doNothing();
        return acc;
      },
      {
        format: formVal['format'],
        // new results criteria down here
        distance: formVal.measurementFields.distance,
        duration: formVal.measurementFields.duration,
      } as ResultsFilter,
    );
    const improvedEmission = Object.keys(sanitizedEmission)
      .filter((k) => {
        const respecitiveVal = sanitizedEmission[k];
        return !!respecitiveVal && (!Array.isArray(respecitiveVal) || respecitiveVal.length > 0);
      })
      .reduce((acc, curr) => {
        acc[curr] = sanitizedEmission[curr];
        return acc;
      }, {});
    this.dialogRef.close(improvedEmission as ResultsFilter);
  };
  onCancelClick = () => this.dialogRef.close();
  getTransportModeTransKey = (mode: string) => `General.transportModes.${mode}`;
}

export interface ExportFilterDialogData {
  productPackage: AccessCategory;
  departmentOptions: NameValuePair[];
  siteOptions: NameValuePair[];
  modeOptions: NameValuePair[];
  format: string;
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormBuilder,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coerce, getCVAProvider, getValidatorProvider } from 'triangle-core';
import { CVA } from 'triangle-core/classes';
import { ContactAgreements } from 'triangle-support/models';

@UntilDestroy()
@Component({
  selector: 'triangle-contact-agreements',
  templateUrl: './contact-agreements.component.html',
  styleUrls: ['./contact-agreements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getCVAProvider(ContactAgreementsComponent),
    getValidatorProvider(ContactAgreementsComponent),
  ],
})
export class ContactAgreementsComponent
  extends CVA<ContactAgreements>
  implements Validator
{
  readonly agreements = this.builder.group({
    privacyStatement: false,
    marketing: false,
  } as ContactAgreements);

  constructor(private readonly builder: FormBuilder) {
    super();
    this.subscribeToControlChangesForCVAPropagation();
    this.setValidatorForPrivacyStatement();
  }

  writeValue(obj: ContactAgreements): void {
    // do nothing
  }

  onPrivacyLinkClick(): void {
    window.open(this.privacyStatementLink, '_blank');
  }

  validate(): ValidationErrors {
    return this.agreements.valid ? null : { invalid: true };
  }

  private setValidatorForPrivacyStatement(): void {
    this.agreements
      .get('privacyStatement')
      .setValidators(Validators.requiredTrue);
  }

  private get privacyStatementLink(): string {
    return `https://digital.tractebel-engie.com/tractebel-digital-data-privacy-policy/`;
  }

  private subscribeToControlChangesForCVAPropagation(): void {
    this.agreements.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        Coerce.voidFn(this.onChange)(value);
      });
  }
}

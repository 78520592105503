export interface Reason {
  readonly reason: UploadReason;
}

export interface Demands {
  readonly numEmployees: UploadDemandEmployees;
  readonly numSites: UploadDemandSites;
}

export interface Fleet {
  readonly analyzeFleet: boolean;
}

export enum UploadReason {
  NA = 0,
  TEST = 1,
  ANALYZE = 2,
  OPTIMIZE_TRANSPORTATION = 3,
  OPTIMIZE_LOCATION = 4,
  CREATE_SCENARIOS = 5,
}

export enum UploadReasonKey {
  TEST = 'testApp',
  ANALYZE = 'analyze',
  OPTIMIZE_MODE = 'optimizeMode',
  OPTIMIZE_LOCATION = 'optimizeLocation',
  CREATE_SCENARIO = 'createScenario',
}

export const ReasonKeyMap: Record<UploadReason, UploadReasonKey> = {
  [UploadReason.NA]: null,
  [UploadReason.TEST]: UploadReasonKey.TEST,
  [UploadReason.ANALYZE]: UploadReasonKey.ANALYZE,
  [UploadReason.OPTIMIZE_TRANSPORTATION]: UploadReasonKey.OPTIMIZE_MODE,
  [UploadReason.OPTIMIZE_LOCATION]: UploadReasonKey.OPTIMIZE_LOCATION,
  [UploadReason.CREATE_SCENARIOS]: UploadReasonKey.CREATE_SCENARIO,
};

export const ReasonHierarchy: UploadReason[] = [
  UploadReason.ANALYZE,
  UploadReason.OPTIMIZE_TRANSPORTATION,
  UploadReason.OPTIMIZE_LOCATION,
  UploadReason.CREATE_SCENARIOS,
];

export enum UploadDemandSites {
  ONE = '1',
  TWO_TO_TEN = '2 - 10',
  MORE_THAN_TEN = '> 10',
}

export enum UploadDemandEmployees {
  LESS_THAN_EQUAL_FIFTY = '<= 50',
  FIFTY_ONE_TO_FIVE_K = '51 - 5000',
  MORE_THAN_FIVE_K = '> 5000',
  ONE_TO_FIVE_K = '1 - 5000',
}

export const UploadDemandEmployeeOptions = {
  [UploadReason.TEST]: [UploadDemandEmployees.LESS_THAN_EQUAL_FIFTY],
  [UploadReason.ANALYZE]: [
    UploadDemandEmployees.LESS_THAN_EQUAL_FIFTY,
    UploadDemandEmployees.FIFTY_ONE_TO_FIVE_K,
    UploadDemandEmployees.MORE_THAN_FIVE_K,
  ],
  [UploadReason.OPTIMIZE_TRANSPORTATION]: [
    UploadDemandEmployees.ONE_TO_FIVE_K,
    UploadDemandEmployees.MORE_THAN_FIVE_K,
  ],
  [UploadReason.OPTIMIZE_LOCATION]: [
    UploadDemandEmployees.ONE_TO_FIVE_K,
    UploadDemandEmployees.MORE_THAN_FIVE_K,
  ],
  [UploadReason.CREATE_SCENARIOS]: [
    UploadDemandEmployees.ONE_TO_FIVE_K,
    UploadDemandEmployees.MORE_THAN_FIVE_K,
  ],
};

export const UploadDemandSiteOptions = {
  [UploadReason.TEST]: [UploadDemandSites.ONE],
  [UploadReason.ANALYZE]: [
    UploadDemandSites.ONE,
    UploadDemandSites.TWO_TO_TEN,
    UploadDemandSites.MORE_THAN_TEN,
  ],
  [UploadReason.OPTIMIZE_TRANSPORTATION]: [
    UploadDemandSites.ONE,
    UploadDemandSites.TWO_TO_TEN,
    UploadDemandSites.MORE_THAN_TEN,
  ],
  [UploadReason.OPTIMIZE_LOCATION]: [
    UploadDemandSites.ONE,
    UploadDemandSites.TWO_TO_TEN,
    UploadDemandSites.MORE_THAN_TEN,
  ],
  [UploadReason.CREATE_SCENARIOS]: [
    UploadDemandSites.ONE,
    UploadDemandSites.TWO_TO_TEN,
    UploadDemandSites.MORE_THAN_TEN,
  ],
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EyesSharedModule } from 'eyes-shared';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  ContextualHelpComponent,
  ExportExclusionComponent,
  ExportFilterDialogComponent,
  ExportMeasurementFieldsComponent,
  ExportOptionalFieldsComponent,
  InequalityInputComponent,
  PackageProjectionsComponent,
  PreviewPageComponent,
  ProformaPreviewComponent,
  ResultsViewerComponent,
  CreateScenarioOptionButtonComponent,
  CreateScenarioOptionsDialogComponent,
} from './components';
import { TriangleSpinnerModule } from './components/spinner';
import { SanitizePipeModule, HoursPipeModule } from './pipes';

const moduleComponents = [
  ContextualHelpComponent,
  InequalityInputComponent,
  ResultsViewerComponent,
  ExportExclusionComponent,
  ExportFilterDialogComponent,
  ExportMeasurementFieldsComponent,
  ExportOptionalFieldsComponent,
  PackageProjectionsComponent,
  ProformaPreviewComponent,
  PreviewPageComponent,
  CreateScenarioOptionButtonComponent,
  CreateScenarioOptionsDialogComponent,
];

@NgModule({
  imports: [
    CommonModule,
    EyesSharedModule.forFeature(),
    NgxSpinnerModule,
    SanitizePipeModule,
    HoursPipeModule,
    TriangleSpinnerModule,
  ],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
})
export class SharedModule {}

<div fxLayout="row" fxLayoutGap="16px" *ngIf="point$ | async as point">
  <img
    *ngIf="!rtl"
    [src]="'/assets/work-points/point_' + point + '.svg'"
    data-test="icon"
  />
  <span data-test="number" class="work-point-number">
    {{ number$ | async }}
  </span>
  <span fxFlex="200px" data-test="description" class="work-point-desc">
    {{ 'Homepage.workPoints.' + point | translate }}
  </span>
  <img
    *ngIf="rtl"
    [src]="'/assets/work-points/point_' + point + '.svg'"
    data-test="rightIcon"
  />
</div>

export interface SupportLandingItem {
  readonly icon: string;
  readonly route: string;
  readonly id: string;
}

export enum SupportFeature {
  FAQ = 'faq',
  CONTACT = 'contact',
  TUTORIAL = 'tutorial',
  REPORT = 'report',
}

export const SupportFeatures: { [id: string]: SupportLandingItem } = {
  [SupportFeature.FAQ]: {
    icon: 'question_answer',
    id: 'faq',
    route: SupportFeature.FAQ,
  },
  [SupportFeature.CONTACT]: {
    icon: 'mark_email_unread',
    id: 'contact',
    route: SupportFeature.CONTACT,
  },
  [SupportFeature.TUTORIAL]: {
    icon: 'help_center',
    id: 'tutorial',
    route: SupportFeature.TUTORIAL,
  },
  [SupportFeature.REPORT]: {
    icon: 'bug_report',
    id: 'report',
    route: SupportFeature.REPORT,
  },
};

export enum ServiceDeskIssueType {
  BUG = 'bug',
  SUPPORT = 'enhancement',
}

export interface ServiceDeskIssueOption {
  readonly icon: string;
  readonly value: ServiceDeskIssueType;
}

export const SupportedIssueOptions: ServiceDeskIssueOption[] = [
  { icon: 'bug_report', value: ServiceDeskIssueType.BUG },
  { icon: 'highlight', value: ServiceDeskIssueType.SUPPORT },
];

export interface ServiceDeskForm {
  readonly issueType: ServiceDeskIssueOption;
  readonly summary: string;
  readonly description?: string;
}

export enum GetInTouchReason {
  GENERAL = 'general',
  ADVANCED_MAP = 'advancedMap',
  ENTERPRISE = 'packageEnterprise',
  OTHER = 'other',
  PRESENTATION = 'presentation',
}

export const ContactUsKeys: (keyof GetInTouchForm)[] = [
  'firstName',
  'lastName',
  'email',
  'message',
  'reason',
  'jobTitle',
  'company',
  'phoneNumber',
  'country',
  'details',
  'marketing',
];

export interface GetInTouchForm {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  reason: GetInTouchReason;
  marketing?: boolean;
  agreements?: ContactAgreements;
  jobTitle?: string;
  company?: string;
  phoneNumber?: string;
  country?: string;

  details?: { [key: string]: string };
}

export interface ContactAgreements {
  readonly privacyStatement: boolean;
  readonly marketing: boolean;
}

import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent, doNothing } from 'eyes-shared';
import { InequalityInputConfig } from '..';

@Component({
  selector: 'triangle-export-measurement-fields',
  templateUrl: './export-measurement-fields.component.html',
  styleUrls: ['./export-measurement-fields.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExportMeasurementFieldsComponent,
      multi: true,
    },
  ],
})
export class ExportMeasurementFieldsComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  distanceConfig: InequalityInputConfig = {
    label: this.translate.instant('Shared.labels.distance'),
    unit: this.translate.instant('Shared.labels.km'),
  };
  durationConfig: InequalityInputConfig = {
    label: this.translate.instant('Shared.labels.duration'),
    unit: this.translate.instant('Shared.labels.mins'),
  };
  formGroup = this.builder.group({ distance: '', duration: '' });
  onChange: (value: any) => void = () => {};

  constructor(private builder: FormBuilder, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(this.takeUntilShare())
      .subscribe((formVal) => this.onChange({ ...formVal, valid: this.formGroup.valid }));
  }

  /** Control Value Accessor methods */
  writeValue = (value: any) => doNothing();
  registerOnChange = (fn: any) => (this.onChange = fn);
  registerOnTouched = (fn: any) => doNothing();
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }
}

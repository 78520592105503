import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TriangleCoreModule } from '../core';
import { SharedModule } from '../shared/shared.module';
import { SignUpModule } from '../sign-up/sign-up.module';
import { UploadFormComponent } from './components';
import { StudyUploadBottomSheetComponent, StudyUploadDialogComponent, StudyUploadModalComponent } from './containers';

@NgModule({
  imports: [CommonModule, TriangleCoreModule, SignUpModule, NgxSpinnerModule, SharedModule],
  declarations: [
    UploadFormComponent,
    StudyUploadModalComponent,
    StudyUploadBottomSheetComponent,
    StudyUploadDialogComponent,
  ],
  entryComponents: [StudyUploadBottomSheetComponent, StudyUploadDialogComponent],
})
export class UploadStudyModule {}

import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent, doNothing } from 'eyes-shared';
import { map } from 'rxjs/operators';
import { NOT_APPLICABLE_PAIR } from '../../../app.references';

@Component({
  selector: 'triangle-export-optional-fields',
  templateUrl: './export-optional-fields.component.html',
  styleUrls: ['./export-optional-fields.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ExportOptionalFieldsComponent,
      multi: true,
    },
  ],
})
export class ExportOptionalFieldsComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  onChange;
  @Input() siteItems;
  @Input() personalItems;

  optionalFieldsForm = this.formBuilder.group({
    siteAttributes: this.formBuilder.array([]),
    personalAttributes: this.formBuilder.array([]),
  });

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    const siteFields = this.optionalFieldsForm.get('siteAttributes') as FormArray;
    const personalFields = this.optionalFieldsForm.get('personalAttributes') as FormArray;
    this.addXItems(siteFields, this.siteItems);
    this.addXItems(personalFields, this.personalItems);

    this.optionalFieldsForm.valueChanges
      .pipe(
        map((formVal) =>
          Object.keys(formVal).reduce((acc, curr) => {
            acc[curr] = formVal[curr].filter((ctrl) => !!ctrl.key).map((ctrl) => ({ [ctrl.label]: ctrl.key }));
            return acc;
          }, {}),
        ),
      )
      .subscribe((formVal) => this.onChange(formVal));
  }

  get siteAttributes() {
    return this.optionalFieldsForm.get('siteAttributes') as FormGroup;
  }
  get personalAttributes() {
    return this.optionalFieldsForm.get('personalAttributes') as FormGroup;
  }

  addXItems(fields, items) {
    Object.keys(items).forEach((key) => {
      fields.push(
        this.formBuilder.group({
          key: undefined,
          label: key,
          values: [[NOT_APPLICABLE_PAIR, ...items[key].map((i) => ({ name: i, value: i }))]],
        }),
      );
    });
  }

  /** Control Value Accessor methods */
  writeValue = (optionalVal: any) => doNothing();
  registerOnChange = (fn: any) => (this.onChange = fn);
  registerOnTouched = (fn: any) => doNothing();
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.optionalFieldsForm.disable() : this.optionalFieldsForm.enable();
  }
}

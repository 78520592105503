<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
  <div fxLayout="column" fxLayoutGap="64px">
    <triangle-work-point
      point="analysis"
      data-test="point-analysis"
    ></triangle-work-point>
    <triangle-work-point
      point="visualization"
      data-test="point-visualization"
    ></triangle-work-point>
    <triangle-work-point
      point="simulation"
      data-test="point-simulation"
    ></triangle-work-point>
  </div>
  <div fxLayout="column" fxLayoutGap="80px">
    <triangle-work-point
      point="security"
      data-test="point-security"
      [rtl]="true"
    ></triangle-work-point>
    <triangle-work-point
      point="trustworthy"
      data-test="point-trustworthy"
      [rtl]="true"
    ></triangle-work-point>
  </div>
</div>

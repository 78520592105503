<triangle-input-filter
  appearance="outline"
  [dataList]="parameters$ | async"
  [properties]="['name', 'description']"
  (filtered)="onFiltered($event)"
></triangle-input-filter>

<table mat-table [dataSource]="dataSource$ | async" class="full-width">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'General.labels.name' | translate }}</th>
    <td mat-cell *matCellDef="let parameter">
      <b>{{ parameter.name }}</b>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>{{ 'General.labels.description' | translate }}</th>
    <td mat-cell *matCellDef="let parameter">{{ parameter.description }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['name', 'description']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['name', 'description']"></tr>
</table>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { VERSION_CONFIG } from 'eyes-core';
import { TRIANGLE_VERSION } from 'triangle-env';
import { FooterComponent } from './footer.component';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule, MatButtonModule, MatIconModule],
  exports: [FooterComponent],
  providers: [{ provide: VERSION_CONFIG, useValue: TRIANGLE_VERSION }],
})
export class FooterModule {}

import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectState, ProjectStore } from './store';
import { Project } from './store/project.action';

@NgModule({
  imports: [NgxsModule.forFeature([ProjectState])],
  providers: [ProjectStore, Project.ActionSubject],
})
export class ProjectRootModule {}
